@import '../../../../assets/styles/variables.scss';

.wrapper {
  .title {
    color: var(--font-color);
    text-align: center;
    cursor: pointer;
    transition: $transition;

    &:hover {
      color: var(--antd-blue);
    }
  }

  ul {
    width: 100%;
    margin: 0;
    list-style: none;
    padding-inline-start: 0;
    overflow-y: scroll;

    li {
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }

  .empty {
    color: var(--font-color);
    font-size: 1rem;
    text-align: center;
    opacity: 50%;
  }
}
