@import '/src/assets/styles/variables.scss';

.clustersRow {
  @include flex-gap(1rem, flex-start, row);

  width: 100%;
  color: inherit;
  margin: 0;

  .avatar {
    svg {
      @include size(16px);
    }
  }
}

.container {
  @include flex-gap(1rem, flex-start, column, flex-start);

  padding: $padding;

  p {
    margin: 0;
    font-size: 12px;
    white-space: nowrap;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
