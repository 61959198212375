@import '/src/assets/styles/variables.scss';

.btn {
  position: absolute;
  top: 2rem;
  right: 3rem;
  color: var(--font-color);
  font-size: 24px;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
}

.dropdown {
  li {
    @include flex-gap(1rem, flex-start, row, flex-start);

    & > svg {
      @include size(20px);

      margin: 0 !important;
    }
  }
}
