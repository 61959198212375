@import '../../assets/styles/variables.scss';
@import '../../assets/styles/colors.scss';

.errorBox {
  @include flex(center, row, center);

  position: absolute;
  z-index: $app-error-index;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  color: #fff;
  background: $logo-green;

  .wraper {
    display: block;
    width: 600px;
    margin-left: 80px;

    .title {
      color: #fff;
      font-size: 120px;
      font-weight: 700;
    }

    .text {
      display: block;
      width: 520px;
      font-size: 24px;
    }

    .image {
      margin-top: 100px;
    }
  }
}

.timerBox {
  display: flex;
  align-items: center;

  .timerText {
    margin: 0;
    margin-right: 20px;
  }
}
