@import '/src/assets/styles/variables.scss';

.container {
  @include flex(flex-start, row, flex-start);

  max-width: 300px;

  .block {
    @include flex-gap(1rem, flex-start, column, flex-start);

    .description {
      div[class*='title'] {
        font-size: 14px !important;
      }

      span[class*='label'] {
        font-size: 12px !important;
      }

      span[class*='content'] {
        font-size: 10px !important;
      }
    }
  }

  &.candle {
    max-width: 500px;
  }
}
