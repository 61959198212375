@import '../../../../assets/styles/variables.scss';

.imgError {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;

  img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  h2 {
    font-size: inherit;
    text-align: center;
  }

  @media (min-width: $media-xxl) {
    width: 100%;
    margin: 0;
  }

  @media (max-width: $media-l) {
    width: 90%;
    margin: 0 5%;
  }

  @media (max-width: $media-s) {
    width: 100%;
    margin: 0;
  }
}
