@import '../../../assets/styles/variables.scss';

.container {
  position: relative;
  pointer-events: none;
  animation: bounse 0.2s ease;
}

@keyframes bounse {
  0% {
    top: -50px;
    opacity: 0%;
  }

  100% {
    top: 0;
    opacity: 100%;
  }
}
