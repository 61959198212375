@import '../styles/variables.scss';
@import './../../../assets/styles/index.scss';

$selected-color: rgb(21 115 255 / 100%);
$hover-color: $selected-color;
$close-box-shadow: red 0 0 5px 1px !important;
$results-padding: 0.5rem;
$results-item-font-size: 11px;
$title-font-size: 14px;
$cell-size: 5px;

.measurementsPanel {
  position: absolute !important;
  top: 8 * $cell-size;
  right: 0;
  margin-top: 3 * $cell-size !important;
  border-radius: $b-radius;
}

.panelBodyStyle {
  padding: $cell-size 2 * $cell-size;
  margin: 0;
}

.measurementsTitle {
  padding-left: $cell-size;
  font-size: $title-font-size;
}

.measurementsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
}

.item {
  color: var(--font-color) !important;
  border-right: 1px solid var(--bg-tint) !important;

  &:hover {
    background: var(--bg-tint);
  }
}

.itemSelected {
  color: white !important;
  background: $selected-color !important;
  border: 0.5px solid var(--bg-tint) !important;

  &:hover {
    background: var(--bg-tint);
  }
}

.measurementsResultContainer {
  padding: $results-padding !important;
}

.measurementsResultTitle {
  padding: $results-padding;
  font-size: $title-font-size;
}

.measurementsList {
  max-height: 140px !important;
  padding-right: $results-padding;
  padding-bottom: $results-padding;
  padding-left: $results-padding;
  overflow-y: auto;
}

.measureNumber {
  font-size: $results-item-font-size;
}

.measureValue {
  display: table;
  font-size: $results-item-font-size;

  &:hover {
    color: white;
    background: $hover-color;
  }
}

.closeIcon {
  color: var(--font-color) !important;
  filter: opacity(0.3);

  &:hover {
    cursor: pointer;
    filter: opacity(1);
  }
}

.noMeasurements {
  padding: $results-padding;
  font-size: $results-item-font-size;
}
