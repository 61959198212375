@import '../../../../../assets/styles/variables.scss';

.blockRow {
  @include flex(flex-start, row, center);

  width: 100%;
  gap: 1rem;

  .avatar {
    @include flex(center, column, center);
    @include size(30px);

    background: var(--font-color);
    border: 4px solid var(--font-color);
    border-radius: 50%;
    cursor: pointer;

    .icon {
      color: var(--bg);
    }
  }

  &.isMe {
    background-color: var(--antd-blue-opacity8a);
    border-radius: $b-radius;

    .avatar {
      border: 3px solid var(--antd-blue-opacity8a);
    }
  }
}
