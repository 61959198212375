@import '../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, space-between, row);

  position: absolute;
  bottom: 1rem;
  width: 100%;
  padding-right: 3%;
  padding-left: 10%;

  &.panel {
    padding-left: 1rem;
  }

  .buttons {
    @include flex-gap(1rem, flex-start, row);
  }

  @media (max-width: $media-xl) {
    padding-left: 1rem;
  }
}
