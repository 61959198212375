@import '../../../../assets/styles/variables.scss';

.point {
  position: absolute;
  z-index: 100;
  top: -2rem;
  left: -0.2rem;
  height: 100%;
  width: 0.4rem;
  background-color: rgb(153 205 50 / 50.7%);
  border-radius: $b-radius;

  &.isGradient {
    visibility: hidden;

    &::after {
      position: absolute;
      right: 0;
      display: block;
      height: 100%;
      width: 75px;
      background: linear-gradient(90deg, #fff0 0%, #20fd3d66 100%);
      visibility: visible;
      content: '';
    }
  }
}
