@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, row);

  position: absolute;
  top: 1rem;
  left: 1rem;
  width: fit-content;
}
