@import '../../assets/styles/variables.scss';
@import '../../assets/styles/zIndex.scss';

@mixin default-style {
  height: 100%;
  width: 100%;
  min-width: 100%;
  padding: 1rem;
  border-radius: $b-radius;
}

.wrapper {
  @include flex(flex-start, column, flex-end);
  @include default-style;

  position: relative;

  .programRow {
    @include flex-gap(1rem, flex-start, row, flex-start);

    width: 100%;
    gap: 1rem;

    .column {
      @include flex(flex-start, column);

      width: 97%;
    }
  }

  .programRowTimeLine {
    display: flex;
    width: 97%;
  }

  .programsBandLine {
    display: flex;
    width: 100%;
  }
}

.noData {
  @include flex(center, column, center);
  @include default-style;
}
