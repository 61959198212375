@import '../../../../assets/styles/variables.scss';

.iteractionButtons {
  display: flex;
  gap: 1rem;

  button {
    border-radius: $b-radius;
    text-transform: uppercase;
  }
}
