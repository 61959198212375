@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, row, flex-start);

  width: 100%;
  margin-bottom: 0 !important;

  & > section {
    width: 100%;
    margin: 0;
  }
}

.inputNumber {
  width: 150px;
}

.asterisk {
  color: var(--danger);
}
