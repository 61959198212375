@import '../../../../assets/styles/variables.scss';

.conatinerMask {
  @include flex(space-between, column, center);

  position: relative;
  height: 100%;
  width: 100%;

  .rowTitle {
    max-width: 100%;

    @include flex(center, row, none);

    margin: auto;

    h1 {
      color: white !important;
      margin: 0;
      font-size: 3em;
      box-sizing: border-box;
    }
  }

  .rowPanel {
    @include flex(center, row, none);

    position: absolute;
    bottom: 1rem;
    width: 100%;

    .rotateLeft,
    .rotateRight,
    .reset {
      padding: 0.5rem;
      border-radius: $b-radius;

      &:hover {
        background-color: var(--grey300);
      }
    }
  }
}
