@import '../../../assets/styles/variables.scss';

.collapseListWrapper {
  position: sticky;
  bottom: 0;
  height: 8px;
  width: 100%;
  background-color: var(--bg-tint);
}

.collapseList {
  @extend %absolute-centered;

  bottom: 0;
  cursor: pointer;

  .btn {
    fill: var(--grey100);
  }

  .btnPrimary {
    fill: var(--primary);
  }

  .arrowToTop {
    fill: var(--white);
  }

  .arrowToBottom {
    fill: var(--bg);
  }
}
