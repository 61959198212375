.main {
  height: 830px;
  width: 90%;
  background: var(--bg);
  padding: 15px 20px;
  margin: 0 auto;
  border-radius: 10px;

  .title {
    color: var(--font-color);
    text-align: center;
  }

  .notSelected {
    display: block;
    width: 100%;
    margin-top: 250px;

    .text {
      text-align: center;
    }
  }
}
