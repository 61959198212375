@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, row);

  .id {
    color: var(--grey100);
    font-size: 10px;
  }

  p {
    margin: 0;
  }
}
