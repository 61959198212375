@import '../../../assets/styles/variables.scss';
@import '../PhaseRow/PhaseRow.module.scss';

$border-height: 3px;

.phaseRowTime {
  @include flex(flex-start, row);

  position: absolute;
  z-index: $detailed-index + 2;
  top: 5.5rem;
  cursor: pointer;

  .blockTime {
    @include flex(center, column);

    position: relative;
    height: $programs-band-height + $border-height;
    max-width: 100%;
    font-size: 11px;
    transition: background-color $transition;
    text-wrap: nowrap;

    &.first {
      border-radius: $b-radius-left;
    }

    &.last {
      border-radius: $b-radius-right;
    }

    &.smallBlock {
      font-size: 7px;
    }

    .phaseBlockTime {
      position: absolute;
      top: -20px;
      right: 0;
      font-size: 1em;
      letter-spacing: -0.05em;

      &.small {
        top: -15px;

        &.odd {
          top: 40px;
        }
      }
    }

    .titleBlockPhase {
      @include flex(center, row);

      font-size: 1.2em;
      gap: 1rem;

      .phaseBlockTitleTime {
        margin: auto;
        font-size: 1em;
        line-height: 1.1em;
        /* stylelint-disable */
        -webkit-backdrop-filter: blur(5px);
        /* stylelint-enable */
        backdrop-filter: blur(5px);
      }
    }

    &:hover {
      background-color: var(--warning);
      opacity: 70%;
    }
  }
}
