@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%);

  display: grid;
  grid-template-rows: 40% 59%;
  gap: 2rem;

  .block {
    @include size(100%);

    display: grid;
    grid-template-columns: 35% 62%;
    gap: 2rem;
  }

  .column {
    @include size(80%, 100%);

    max-width: 550px;
  }
}
