@import '../../../../assets/styles/variables.scss';

.container {
  @include flex(flex-start, column, flex-start);

  width: 100%;
  margin-bottom: $margin * 2;
  text-align: start;
}

.header {
  text-align: center;
}
