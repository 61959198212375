@import '../../../assets/styles/zIndex.scss';
@import '../../../assets/styles/variables.scss';

.wrapper {
  display: flex;
  height: 10px;
  width: 100%;
  border-bottom: 1px solid var(--font-color);

  .phase {
    position: relative;

    .text {
      position: absolute;
      z-index: $tact-index + 1;
      bottom: -10px;
      left: 50%;
      background-color: var(--bg-tint-contrast);
      padding: 2px;
      margin: 0;
      font-size: 10px;
      transform: translate(-50%, 0);
      border-radius: $b-radius;
    }
  }
}
