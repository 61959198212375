/* stylelint-disable */
@import '../../../../assets/styles/variables.scss';

@mixin contrast-theme {
  h3,
  p {
    color: var(--font-color);
  }

  background: var(--bg-tint-contrast);
  border: none !important;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  height: 79vh;
  width: 100%;
  overflow-y: auto;
}

.cameraCaption {
  @include flex(space-between, column, none);

  flex-wrap: wrap;
  max-width: 100%;
  padding: 0 1rem 1rem;

  p {
    font-size: 10px;
    margin: 0;
  }
}

.small {
  position: relative;
  max-width: 100%;

  p {
    margin: 10;
  }

  input[type='button'] {
    @include size(16px);
    position: absolute;
    top: 3px;
    right: 1rem;
    background: center/contain no-repeat
      url('../../../../assets/icons/scaleUpVideo.svg');
    border: none;
    transition: all 0.2s;
    filter: $svg-icons-filter !important;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }
}

.mainCamera {
  height: 100%;
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  div[class$='-card-body'] {
    padding: 10px 25px 0 !important;
  }
}

.subCamera {
  height: 100%;
  div[class$='-card-body'] {
    padding: 10px !important;
  }
}

.cameraWrap {
  @include contrast-theme;

  box-shadow: $box-shadow-lite;
  border-radius: $b-radius;
  height: 100%;

  & > div:first-child {
    height: 80%;
  }
}
