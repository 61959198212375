@import '../../../../../../assets/styles/variables.scss';

.btnsWrap {
  display: flex;
  flex-wrap: nowrap;

  button {
    border-radius: $b-radius;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
