@import '../LayerTooltipContent.module.scss';

.fieldsRow {
  width: 100%;
  color: inherit;
  margin: 0;
  font-size: $tooltip-font-size;
  font-weight: 400;
  line-height: $row-line-height;
}

.container {
  margin-bottom: 10px;
}
