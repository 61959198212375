@import '../../../../assets/styles/variables.scss';
@import '../StatusCard/StatusCard.module.scss';

.userCardWrapper {
  width: 300px;
}

.tooltipBlock {
  p {
    margin: 0.5rem 0;
  }
}

.line {
  background: var(--white600);
  margin-top: 1.5rem;
}

.titleBlock {
  @include flex(space-between, row);

  & > h3 {
    font-weight: bolder;
  }

  .btn {
    @extend %manage-btn;
  }
}

.block {
  @include flex(flex-start, column, flex-start);

  max-height: 70vh;
  gap: 2rem;
  overflow-y: auto;
}
