@import '../../../../assets/styles/variables.scss';

.infoHead {
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    @include size(20px);

    cursor: pointer;

    &:hover {
      color: var(--antd-blue);
    }
  }
}
