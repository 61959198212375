@import '../../../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, row);

  position: relative;

  .id {
    color: var(--grey100);
    font-size: 10px;
  }

  .active {
    color: var(--font-color);
  }

  p {
    margin: 0;
  }

  .status {
    @include flex-gap(1rem, flex-end, row);

    position: absolute;
    right: 0;

    .icon {
      @include size(20px);
    }

    .warningIco {
      color: var(--warning);
    }
  }
}
