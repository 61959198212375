@import '../../../assets/styles/variables.scss';

.container,
.wrapper {
  @extend %absolute-centered;
}

.bar,
.wrapper {
  position: absolute;
  background: transparent;
  overflow: hidden;
}

.wrapper {
  transform-origin: top;
}

.lane,
.generalBlock {
  width: 100%;
}

.laneClick {
  cursor: pointer;
  pointer-events: all;
}

.generalBlock {
  position: absolute;

  &.generalBlockClick {
    transition: $transition;
    box-sizing: content-box;
    cursor: pointer;
    pointer-events: all;

    &:hover {
      background-color: var(--primary) !important;
    }
  }
}
