@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start, column, flex-start);

  margin-top: 2rem;

  .description {
    span[class*='content'] {
      color: var(--font-color) !important;
    }
  }
}
