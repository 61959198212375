@import '../../../../assets/styles/variables.scss';

.infoHead {
  @include flex-gap(1rem, flex-start, row);

  width: 70%;

  svg {
    @include size(20px);

    cursor: pointer;

    &:hover {
      color: var(--antd-blue);
    }
  }
}

.infoTitle {
  display: flex;
  justify-content: center;
  width: max-content;
  color: inherit;
  margin: 0;
}
