@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex(flex-start, row);

  position: relative;
  min-height: 50px;
  width: 100%;
  padding: $padding;
  border: 1px solid var(--bg-tint);

  .filterBlock {
    @include flex-gap(1rem, flex-start, row);

    flex-wrap: wrap;
    width: 70%;
    margin-left: 10rem;

    p {
      width: 100%;
      text-align: center;
    }
  }
}

.empty {
  @include flex;

  p {
    margin: 0;
  }
}
