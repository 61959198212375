@import '../../../../../assets/styles/variables.scss';

.item {
  color: var(--white) !important;
  background-color: var(--antd-blue) !important;
  white-space: wrap !important;
  border-radius: $b-radius !important;

  svg {
    fill: var(--white);
  }
}
