@import '../../../assets/styles/zIndex.scss';
@import '../../../assets/styles/variables.scss';

.tacts {
  position: absolute;
  z-index: $tact-index;
  display: flex;
  width: 100%;

  .tact {
    position: relative;
    border-right: 1px solid var(--font-color);

    &.isFirst {
      border-left: 1px solid var(--font-color);
    }

    &.isNotMain {
      border-right: 1px dashed var(--font-color);
    }

    &.isLadder {
      & > span {
        top: -3rem;
      }
    }

    .tactStop,
    .zero {
      position: absolute;
      top: -2rem;
      right: 0;
      font-size: 10px;
    }

    .zero {
      left: 0;
    }
  }
}
