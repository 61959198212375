@import '../../../assets/styles/variables.scss';

@mixin contrast-theme {
  h3,
  p {
    color: var(--font-color);
  }

  background: var(--bg-tint-contrast);
}

.mainGraphCard,
.tlDetectorsList,
.tlDetectorsInfo,
.mainGraphCardNotDetectors {
  border-radius: $b-radius;
  box-shadow: 1px 1px 20px #0003;
}

.containerNotDetectors {
  @include flex(center, row, center);

  width: 100%;

  .mainGraphCardNotDetectors {
    @include flex(center, column);

    width: 40%;
    margin-bottom: 4rem;
    box-shadow: $box-shadow;
    border-radius: $b-radius;
    text-align: center;
  }
}

.container {
  @include size(100%, 83vh);

  display: grid;
  width: 100%;
  grid-template-columns: 68% 30%;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 2rem;

  .subwrap {
    @include size(100%);

    display: grid;
    grid-template-rows: repeat(1fr);
    gap: 2rem;
  }
}
