@import '../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(20px, space-between, column, none);
}

.select {
  width: 70%;
  margin-bottom: 7px !important;
}
