@import '../../../../../assets/styles/variables.scss';

.container {
  position: relative;
  width: 100%;
  border: 1px solid var(--bg-tint);
  border-radius: $b-radius;

  .updateInfo {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: help;
  }
}
