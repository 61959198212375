@import '../../../../../assets/styles/variables.scss';

.footer {
  @include flex(flex-end, row);

  .container {
    @include flex(space-around, row);

    background-color: var(--primary);

    p {
      margin: 0;
    }
  }

  .formatOne {
    width: 16%;
  }

  .formatTwo {
    width: 28%;
  }

  .formatThree {
    width: 37%;
  }
}
