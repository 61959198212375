@import '../../../assets/styles/variables.scss';

$media-s-map-btns: $media-s + 21px;
$border-side: 1px solid var(--whity);
$child-containers-right-offset: 50px;
$child-containers-top-offset: -40px;
$child-containers-top-offset-thin-screen: 95px;

%divider-general {
  padding: 0;
  border-right: $border-side;
}

%systems {
  @include flex(flex-start, row);

  overflow: hidden;

  hr {
    height: 1px;
    width: 100%;
    background: var(--whity);
    padding: 0;
    margin: 0;
    border: none;
  }

  .buttonBox {
    display: block;
  }
}

%divider {
  >button {
    @extend %divider-general;
  }

  >:last-child {
    border: none;
  }
}
