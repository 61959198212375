@import '../../../../assets/styles/variables.scss';

.noData {
  @extend %absolute-centered;

  width: 200px;
  color: var(--font-color);
  background: var(--bg-tint);
  padding: $padding;
  font-size: 1.4rem;
  border-radius: $b-radius;
  text-align: center;
}
