@import '../../../../../assets/styles/variables.scss';

.wrapper {
  @include flex(center, column, center);

  gap: 1rem;

  .imgWrapper {
    @include flex(center);

    .img {
      height: 100%;
      width: 100%;
    }
  }
}
