@import '../../../../../../../assets/styles/variables.scss';

.container {
  @include flex(flex-start, column, none);

  gap: 1rem;

  h4 {
    font-weight: bold;
  }
}
