@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, row);

  cursor: help;

  .icon {
    @include size(20px);

    svg {
      @include size(100%);

      stroke: var(--font-color);
    }
  }

  p {
    margin: 0;
    text-align: center;
  }
}

.vertical {
  @include flex-gap(1rem);
}
