@import '../../../assets/styles/variables.scss';

.wrapper {
  @include flex(space-between, row, flex-start);

  position: absolute;
  z-index: 3;
  top: 0;
  width: 100%;
  background-color: rgb(0 0 0 / 30%);
  padding: $padding;
  overflow: hidden;
  transition: $transition;

  p {
    color: var(--white) !important;
    padding: 0;
    margin: 0;
    text-align: left;
  }

  .additionalContent {
    width: 40%;

    p {
      text-align: right;
    }
  }
}
