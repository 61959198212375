@import '../../../../assets/styles/variables.scss';

.title {
  color: var(--font-color);
  margin-bottom: 8px;
  text-align: center;
  line-height: 18px;
}

.select {
  width: 100%;
  margin-bottom: 7px !important;

  & > div {
    color: var(--font-color);
    background: var(--bg-tint);
    border: 1px solid var(--disabled) !important;
  }

  & > span {
    color: var(--font-color);
  }
}
