@import '../../../../assets/styles/variables.scss';

.disabled {
  color: var(--disabled-tint) !important;
}

.active {
  color: var(--white) !important;
  background-color: var(--primary);

  &:hover {
    background-color: var(--primary) !important;
  }
}
