@import '../../../../assets/styles/variables.scss';

.rowContainer {
  @include flex(flex-start, row);

  .infoIcon {
    @include size(25px);
  }

  .columnContainer {
    @include flex(flex-start, column, flex-start);
    @include size(100%);

    span {
      @include flex(space-between, row, flex-start);

      width: fit-content;
      color: var(--font-color);
      margin-left: 1rem;
      font-size: 12px;
      text-align: left;

      p {
        margin: 0;
      }

      .time {
        margin-left: 1rem;

        strong {
          animation-duration: 1s;
          animation-name: showTime;
          animation-iteration-count: infinite;

          @keyframes showTime {
            from {
              color: var(--font-color);
            }

            to {
              color: var(--bg-tint-contrast);
            }
          }
        }
      }
    }
  }
}
