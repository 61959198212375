@import '../styles/variables.scss';

.zoom {
  position: absolute;
  top: 35vh;
  right: 0;
  font-size: 3rem;
  border-radius: $b-radius;

  span {
    @include flex;

    color: var(--sidebar-btn);
    overflow: hidden;
    transition: none;
  }

  .buttonBox {
    margin-bottom: 10px;

    >button {
      padding: 0;
      border-bottom: $border-side;
    }

    >:last-child {
      border: none;
    }

    & button {
      color: var(--sidebar-btn);
    }

    :active {
      color: var(--white);
      background: var(--primary);
      transition: none;

      span {
        color: var(--white);
        transition: none;
      }
    }
  }

  .mode {
    width: 40px;
    margin-bottom: 15px;
    border-radius: $b-radius;
    overflow: hidden;
  }

  .reset {
    width: 40px;
    margin-top: 15px;
    border-radius: $b-radius;
    overflow: hidden;
  }
}
