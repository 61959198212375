@import '/src/assets/styles/variables.scss';

.container {
  @include flex;
  @include size(100%);

  .block {
    @include flex-gap(2rem, center, column);
    @include size(100%, 20vh);

    border: 1px solid var(--bg-tint);
    border-radius: $b-radius;

    .text {
      margin: 0;
    }

    .select {
      width: 60%;
    }
  }
}
