@import '../../../../assets/styles/variables.scss';

.detectorsInfo {
  @include flex-gap(2rem, flex-start);
  @include size(100%);

  position: relative;
  background-color: var(--bg);
  padding: $padding;
  border-radius: $b-radius;

  .tabsCustom div[role='tab'] {
    text-transform: uppercase;
  }

  .tabsCustom {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .tabsCustom > div {
    width: 100%;

    & > div {
      justify-content: center;
      align-items: center;
    }
  }
}
