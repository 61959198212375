@import '../../../../../assets/styles/variables.scss';

.signalPRg {
  @include flex(center, column);

  max-width: $media-s;
  margin: 0;
  text-align: center;
  border: 2px solid var(--bg-tint-contrast);
  border-radius: $b-radius;
  box-shadow: $box-shadow;
}

.signalGraphWrap {
  max-width: $media-xs;
  overflow-x: auto;
}

.titleContainer {
  @include flex-gap(2rem, flex-start, row);

  width: 100%;

  .iconInfo {
    width: 30px;
  }

  strong {
    padding-top: 0.5rem;
    font-size: 16px;
  }
}

.selectText {
  height: 100%;
  width: 100%;
}

.wrapper {
  width: 700px;
}
