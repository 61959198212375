@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%, 85%);

  overflow-y: auto;

  &.additional {
    display: grid;
    padding-right: $padding;
    grid-template-columns: 45% 50%;
    gap: 2rem;
  }
}
