@import '/src/assets/styles/variables.scss';

.container {
  @include flex(center, row);

  position: relative;
  width: 100%;

  .radioGroup {
    margin-top: 15px;

    & > label {
      color: var(--font-color);
      background: var(--bg-tint);
      font-size: 12px;
      font-weight: 400;
      text-align: center;

      &:hover {
        color: var(--font-color);
        background: var(--bg);
      }
    }
  }
}
