.textItem {
  width: 100%;
  background: var(--bg-tint);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;

  p {
    width: 100%;
    margin: 0;
    word-wrap: break-word;
  }

  .label {
    color: var(--font-color-lite);
    font-size: 12px;
  }
}
