@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, space-between, row);

  position: relative;
  min-height: 30px;

  .warnings {
    @include flex-gap(1rem, flex-start, row);

    flex-wrap: wrap;
    width: 70%;
  }

  .btn {
    @include flex-gap(1rem, flex-end, row, flex-end);

    position: absolute;
    right: 0;
    bottom: 0;
  }
}
