@import '../../../../../assets/styles/variables.scss';

.ico {
  @include size(25px);

  position: relative;
  cursor: pointer;

  svg {
    @include size(100%);

    color: var(--font-size);
    transition: $transition;
  }

  > sup {
    color: var(--white) !important;
    font-size: 9px !important;
  }

  &:hover {
    svg {
      color: var(--primary);
    }
  }
}
