@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%);

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 2rem;
  overflow-y: auto;

  &.bottomBlock {
    &::after {
      @include size(100%, $padding);

      display: block;
      content: '';
    }
  }
}
