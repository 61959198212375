@import '/src/assets/styles/variables.scss';

.myModal {
  > div > div:nth-child(3) {
    padding: 0 $padding-card !important;
  }

  button {
    span {
      text-transform: uppercase;
    }
  }
}
