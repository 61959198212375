@import '/src/assets/styles/variables.scss';

.title {
  @include flex-gap(1rem, flex-start, row);

  width: 100%;

  .id {
    color: var(--grey100);
    font-size: 10px;

    &.active {
      color: var(--white);
    }
  }

  p {
    margin: 0;
  }
}
