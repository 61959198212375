@import '../../../assets/styles/variables.scss';

.container {
  @include flex;

  .title {
    font-weight: bold;
  }

  strong {
    color: var(--warning);
  }
}
