@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start);
  @include size(100%);

  position: relative;
  background-color: var(--bg);
  padding: $padding-card;
  border-radius: $b-radius;

  & > h3 {
    font-weight: bolder;
  }
}
