@import '../../../../assets/styles/variables.scss';

.container {
  @include flex;
}

.menuContainer {
  li[role='menuitem'][class*='selected'] {
    span > div > span {
      color: var(--primary);
    }
  }
}
