@import '/src/assets/styles/variables.scss';

.container {
  display: block;

  .img {
    @include size(100%, 200px);

    flex: 1 0 auto;
    border-radius: $b-radius-top;
    object-fit: contain;
    overflow: hidden;
  }

  &.invisible {
    display: none !important;
  }
}
