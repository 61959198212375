@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%);

  display: grid;
  max-height: 70vh;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  h3 {
    width: 100%;
    font-weight: bolder;
    text-align: left;
  }

  @media (max-width: $media-l) {
    grid-template-columns: 1fr 1fr;
  }
}
