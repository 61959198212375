@import '../../../../../../../../assets/styles/variables.scss';

.titleRow {
  @include flex(space-between, row);

  gap: 3rem;

  .title {
    vertical-align: middle;
  }

  .icons {
    @include flex(space-between, row);

    gap: 0.5rem;

    .icon {
      @include size(25px);

      cursor: pointer;
      transition: color $transition;

      svg {
        @include size(100%);
      }

      &:hover {
        color: var(--antd-blue);
      }
    }

    .same {
      cursor: help !important;
    }

    .active {
      @include size(30px);

      color: var(--antd-blue);
    }
  }
}
