@import '/src/assets/styles/variables.scss';

.btn {
  position: absolute !important;
  top: 0;
  left: 0;

  .block {
    @include size(100%);
    @include flex-gap(1rem, flex-start, row, center);

    & > p {
      margin: 0;
    }
  }
}
