@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, column, flex-start);

  flex-wrap: wrap;
  width: 100%;

  .title {
    @include flex-gap(1rem, flex-start, row);

    width: 48%;
    opacity: 100%;
    transition: $transition;

    p {
      width: 100%;
      margin: 0;
      font-size: 9px;
    }

    svg {
      @include size(16px);
    }

    &.pointer {
      cursor: pointer;
    }
  }

  .hover {
    opacity: 30%;
  }
}
