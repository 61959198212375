@import '../../../../../assets/styles/variables.scss';

.wrapper {
  position: absolute;
  bottom: -5px;
  left: -5px;
  background-color: red;
  font-size: 1.1rem;
  border-radius: 50%;

  @include size(15px);
  @include flex(center);
}

.small {
  @include size(12px);

  font-size: 8px;
}
