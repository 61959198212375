@import '/src/assets/styles/variables.scss';

.errorTitle {
  width: max-content;
}

.container {
  @include flex;

  .tooltipTitle {
    @include flex(center, row);

    width: max-content;
    color: inherit;
    margin: 0;
  }
}
