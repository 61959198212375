@import '../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start, row);

  padding: $padding;
  margin: -4rem 0 0 5rem;

  .title {
    @include flex-gap(1rem, flex-start, row);

    opacity: 100%;
    transition: $transition;
    cursor: pointer;

    p {
      margin: 0;
      font-size: 12px;
    }

    svg {
      @include size(16px);
    }
  }

  .hover {
    opacity: 30%;
  }
}
