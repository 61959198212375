@import '../../../../../assets/styles/variables.scss';
@import '../../../../MapBtns/styles/variables.scss';

$map-info-bottom: 30px;

.container {
  @include flex-gap(1rem, flex-end, row, flex-end);

  position: absolute;
  z-index: $map-btns-index - 1;
  right: $map-btns-right + $child-containers-right-offset;
  bottom: $map-info-bottom;
  pointer-events: none;
  transition: $transition;

  &.isInfoPanel {
    transform: translate(-$info-panel-width, 0);
  }

  &.isTimeSlider {
    bottom: calc($map-info-bottom + 70px);
  }
}

.icon {
  svg {
    path {
      stroke: var(--font-color);
    }

    circle {
      fill: var(--font-color);
    }
  }
}

.meteoIcon {
  svg {
    path {
      fill: var(--font-color);
    }
  }
}
