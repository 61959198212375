@import '/src/assets/styles/variables.scss';

.ico {
  @include size(16px);

  position: absolute;
  top: 1rem;
  right: 1rem;

  & > svg {
    @include size(100%);

    opacity: 100%;
    transition: $transition;
    cursor: help;

    &:hover {
      opacity: 80%;
    }
  }
}
