@import '../../../../assets/styles/variables.scss';

.wrapper {
  display: flex;
  gap: 1rem;

  button {
    @include flex(center, row, center);

    background-color: inherit !important;
    box-shadow: none !important;
    border: none !important;

    &:hover {
      background-color: inherit;
    }
  }
}
