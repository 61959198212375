@import '../../../assets/styles/variables.scss';

.profileWrapper {
  fill: var(--sidebar-profile);
  stroke: var(--white);
  cursor: pointer;
}

.profileIco {
  fill: inherit;
  stroke: inherit;
  cursor: inherit;
  transition: $transition;

  &:hover:not(.active) {
    fill: var(--primary);
  }

  &.active {
    fill: var(--primary);

    &:hover {
      opacity: 80%;
    }
  }
}

.active {
  fill: var(--primary);
}
