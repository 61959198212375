@import '../../../assets/styles/variables.scss';

.title {
  margin-bottom: 2rem;
  font-weight: bold;
  align-self: flex-start;
}

.titleWrapper {
  @include flex(space-between, row);

  width: 100%;
  margin-bottom: 2rem;
  align-self: center;
}
