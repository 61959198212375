.box {
  max-height: 750px;
  width: 100%;
  overflow-y: auto;
}

.title {
  color: var(--font-color);
  text-align: center;
}
