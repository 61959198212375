@import '../../../../../assets/styles/variables.scss';

.wrapper {
  @include flex(space-between, row, flex-start);

  width: 100%;

  .title {
    color: var(--font-color);
    text-align: center;
  }

  .separator {
    height: 700px;
    width: 2px;
    background: var(--disabled-tint);
    margin: auto 0;
  }

  .wrapItem {
    width: 48%;
  }
}

.submit {
  margin: 10px auto;
}
