@import '/src/assets/styles/variables.scss';

.container {
  @include flex;
  @include size(100%);

  .block {
    @include flex-gap(2rem, center, column);
    @include size(100%, 20vh);

    border: 1px solid var(--grey100);
    border-radius: $b-radius;

    h4 {
      font-size: 18px;
      text-align: center;

      @media (max-width: $media-xl) {
        font-size: 16px;
      }

      @media (max-width: $media-l) {
        font-size: 14px;
      }

      @media (max-width: $media-m) {
        font-size: 12px;
      }
    }
  }
}
