@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-end, row, flex-end);

  position: absolute;
  top: 0;
  right: 0;
}

.ico {
  @include size(20px);

  display: block;
  cursor: pointer;

  svg {
    @include size(100%);

    color: var(--font-size);
    transition: $transition;
  }

  &:hover {
    svg {
      color: var(--primary);
    }
  }
}

.help {
  cursor: help;
}
