@import '/src/assets/styles/variables.scss';

.ico {
  @include size(30px);
  @include flex(center, row);

  border-width: 2px;
  border-style: solid;
  border-radius: $b-radius;

  svg {
    @include size(100%);
  }
}
