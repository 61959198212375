@import '../../../../assets/styles/variables.scss';

.wrapper {
  @include flex(none, column, none);
  @include size(100%);

  background-color: var(--bg-tint);
  padding: 1rem;
  box-shadow: 1px 1px 23px #00000036;
  border-radius: $b-radius;
}
