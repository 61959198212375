@import '../../../../../../assets/styles/variables.scss';

.wrapper {
  width: 100%;
  padding: 2rem;

  .notValid {
    color: red;
    opacity: 70%;
    text-align: center;
  }

  .addBtn {
    margin-bottom: $margin;
  }

  .text {
    padding: 0;
    margin: 0;
    opacity: 50%;
  }

  .formats {
    list-style: none;
    padding-inline-start: 0;

    @include flex(flex-start, row, center);

    gap: 1rem;

    li {
      opacity: 50%;
    }
  }
}
