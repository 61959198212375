@import '../styles/variables.scss';
@import './../../../assets/styles/index.scss';

$main-panel-width: 400px;
$main-panel-height: 200px;
$block-size: 32px;
$block-size-small: calc(2 * $block-size / 3);
$margin-size: 2px;
$text-block-height: 16px;

.block {
  background: var(--bg);
  overflow: hidden;
  box-shadow: $box-shadow;
  border-radius: var(--border-radius);
}

.btnsBlock {
  @extend %systems;
  @extend %divider;

  background: var(--bg) !important;

  svg path {
    stroke: var(--font-primary) !important;
  }
}

.activeBtn {
  svg path {
    stroke: var(--white) !important;
  }
}

.legendItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $block-size-small;
  width: $block-size;
  color: white;
  margin: $margin-size;
  line-height: $block-size-small;

  &:nth-child(3n - 2) {
    height: $block-size;
    line-height: $block-size;
  }
}

.legendItemText {
  height: $text-block-height;
  margin-bottom: 2 * $margin-size;
}

.legendItemContainer {
  display: flex;
  align-items: flex-end;
}

.legendPanel {
  position: absolute !important;
  top: $child-containers-top-offset-thin-screen !important;
  right: 0;
  height: $main-panel-height;
  width: $main-panel-width;
  margin-top: $child-containers-top-offset !important;
  border-radius: $b-radius;
  border-width: 0 !important;
}
