@import '../../../../assets/styles/variables.scss';

.btsWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  button {
    border-radius: $b-radius;
  }
}
