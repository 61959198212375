@import './../../../../assets/styles/variables.scss';

.box {
  @include flex;

  width: 100%;
  color: var(--font-color);
  margin-bottom: 100px;

  .line {
    width: 100%;
    background: var(--white600);
    margin: 7px 0;
  }

  .title {
    color: inherit;
  }
}

.noLinkedText {
  width: 70%;
  padding: 0.5rem;
  margin: 2rem auto;
  font-size: 16px;
  text-align: center;
  border: 2px solid var(--bg-tint);
  border-radius: $b-radius;
}

.videoDt {
  @include flex;

  width: 100%;
  margin-bottom: 10px;
  border: 1px solid var(--antd-blue-opacity8a);
  border-radius: $b-radius;
}
