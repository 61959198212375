@import '/src/assets/styles/variables.scss';

.container {
  & > h5 {
    font-weight: bold;
  }

  .map {
    @include size(100%, 90%);

    position: relative;
    border: 1px solid var(--bg-tint);
    border-radius: $b-radius;
  }
}
