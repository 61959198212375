.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-inline-start: 0;

  li:not(:last-child) {
    margin-bottom: 5px;
  }
}
