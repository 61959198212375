@import '/src/assets/styles/variables.scss';

$media-wrap: 1400px;

.btns {
  @include flex-gap(1rem, flex-end, row, flex-end);

  position: absolute;
  right: 0;
  bottom: 0;

  &.edit {
    @media (max-width: $media-wrap) {
      flex-direction: column;
    }
  }
}
