@import '../../../assets/styles/variables.scss';

$margin-map: 20px;
$opacity: 90%;

.scaleLineContainer {
  position: absolute;
  bottom: 30px;
  left: $sidebar-width + $minific-map-width + $margin-map;
  color: var(--font-color);
  background-color: var(--bg) !important;
  padding: 2px;
  opacity: $opacity !important;
  text-align: center;
  border: 1px solid var(--font-color-light);
  border-radius: $b-radius;
  transition: left $transition;
  animation: showBtn;
  animation-duration: 0.5s;

  & > div {
    border-top-width: 0 !important;
  }

  &.isPanel {
    bottom: 0 !important;
    left: $panel-width + $sidebar-width - 50px !important;
  }

  &.isMinificCloseIsPanel {
    bottom: 0 !important;
    left: $margin-map + 50px !important;
  }

  &.isMinificSettingClose {
    bottom: 0 !important;
    left: -($minific-map-width - $margin-map + 20px) !important;
  }

  &.isMinificClose {
    bottom: 0 !important;
    left: -($minific-map-width - $margin-map - 20px) !important;
  }
}

@keyframes showBtn {
  from {
    opacity: 0%;
  }

  to {
    opacity: $opacity;
  }
}
