@import '../../../../../assets/styles/variables.scss';

.container {
  position: relative;
  height: 200px;
  width: 100%;
  background-color: var(--bg-tint);
  padding: 50px 5px 10px;
  overflow: hidden;
  text-align: center;
  border-radius: $b-radius;
}

.barChart {
  @include size(100%);
}
