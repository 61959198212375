/* stylelint-disable selector-class-pattern */
.popoverClear {
  div[class*='ant-popover-inner'] {
    background: transparent !important;
    padding: 0 !important;
  }

  div[class*='ant-popover-inner-content'] {
    background: transparent;
    padding: 0;
  }

  div[class*='ant-popover-arrow']::before {
    background: var(--bg) !important;
  }
}
