@import '../../../../assets/styles/variables.scss';

.labelGraph {
  font-size: 1.4rem;
  font-weight: 400;
  fill: var(--font-color);
  line-height: 17px;
  letter-spacing: 0;
  text-align: center;

  @media (max-width: $media-xxl) {
    font-size: 1.2rem;
  }

  @media (max-width: $media-xl) {
    font-size: 1rem;
  }
}
