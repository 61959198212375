@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, center, row);

  width: 100%;
  padding: $padding;
  border-top: 1px solid var(--white600);

  p {
    font-size: 12px;
  }
}
