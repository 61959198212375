@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start);

  .phase,
  .time {
    @include flex;

    width: 27px;
    color: var(--whity);
    padding: 2px 4px;
    font-size: 10px;
    border-radius: $b-radius;
  }

  .phase {
    background-color: var(--antd-blue);
  }

  .time {
    background-color: var(--grey100);
  }
}
