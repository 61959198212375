@import '../../../../../assets/styles/variables.scss';

.arrow {
  height: 20px !important;
  width: 20px !important;

  &::before {
    color: var(--font-color) !important;
    transition: $transition;
  }

  &:hover:not([class*='disabled']) {
    &::before {
      color: var(--antd-blue) !important;
    }
  }
}
