.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 1rem;
  padding-inline-start: 0;
  list-style: none;
}

.text {
  opacity: 50%;
  text-align: center;
}
