@import '/src/assets/styles/variables.scss';

.item {
  align-items: flex-start !important;
  padding: 16px !important;
  border-bottom: 1px solid var(--white600) !important;
  transition: $transition;

  &.clickable {
    cursor: pointer;
  }

  &.active {
    background-color: var(--bg-tint);
    cursor: default;
  }

  &:hover {
    background-color: var(--bg-tint);
  }
}
