@import '/src/assets/styles/variables.scss';

.btnBlock {
  position: absolute;
  right: 10px;
  bottom: 10px;

  .btn {
    width: 140px;
    border-radius: $b-radius;
    border: 0;
  }
}
