@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start);

  width: 100%;

  & > section {
    width: 100%;
  }
}
