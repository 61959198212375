@import '../../../assets/styles/variables.scss';

%sub {
  @include font-color-tint(0.8);

  font-size: 13px;
  font-weight: 300;
}

.container {
  @include flex(space-between);

  height: 112px;
  width: $simple-accordion-width;
  background: var(--bg);
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: $b-radius;

  .firstRow {
    @extend %space-between;

    align-items: flex-start;
    height: 50px;
  }

  .secondRow {
    @extend %space-between;

    align-items: flex-end;
    height: 30px;
  }

  .amountContainer {
    text-align: right;
    line-height: 20px;

    .amount {
      font-size: 2.6rem;

      > span {
        @extend %sub;

        font-size: 17px;
      }
    }

    .subAmountInfo {
      @extend %sub;

      .victimsAmount {
        color: #e35744;
        font-weight: bold;
      }
    }
  }

  .titleContainer {
    width: $simple-accordion-width - 30px;
    line-height: 18px;

    .title {
      width: 155px;
      font-size: 1.2rem;
      text-transform: uppercase;
    }

    .subtitle {
      @extend %sub;

      font-size: 1.2rem;
    }
  }

  .btnContainer {
    @include flex(flex-end);

    height: 100%;

    .arrowBtn {
      @include flex(flex-end);

      color: var(--font-primary);
      background: var(--bg);
      border: none;
      cursor: pointer;
    }
  }
}
