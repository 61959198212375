@import '/src/assets/styles/variables.scss';

.tooltipTitleTl {
  @include flex(center, column);

  width: 100%;
  color: inherit;
  padding: $padding;

  p {
    margin: $padding 0;
    font-size: 12px;
    text-align: center;
  }

  .status {
    @include flex-gap(1rem, center, row);
  }

  .disabledText {
    color: var(--danger);
  }

  .warningText {
    color: var(--warning);
  }
}

.line {
  border-top: 1px solid var(--white600);
}
