@import '../../assets/styles/variables.scss';
@import '../../assets/styles/zIndex.scss';

.container {
  position: absolute;
  z-index: $map-btns-index;
  bottom: 1rem;
  left: 50%;
  height: 20px;
  width: 50%;
  transform: translate(-50%, 0);
}
