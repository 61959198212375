@import '../../../assets/styles/variables.scss';

$ico-size: 18px;

.container {
  @include flex-gap(2rem, flex-start);

  .containerRowTitle {
    @include flex-gap(1rem, center, row, flex-start);

    .systemBlock {
      @include size(28px);

      svg {
        width: 100%;
      }

      &.allSystem {
        svg {
          height: $ico-size !important;
          width: $ico-size !important;

          rect {
            stroke: var(--font-color);
          }
        }
      }
    }
  }

  .containerRow {
    @include size(100%, 82vh);

    display: grid;
    grid-template-columns: 0.65fr 1fr 1fr;
    gap: 2rem;

    .column {
      display: grid;
      max-height: 82vh;
      grid-template-rows: repeat(2, 49%);
      gap: 2rem;
    }
  }
}
