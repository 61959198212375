@import '../../../assets/styles/variables.scss';

@mixin theme-light-scheme {
  h3,
  p {
    color: var(--font-color-scheme);
  }

  background: var(--bg-light-scheme);
}

%tl-box {
  box-shadow: $box-shadow;
  border-radius: $b-radius;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-flow: wrap !important;
  flex-direction: row !important;
  max-height: 82vh;
  padding: 20px;
  gap: 25px;
  overflow-y: auto;
  overflow-x: hidden;

  .schemeCRs {
    @extend %tl-box;

    width: 100%;
    flex-grow: 3;
    overflow-y: scroll;

    @media (min-width: $media-xxl) {
      height: 80vh;
      max-width: 30%;
    }
  }

  .subwrap {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: 15px;
    flex-grow: 1;

    @media (min-width: $media-xxl) {
      width: 65%;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: var(--bg-tint-contrast);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--white600);
  }

  @media (min-width: $media-xxl) {
    overflow-y: hidden;
  }
}
