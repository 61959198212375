@import '../../../assets/styles/variables.scss';

.theme {
  @include flex;

  margin: 1rem 0.3rem;
}

.themeLabel {
  padding: 0.8rem 0;
  font-size: 1rem;
  text-align: center;
}
