@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start, column, flex-start);

  width: 100%;
  padding: $padding;
  border: 1px solid var(--bg-tint);
  border-radius: $b-radius;

  .title {
    margin: 0;
    font-weight: bold;
  }

  .row {
    @include flex(space-between, row);

    width: 100%;

    & > div {
      width: 45%;

      p {
        margin-bottom: 4px;
        font-size: 12px;
      }

      .input {
        width: 100%;
      }
    }
  }

  .btnBlock {
    @include flex(flex-end, row);

    width: 100%;
  }
}
