@import '../../../assets/styles/variables.scss';

.containerNotData {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
