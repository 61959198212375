@mixin flex($justify: center, $direction: column, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

%reset-btn-style {
  background-color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
}

@mixin tabs-border-bottom($border: none) {
  > div {
    > div {
      &::before {
        border-bottom: $border !important;
      }
    }
  }
}
