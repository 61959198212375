@import '../../../assets/styles/variables.scss';

.copyButton {
  @include flex(center, row, center);

  padding: 4px;
  border: 1px solid var(--font-color-lite);
  border-radius: 4px;
  opacity: 40%;
  transition: $transition;
  cursor: pointer;

  &:hover {
    opacity: 100%;
  }
}

.copied {
  background: var(--logo-green);
  opacity: 100%;
}
