@import '../../../../assets/styles/variables.scss';

.container {
  position: relative;
  color: var(--font-color);
  margin: 0 auto;
  overflow: hidden !important;
  text-align: center;
  border-radius: 5px;
}
