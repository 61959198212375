@import '../../../../../../../../../assets/styles/variables.scss';

.blockCard {
  @include flex;

  height: 100%;
  background-color: var(--bg);
  padding: 0.5rem;
  border-radius: $b-radius;
  border: 1px solid var(--bg);
  transition: $transition;
  cursor: pointer;

  &:hover {
    border-color: var(--antd-blue);
  }

  & > span {
    @include flex;

    height: 100%;
  }

  &.disabled {
    background-color: var(--disabled);
    border-color: var(--disabled);
    cursor: not-allowed;
  }
}

.confirm {
  max-width: $max-confirm-width;
}
