@import '../../../../../../assets/styles/variables.scss';

.noEditable {
  padding-right: $padding-card;
}

.inputNumbers {
  width: 100%;
  margin: 0 !important;

  input {
    width: 100%;
    border: none;
  }
}
