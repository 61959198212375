@import '../../../../../assets/styles/variables.scss';

.footer {
  @include flex(space-around, row);

  color: var(--white);
  background-color: var(--primary);
  border-radius: $b-radius-bottom;

  p {
    margin: 0;
  }
}
