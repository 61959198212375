@import '../../../../assets/styles/variables.scss';

@mixin contrast-theme {
  h3,
  p {
    color: var(--font-color);
  }

  background: var(--bg-tint-contrast);
}

.mainGraphCard {
  @include size(100%);
  @include contrast-theme;

  padding: $padding;
  border-radius: $b-radius;

  & > div {
    width: 100%;
  }

  .title {
    text-align: center;
  }
}
