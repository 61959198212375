@import '../../../assets/styles/variables.scss';

.wrapper {
  padding: 10px 0;
}

.mainBtn {
  @include flex;

  height: 40px;
  width: 40px;
  background: var(--bg);
  margin: 12px 0;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: $transition;

  &:hover:not(.active) {
    background: var(--primary);

    svg {
      color: var(--white);
      stroke: var(--white);
    }
  }

  &.active {
    background: var(--primary);

    &:hover {
      opacity: 80%;
    }
  }

  &.collapsed {
    border: 2px solid var(--primary);
  }
}

.ico {
  stroke: var(--sidebar-btn);
}

.reactIco {
  color: var(--font-primary);
  font-size: 22px;
}

.reactIco,
.ico {
  transition: $transition;

  &.active {
    color: var(--white);
    stroke: var(--white);
  }
}
