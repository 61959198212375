@import '../../../../assets/styles/variables.scss';

%wrappers {
  position: absolute;
  left: 50%;
  padding: 0.5rem;
  font-size: 1.2rem;
  transform: translateX(-50%);
  border-radius: $b-radius;
}

.phaseCircleContainer {
  position: relative;
}

.trafficLightId {
  @extend %wrappers;
  @include flex(center, row);

  bottom: -13px;
  height: 32px;
  padding: 0;
  overflow: hidden;
  pointer-events: all;
}

.isActive > button {
  color: var(--white) !important;
}

.trafficLightIdOffset {
  bottom: -27px;
}

.detailedBtn {
  color: var(--font-color) !important;
  padding: 4px 8px !important;

  &:hover {
    color: var(--font-primary) !important;
  }
}

.disabledBtn {
  color: var(--font-color-dark) !important;
  background-color: var(--disabled-tint-dark) !important;
  border-color: var(--disabled-tint-dark) !important;
  border-radius: $b-radius !important;
}
