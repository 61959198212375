@import '../../../assets/styles/variables.scss';

.wrapper {
  position: absolute;
  width: 100%;

  .pointWrapper {
    transition: $transition-tact;
  }
}
