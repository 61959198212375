@import '../../../assets/styles/variables.scss';

.tabsCustom {
  color: var(--font-color);

  hr {
    background: var(--grey100);
  }
}

.tabsCustom div[role='tab'] {
  text-transform: uppercase;
}
