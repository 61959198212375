@import '../../../../assets/styles/variables.scss';

.container {
  @include flex;
  @include size(100%);

  padding: $padding;
  border-radius: $b-radius;

  p {
    color: var(--disabled-tint) !important;
  }
}

.background {
  background-color: var(--bg-tint) !important;
}
