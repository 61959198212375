@import '../../assets/styles/variables.scss';

@mixin timeline {
  position: absolute;
  width: 1px;
  font-size: 11px;
  visibility: visible;
}

.spin {
  @extend %absolute-centered;
}

.containerMain {
  @include flex(flex-start, column, flex-end);

  position: relative;
  z-index: $detailed-index + 1;
  width: 100%;
  color: var(--font-color);
  margin-top: 1rem;
}
