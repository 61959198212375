@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, center, column, flex-start);
  @include size(100%);

  & > h5 {
    font-weight: bold;
  }

  .content {
    @include size(100%);

    & > div {
      border: 1px solid var(--bg-tint);
      border-radius: $b-radius;
    }
  }
}
