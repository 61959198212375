@import '/src/assets/styles/variables.scss';

.formItem {
  label {
    margin-right: 100px;
    font-size: 1.8rem !important;
  }
}

.img {
  @include size(100%);

  min-height: 270px;
  min-width: 270px;
  color: var(--font-color);

  img {
    @include size(100%);

    overflow: hidden !important;
    object-fit: cover !important;
  }
}
