@import '/src/assets/styles/variables.scss';

.wrapper {
  height: 100%;
  width: 100%;
}

.errorBox {
  @include flex(center, column, center);

  height: 100%;
  width: 100%;
}
