.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .errorText {
    width: 100%;
    padding: 10px;
    font-size: 12px;
    text-align: center;
  }
}
