@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%);

  max-height: 160px;
  overflow-y: auto;

  .desc {
    span[class*='content'] {
      font-size: 9px;
    }

    span[class*='label'] {
      font-size: 11px;
    }
  }
}
