@import '../../../../../assets/styles/variables.scss';

.tableWrapper {
  @include flex(center, row, center);

  position: relative;
  min-height: 350px;
  width: 70%;
}

.tableWrapperPhase {
  max-width: 450px !important;
  min-width: 500px;
}

.buttonsBox {
  position: absolute;
  bottom: 0;
  display: flex;
}

.tableActionBtn {
  margin-right: 30px;
  border-radius: $b-radius;
  text-transform: uppercase;
}

.uploadBtn {
  display: block;
  height: 0;
  width: 0;
  visibility: hidden;
}
