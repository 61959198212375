@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/zIndex.scss';

.searchedRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-left: 10px;
  gap: 1rem;

  .drawer {
    z-index: $ten-index;
    color: var(--font-color);
  }
}

.title {
  color: var(--font-color);
}
