@import './styles/variables.scss';

.container {
  position: absolute;
  z-index: $map-btns-index + 1;
  top: 5vh;
  right: $map-btns-right;
  transition: right $transition;
}

.containerOffset {
  right: $map-btns-right + $info-panel-width;
}
