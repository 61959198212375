@import '../../../../assets/styles/variables.scss';

$width: 15px;
$height: 10px;

%arrow {
  position: absolute;
  height: 0;
  width: 0;
  transform: translate(-50%);
  border-top: 30px solid var(--primary);
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}

%crosshair-default {
  position: absolute;
  z-index: $default-index;
  box-shadow: 0 3px 1px 1px rgb(0 0 0 / 20%);
  border-radius: 50%;
  transform: translate(-50%, -40%);
}

.crosshairPopupMain {
  @extend %crosshair-default;
  @include flex(center, none, center);
  @include size(40px);

  bottom: 1rem;
  color: var(--font-color);
  background: var(--primary);
  padding: 5px;
  border: 2px solid var(--primary);

  svg {
    transform: scale(1.5);
  }

  .id {
    position: absolute;
    top: -30px;
    color: var(--font-color);
    background-color: var(--bg);
    padding: 2px 6px;
    border-radius: $b-radius;
  }
}

.crosshairPopupPoint {
  @extend %crosshair-default;
  @include size(10px);

  position: absolute;
  z-index: $default-index;
  background: var(--blue100);
  border: 1px solid var(--white);
}

.crosshairPopupArrow {
  @extend %arrow;

  bottom: 0.5rem;
  left: 50%;
}
