@import '../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start);
  @include size(100%);

  background-color: var(--bg);
  padding: $padding-card;
  border-radius: $b-radius;

  .titleRow {
    @include flex(center, row);

    position: relative;
    width: 100%;

    & > h3 {
      font-weight: bolder;
    }
  }
}
