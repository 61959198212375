@import '../../../assets/styles/variables.scss';

.wrapper {
  position: relative;

  @include flex(flex-start, column, flex-start);

  width: 100%;
  gap: 1rem;
}
