@import '../../../../../../../assets/styles/variables.scss';

.wrapper {
  @include flex(flex-start, column, flex-start);

  gap: 1rem;
  list-style: none;
  padding-inline-start: 0;

  .filter {
    display: flex;
    gap: 1rem;

    .key {
      font-weight: bold;
    }

    p {
      margin: 0;
    }

    .tag {
      text-wrap: wrap;
    }
  }
}
