@import '/src/assets/styles/variables.scss';
@import '../styles/basicControlStyles.module.scss';

.container {
  @include flex-gap(3rem, flex-start, column);
  @include size(100%, $min-container-height);

  max-height: $min-container-height;
  padding: $padding;

  h4 {
    font-weight: bold;
  }
}
