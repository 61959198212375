@import '/src/assets/styles/variables.scss';

.slider {
  @include flex-gap(1rem, flex-start, row);

  width: 100%;
  cursor: grab;

  & > div {
    width: 100%;
  }

  div[class*='next']::before {
    content: '→' !important;
  }

  div[class*='prev']::before {
    content: '←' !important;
  }

  div[class*='active'] {
    @include flex;
  }
}
