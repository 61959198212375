@import '../../../../../../assets/styles/variables.scss';

.tooltipContainer {
  @include flex(center, column, flex-start);

  width: 200px;

  p {
    font-size: 1rem;
  }

  .tag {
    margin: 0 auto;
  }
}
