@import '../../../assets/styles/variables.scss';

.container,
.wrapper {
  @extend %absolute-centered;
}

.bar,
.wrapper {
  position: absolute;
  background: transparent;
  overflow: hidden;
}

.wrapper {
  transform-origin: top;
}

.lane,
.generalBlock {
  width: 100%;
}

.lane {
  transition: $transition;
  cursor: pointer;
  pointer-events: all;

  &:hover {
    background:
      linear-gradient(
        0deg,
        rgb(0 0 0 / 0%) 0%,
        var(--primary) 80%
      ) !important;
  }
}

.disabled {
  cursor: not-allowed;

  &:hover {
    background:
      linear-gradient(
        0deg,
        rgb(0 0 0 / 0%) 0%,
        var(--disabled-dark) 80%
      ) !important;
  }
}

.generalBlock {
  position: absolute;
  background-color: var(--warning);
  transition: $transition;
  border: 2px solid var(--primary);
  border-radius: $b-radius;
  box-sizing: content-box;
  cursor: pointer;
  pointer-events: all;

  &:hover {
    background-color: var(--primary);
  }

  &.activeAll {
    background-color: var(--primary);

    &:hover {
      background-color: var(--primary);
      opacity: 90%;
    }
  }
}
