@import '../../../../assets/styles/variables.scss';

.container {
  padding: 0.4rem;
  border: 1px solid var(--bg-tint);
  border-radius: $b-radius;
}

.active {
  border-color: var(--success-log);
}
