@import '../../../../assets/styles/variables.scss';

.container {
  display: grid;
  max-height: 78vh;
  width: 100%;
  padding: 20px;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  overflow-y: auto;

  @media (max-width: $media-l) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $media-s) {
    grid-template-columns: 1fr;
  }
}

.cameraCaption {
  display: flex;
  justify-content: flex-start;
  height: 35px;
  width: 480px;
  gap: 40px;

  p {
    height: 100%;
    margin: 10px 0 0;
    white-space: nowrap;
    overflow-x: auto;
  }

  p.cameraID {
    max-width: 30%;
  }

  p.caption {
    max-width: 70%;
  }
}

.cameraWrap {
  width: 100%;
  background-color: var(--bg);
  box-shadow: $box-shadow-lite;
  border-radius: $b-radius;

  div[class$='-card-body'] {
    padding: 10px !important;
  }
}
