/* stylelint-disable declaration-block-no-redundant-longhand-properties */
@import '../../../assets/styles/variables.scss';
@import './TlPhaseSchemeGrid.module.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  height: 80vh;
  min-height: 45vh;
}

.phaseSchemeAlternative {
  height: 100% !important;
  min-height: 45vh;
  max-width: 50vw;
  min-width: 500px;
  background-color: var(--bg);
  padding: $padding-card;
  box-shadow: 1px 1px 20px #0003;
  border-radius: $b-radius;
  flex-grow: 2;
  overflow: auto;

  .phaseScTitle {
    text-align: center;
  }

  .containerImg {
    @include image-container;
    @include size(70%);

    .img {
      img {
        @include size(100%);

        display: block;
        padding: 20px 35px;
        overflow: hidden;
        object-fit: cover;
      }
    }

    @media (max-width: $media-l) {
      @include size(70%);
    }
  }
}

.schemeSwitch {
  height: fit-content;
  margin-top: -10px;
  margin-left: 20px;

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 70vh;
    padding: 0;
    margin: 0;
    list-style: none;

    button {
      width: 260px;
      margin: 10px;
      box-shadow: 1px 1px 8px #0003;
      border-radius: $b-radius;
    }
  }
}

.containerNotData {
  @include flex(center, row, center);

  width: 100%;

  .mainGraphCardNotData {
    @include flex(center, column);

    width: 40%;
    margin-bottom: 4rem;
    box-shadow: $box-shadow;
    border-radius: $b-radius;
    text-align: center;
  }
}

@media (orientation: portrait) {
  .container {
    flex-direction: column;
  }

  .phaseSchemeAlternative {
    height: 100%;
    width: 100%;
    margin-top: 20px !important;
    align-self: center;
  }

  .schemeSwitch {
    order: -1;

    ul {
      flex-direction: row;
    }
  }
}
