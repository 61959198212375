@import '../../../assets/styles/variables.scss';

$info-border: 8px;

.infoWrapper {
  margin-top: 20px;

  .warning {
    box-sizing: border-box;
    border: 2px solid var(--warning);
    cursor: help;
  }
}

.infoTop,
.infoCenter,
.infoBottom {
  @include flex;

  height: 56px;
  width: 64px;
  background: var(--bg-tint);
  padding: 4px;
  border: 1px solid var(--bg);
}

.infoTop {
  position: relative;
  border-radius: $info-border $info-border 0 0;

  .warningIco {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .colon {
    animation-name: colonAppearance;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @keyframes colonAppearance {
      from {
        color: var(--font-color);
      }

      to {
        color: var(--bg-tint);
      }
    }
  }
}

.infoCenter {
  border-radius: none;
}

.infoBottom {
  border-radius: 0 0 $info-border $info-border;
  text-transform: lowercase;
}

.secondRaw {
  color: var(--font-color);
  font-size: 0.9rem;
  text-align: center;
  word-break: break-all;
}
