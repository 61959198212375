@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start, row);

  width: 100%;
}

.radioContainer {
  display: flex !important;
  width: fit-content;
  gap: 0.3rem !important;

  & > label {
    color: var(--font-color);
    background: var(--bg-tint) !important;
    font-size: 0.9rem !important;
    text-align: center;
    transition: $transition !important;
    white-space: nowrap;

    &:hover {
      color: var(--font-color) !important;
      background-color: var(--bg) !important;
    }

    [class*='checked'] {
      color: var(--white) !important;
      background-color: var(--primary);
    }

    @media (max-width: 1450px) {
      font-size: 0.7rem !important;
    }

    @media (max-width: 1400px) {
      font-size: 0.5rem !important;
    }
  }

  .disabledBtn {
    color: var(--disabled-text) !important;
    background: var(--disabled) !important;
  }
}
