.wrapper {
  p {
    color: var(--font-color);
    padding: 0;
    margin: 0;

    span {
      font-weight: bold;
    }
  }
}
