@import '../../assets/styles/variables.scss';

.main {
  display: block;
  width: 100%;
  background: var(--bg);
  border-radius: 5px;
  overflow: hidden;

  .info {
    color: var(--font-color);
    font-size: 12px;
    text-align: center;
    transition: $transition;
  }

  .head {
    display: block;
    height: 70px;
    width: 100%;
    background: var(--primary);

    .header {
      @include flex(none, row);

      position: relative;
      top: 15px;
      width: 85%;
      margin: 0 auto;

      .avatar {
        @include flex(center, row, center);

        height: 80px;
        width: 80px;
        background: var(--font-color);
        border: 4px solid var(--bg);
        border-radius: 50%;

        .icon {
          color: var(--bg);
        }
      }

      .title {
        color: var(--font-color);
        margin-left: 20px;
      }
    }
  }

  .content {
    @include flex(flex-start, column, flex-start);

    padding: 20px;
    margin-top: 15px;

    .row {
      max-width: 220px;
      color: var(--font-color);
      margin-bottom: 5px;
      font-size: 13px;

      span {
        font-weight: bold;
      }
    }
  }
}

.wrapper {
  @include flex;
  @include size(100%);

  &.bg {
    background: var(--tooltip);
    padding: $padding;
    border-radius: $b-radius;
  }
}
