@import '../styles/variables.scss';
@import './../../../assets/styles/index.scss';

$grid-size: 3px;
$cell-size: 5px;
$plate-width: 72px;
$plate-font-size: 10px;
$margin-size-default: 6px;
$hover-color: rgb(200 200 200 / 70%);
$selected-color: rgb(21 115 255 / 100%);
$main-panel-width: 400px;
$main-panel-height: calc(100vh - 140px);
$panel-title-font-size: 14px;
$title-font-size: 14px;
$subtitle-font-size: 12px;
$hint-text-size: 12px;
$title-height: 24px;
$icon-size: 16px;
$right-offset: 50px;
$list-item-width: calc($main-panel-width - 4 * $cell-size);
$search-list-item-height: calc(75px - $margin-size-default);
$direction-list-item-height: 40px;

.searchPanel {
  position: absolute !important;
  top: $child-containers-top-offset-thin-screen !important;
  right: $right-offset;
  height: calc(100vh - 140px);
  max-height: calc(100vh - 140px);
  width: $main-panel-width;
  margin-top: $child-containers-top-offset !important;
  border-radius: $b-radius;
  border-width: 0 !important;
}

.searchPanelTitle {
  display: flex;
  align-items: center;
  height: $title-height;
  padding-left: $cell-size;
  font-size: $panel-title-font-size;
  line-height: $title-height;
}

.searchContainer {
  display: flex;
  align-items: center;
  width: $main-panel-width - 4 * $cell-size;
  margin-top: $margin-size-default;
  margin-bottom: $margin-size-default;
}

.searchSettingsContainer {
  width: $main-panel-width - 4 * $cell-size;
  padding: $margin-size-default;
  margin-top: $margin-size-default;
  margin-bottom: $margin-size-default;
  line-height: $title-height;
}

.settingsHint {
  color: var(--font-color-lite);
  padding-right: 4 * $margin-size-default;
  padding-left: 4 * $margin-size-default;
  margin-bottom: 2 * $margin-size-default;
  font-size: $hint-text-size;
  font-weight: lighter;
  line-height: $hint-text-size + $margin-size-default;
  font-variant: contextual;
  text-align: justify;
}

.listContainer {
  height: calc(100vh - 230px);
  width: $list-item-width;
}

.directionListContainer {
  height: calc(100vh - 300px);
  width: $list-item-width;
}

.listItem {
  width: $list-item-width;
  padding-right: 2 * $grid-size;
  padding-left: 2 * $grid-size;
  margin-top: 0;
  margin-bottom: $margin-size-default;
  border: $grid-size rgb(255 255 255 / 0%) solid;
  border-radius: 2 * $grid-size;
  border-bottom: 1px solid var(--bg-tint);
  line-height: 7 * $grid-size;

  &:nth-child(2n) {
    background-color: var(--bg-tint);
  }

  &:hover {
    box-shadow: $selected-color 0 0 0 $grid-size;
  }
}

.icon {
  height: $icon-size;
  width: $icon-size;
  margin-right: $grid-size;
}

.listItemTitle {
  display: flex;
  align-items: center;
  color: var(--font-color);
  font-size: $title-font-size;
  font-weight: bold;
}

.actionsContainer {
  margin-top: $grid-size;
  margin-left: 6 * $grid-size - $icon-size;
}

.narrativeItem {
  display: flex;
  align-items: center;
  font-size: $subtitle-font-size;
}

.narrativeItemTitle {
  width: 210px;
  margin-right: 4 * $cell-size;
}

.narrativeItemDistance {
  width: 60px;
  margin-right: 4 * $cell-size;
}

.narrativeItemTime {
  width: 80px;
}

.selectionActive {
  color: $selected-color;
}

.searchItemIconType {
  margin-right: $grid-size;
}

.directionSummaryContainer {
  display: flex;
  align-items: center;
}

.directionSummaryResultItem {
  margin-right: 2 * $margin-size-default;
}

.searchResultListItem {
  height: $search-list-item-height;
  max-height: $search-list-item-height;
  min-height: $search-list-item-height;
}

.directionResultListItem {
  height: $direction-list-item-height;
  max-height: $direction-list-item-height;
  min-height: $direction-list-item-height;
}
