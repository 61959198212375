@import '../../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(4rem, center, row);

  padding: $padding;

  p {
    margin: 0;
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
  }

  .tempContainer {
    @include flex-gap(1rem);

    cursor: help;

    .title {
      @include flex-gap(1rem, flex-start);

      svg {
        @include size(30px);
      }
    }
  }

  .weather {
    width: 20%;

    .weatherContainer {
      @include flex-gap(1rem, flex-start);

      cursor: help;

      .icon {
        @include size(100%);

        p {
          font-size: 15px;
        }

        svg {
          @include size(100%);
        }
      }
    }
  }
}
