@import '../../../../assets/styles/variables.scss';

.wrapper {
  padding: 0;
  margin: 0;
}

.sectionTitle {
  color: var(--font-color);
  margin-bottom: 8px;
  text-align: center;
  line-height: 18px;
}

.content {
  z-index: $popop-content-index;
  margin: 12px;
}

.detailsBtn {
  margin-right: 40px;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: $b-radius;
  text-transform: uppercase;
}

.line {
  background: var(--white600);
  margin: 7px 0;
}

.linkedText {
  width: 70%;
  padding: 0.5rem;
  margin: 2rem auto;
  font-size: 16px;
  text-align: center;
  border: 2px solid var(--bg-tint);
  border-radius: $b-radius;
}

.information {
  min-width: 220px;
  white-space: pre-line;
}

.unavailable {
  text-align: center;
}
