@import '../../assets/styles/variables.scss';

.container {
  height: 100vh;
  width: 100vw;
  background: var(--bg-tint);
  overflow: hidden;

  @include flex;

  h2 {
    color: var(--font-color);
  }

  .regionsButtons {
    @include flex(center, row);

    flex-wrap: wrap;
  }

  .btn {
    @include flex;
    @include size(230px, 100px);

    color: #fff !important;
    margin: 0 15px 15px 0;
    font-size: 1.6rem;
    white-space: wrap;
    box-shadow: -2px -2px 15px #0002;
    border-radius: $b-radius;

    &.disabled {
      background: var(--disabled);
    }
  }
}
