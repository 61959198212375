@import '/src/assets/styles/variables.scss';

.timePickerCard {
  @include flex(space-between, column, none);

  margin-bottom: 2rem;

  .title {
    margin: 0;
  }

  .timeCardStyle {
    @include flex-gap(20px, flex-start, column, none);

    margin: 20px 0;
    opacity: 0%;
    transition: opacity $transition;

    .spaceHorizontalDuration {
      @include flex(space-between, row);

      width: 100%;

      h4 {
        margin-right: 3rem;
      }
    }

    &.timeCardAnimate {
      opacity: 100%;
    }
  }
}
