@import '../../../assets/styles/variables.scss';

$box-shadow: -1px -1px 10px #00000080;

.info {
  position: relative;
  color: var(--font-color);
  background: var(--bg);
  padding-bottom: 70px;

  .wrapper {
    height: 90vh;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
  }

  .sectionTitle {
    color: var(--font-color);
    margin-bottom: 8px;
    text-align: center;
    line-height: 18px;
  }

  .content {
    z-index: $popop-content-index;
    margin: 12px;
  }

  .line {
    background: var(--white600);
    margin: 7px 0;
  }

  .linkedText {
    width: 70%;
    padding: 0.5rem;
    margin: 2rem auto;
    font-size: 16px;
    text-align: center;
    border: 2px solid var(--bg-tint);
    border-radius: $b-radius;
  }

  .information {
    min-width: 220px;
    white-space: pre-line;
  }
}
