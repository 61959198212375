@import '/src/assets/styles/variables.scss';

.radioContainer {
  display: flex !important;
  width: fit-content;
  gap: 0.3rem !important;

  & > label {
    color: var(--font-color);
    background: var(--bg-tint) !important;
    font-size: 20px !important;
    text-align: center;
    transition: $transition !important;

    &:hover {
      color: var(--font-color) !important;
      background-color: var(--bg) !important;
    }

    [class*='checked'] {
      color: var(--white) !important;
      background-color: var(--primary);
    }

    &[class*='disabled'] {
      background: var(--disabled) !important;

      &:hover {
        color: var(--disabled-text) !important;
        background-color: var(--disabled) !important;
      }
    }

    @media (max-width: 1450px) {
      font-size: 18px !important;
    }

    @media (max-width: 1400px) {
      font-size: 16px !important;
    }
  }
}
