@import '/src/assets/styles/variables.scss';

.avatar {
  @include flex-gap(1rem, center, column);

  display: block;

  .id {
    color: var(--grey100);
    font-size: 12px;
    text-align: center;
  }

  &.invisible {
    display: none;
  }
}
