@import '../../styles/variables.scss';
@import '../../../Overlays/DeviceWrapper/DeviceWrapper.module.scss';

.ico {
  @extend %absolute-centered;
}

.icoCursor {
  @extend %ico-cursor;
}

.unActive {
  opacity: $device-opacity;
}
