@import '../../../../assets/styles/variables.scss';

.wrapper {
  position: relative;

  @include flex(space-between, column);

  width: 40%;
  padding: 2rem;
  box-shadow: $box-shadow;
  border-radius: $b-radius;
  overflow-y: scroll;

  .search {
    margin-bottom: $padding * 2;
  }

  .collapseWrapper {
    position: relative;
    height: 95%;
    width: 100%;
    overflow: hidden;

    .collapse {
      height: 95%;
      overflow-y: scroll;
    }
  }

  .addBtn {
    align-self: flex-end;
  }
}
