@import '../../../../../assets/styles/variables.scss';

.dots {
  &::before {
    color: var(--font-color) !important;
    transition: $transition;
  }

  &:hover {
    &::before {
      color: var(--antd-blue) !important;
    }
  }
}
