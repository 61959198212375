@import '../Name.module.scss';

.tooltipTitleTl {
  @include flex(center, row);

  width: max-content;
  color: inherit;

  .status {
    height: 10px;
    width: 10px;
    margin-right: 1rem;
    opacity: 100%;
    border-radius: 50%;
    transition: $transition;
  }

  .statusPromTact {
    @include animation;
  }
}
