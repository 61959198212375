@import '../../../../assets/styles/variables.scss';

.container {
  @include flex(flex-start, column, flex-start);

  width: 150px;
  background-color: var(--bg);
  padding: $padding;
  gap: 1rem;
  border: 1px solid var(--font-color-lite) !important;
  border-radius: $b-radius;
  opacity: 85%;

  h4 {
    margin: 0;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  .payload {
    font-weight: bold;
  }
}
