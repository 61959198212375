@import '../../../../../assets/styles/variables.scss';

.containerDefault {
  overflow-x: hidden;

  p {
    color: var(--font-color);
    font-size: large;
    text-align: center;
  }
}

.containerRow {
  @include flex(flex-start, row, none);
}

.containerRowPhase {
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;

  @media (max-width: ($media-xl - 200)) {
    flex-direction: column !important;
  }
}

.containerColumn {
  @include flex(center, column, center);

  gap: 40px;
}
