@import '../../../assets/styles/variables.scss';

.table {
  height: 100%;

  [class*='sorter'] {
    cursor: pointer;
  }

  &.clickable {
    tr {
      cursor: pointer;
    }
  }
}

.filterAndSearch {
  @include flex-gap(2rem, flex-start, row);

  .title {
    min-width: fit-content;
    font-weight: bolder;
    text-align: center;
  }

  .leftBlock {
    @include flex(center, center, row);

    margin-left: auto;
  }
}
