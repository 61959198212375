@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, column, flex-start);
  @include size(100%, 300px);

  padding: $padding;
  border: 1px solid var(--bg-tint);
  border-radius: $b-radius;

  .title {
    margin: 0;
    font-size: 11px;
    font-weight: bold;
  }

  .content {
    @include size(100%);
    @include flex;

    .noData {
      color: var(--grey100);
      font-size: 12px;
      text-align: center;
    }
  }
}
