@import '/src/assets/styles/variables.scss';
@import '../../styles/variables.scss';

.tlIco {
  @extend %absolute-centered;
  @extend %ico-cursor;

  &.inactive {
    transition: $transition;

    &:hover {
      fill: var(--primary);
    }
  }

  &.activeId {
    fill: var(--primary);
  }
}
