@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex;

  & > p {
    margin: 0;
  }

  .disabledText {
    color: var(--danger);
  }
}
