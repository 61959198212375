@import '../../../../assets/styles/variables.scss';

.btnClose {
  position: absolute;
  z-index: $map-btns-index;
  bottom: 30px;
  left: $sidebar-width + 7px;
  transition: left $transition;
  border-radius: $b-radius;
  cursor: pointer;
}

.panelOpen {
  left: $panel-width + $sidebar-width + 34px !important;
}
