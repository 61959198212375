.infoRow {
  padding-left: 3rem;

  p {
    display: flex;
    justify-content: center;
    width: max-content;
    color: inherit;
    margin: 0;
    gap: 1rem;
  }
}
