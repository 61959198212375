@import '/src/assets/styles/variables.scss';

.container {
  @include flex(flex-start, column, flex-start);
  @include size(100%);

  position: relative;
  padding: 24px;
  border-radius: $b-radius;

  &.bg {
    background-color: var(--bg);
  }

  p {
    font-weight: bolder;
  }

  .empty {
    @extend %empty;
  }

  .title {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    font-weight: bolder;
  }

  .footer {
    position: absolute;
    bottom: 24px;
    left: 24px;

    p {
      margin: 0;
    }
  }

  .spin {
    @extend %absolute-centered;
  }

  .chartContainer {
    @include size(100%);

    margin: 0 auto;
  }
}
