@import '../../../../assets/styles/variables.scss';
@import '../PhaseCircleLayout/PhaseCircleLayout.module.scss';

.header {
  @extend %wrappers;
  @include flex(center, column);

  top: -60px;
  height: 80px;
  width: 110px;
  color: var(--white);
  background: var(--green200);

  .cycleLen {
    font-size: 1.5rem;

    > svg {
      margin: 0 12px -2px 0;
    }
  }
}

.headerOffset {
  top: -80px;
}

.line {
  height: 1px;
  width: 90%;
  background: var(--white);
  margin: 1px 0 2px;
  opacity: 50%;
}

.divider {
  margin-right: 5px;
  opacity: 60%;
}
