@import '/src/assets/styles/variables.scss';

.thread {
  @include size(100%, 84vh);
  @include flex(flex-start);

  position: relative;
  background-color: var(--bg);
  padding: $padding-card;
  border-radius: $b-radius;

  .model {
    @include size(100%);
    @include flex;
  }
}
