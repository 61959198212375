@import '/src/assets/styles/variables.scss';

.filterBlock {
  @include flex-gap(1rem, flex-start, row);

  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;

  & > p {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
