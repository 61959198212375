/* stylelint-disable declaration-block-no-redundant-longhand-properties */

@import '../../../assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start);

  max-height: 82vh;
  width: 100%;
  padding: 20px 80px 20px 20px;
  overflow-y: auto;
  overflow-x: hidden;

  .preLoaderCard {
    min-width: $media-m;
    box-shadow: $box-shadow;
    border-radius: $b-radius;
    text-align: center;

    @media (max-width: $media-xl) {
      min-width: $media-s;
    }
  }

  .columnContainer {
    @include flex-gap(2rem, flex-start, column, none);

    width: 100%;

    .icon {
      @include size(25px);

      position: fixed;
      top: 18%;
      right: 2%;
      transition: $transition;
      cursor: pointer;

      &:hover {
        color: var(--primary);
      }
    }
  }

  ::-webkit-scrollbar-track {
    background-color: var(--grey300-o09);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--white600-o80);
  }
}
