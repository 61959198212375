@import '/src/assets/styles/variables.scss';

.infoBlock {
  @include flex;

  position: absolute;
  z-index: 1;
  top: 1rem;
  right: 1rem;
  min-width: 70px;
  color: var(--white);
  font-size: 24px;
  font-weight: bold;
  border-radius: $b-radius;
  cursor: help;
}
