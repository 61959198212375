@import '../../../../assets/styles/variables.scss';

@mixin animation() {
  animation-name: promTactColor;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.directionName {
  @include flex-gap(5px, center, row);

  height: 1.5rem;
  color: var(--font-color);
  margin: 0;
  font-size: 10px;
  cursor: pointer;
  text-align: center;

  .ico {
    @include size(16px);

    opacity: 100%;
    transition: $transition;
  }

  .notAllowed {
    & > svg {
      path {
        fill: var(--dir-not-allowed);
      }
    }
  }

  .animate {
    @include animation;
  }
}

@keyframes promTactColor {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}
