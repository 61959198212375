@import '../../../../assets/styles/variables.scss';

.select {
  width: 70%;
  margin-bottom: 7px !important;
}

.loadingBlock {
  @include flex(center, row);

  height: 100%;
  width: 100%;
  margin-top: 30%;
}

.btn {
  width: 140px;
  border-radius: $b-radius;
}

.container {
  @include flex(space-between, column, none);

  gap: 20px;
}
