@import '../../../../assets/styles/variables.scss';

.settingBox {
  position: relative;
  display: flex;

  svg {
    position: absolute;
    right: 0;

    @include size(23px);

    cursor: pointer;
  }

  div {
    position: relative;
    width: 20px;
    margin-left: 12px;

    input {
      display: none;
    }

    svg {
      transition: ease $transition-time;
      filter:
        invert(60%) sepia(0%) saturate(1228%) hue-rotate(95deg)
        brightness(117%) contrast(94%);

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
