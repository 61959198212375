@import '/src/assets/styles/variables.scss';

.labelTabs {
  color: inherit;
  margin: 0;
  transition: $transition;

  &:hover {
    color: var(--primary);
  }
}

.labelDisabledTabs {
  color: var(--disabled-text);

  &:hover {
    color: var(--disabled-text);
  }
}
