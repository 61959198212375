@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, row);

  width: 100%;

  svg {
    fill: var(--warning);
  }

  span {
    width: 100%;
    text-wrap: wrap !important;
  }
}
