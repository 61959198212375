@import '../TlDetectors.module.scss';

.tlDetectorsList {
  @include flex;
  @include size(100%);
  @include contrast-theme;

  padding: $padding;

  h3 {
    font-weight: bold;
  }
}
