@import '../../../../../assets/styles/variables.scss';

.wrapper {
  position: relative;

  @include flex-gap(1rem, space-between, row, center);

  width: 100%;
  padding: 0.3rem;
  cursor: pointer;
  transition: $transition;
  border-radius: $b-radius;

  &:hover {
    color: var(--antd-blue);
  }

  &.isActive {
    color: var(--whity);
    background-color: var(--antd-blue);

    .downloadBtn {
      svg {
        color: var(--whity);
      }
    }
  }

  .input {
    height: 22px;
    width: 50%;
  }

  .titleBlock {
    @include flex-gap(1rem, flex-start, row, center);

    .icon {
      svg {
        @include size(20px);
      }
    }
  }
}
