@import '../../../../../../assets/styles/variables.scss';

.btnSelect {
  min-width: 80px;
  margin: 1rem auto 0;

  &.active {
    background-color: var(--success-log) !important;
    padding: 2px !important;
  }
}
