@import '/src/assets/styles/variables.scss';

.btn {
  width: 140px;
  border-radius: $b-radius;

  & > button {
    border-radius: $b-radius;
  }
}
