.text {
  margin: 0;
}

.wrapper {
  max-width: 400px;
  padding: 1rem;

  .title {
    text-transform: uppercase;
    text-align: center;
  }
}
