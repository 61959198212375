@import '/src/assets/styles/variables.scss';

.titleContainer {
  @include flex-gap(1rem, flex-start, row);

  .iconInfo {
    @include size(30px);

    color: var(--warning);
  }

  & > h3 {
    margin: 0;
    font-weight: bold;
  }
}
