@import '/src/assets/styles/variables.scss';

.listItem {
  display: block;
  min-height: 85px;
  background: var(--bg-tint);
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
  border-radius: $b-radius;
}

.fullData {
  grid-area: RefreshUpdateInfo;
}

.partialData {
  grid-area: PartialUpdateInfo;
}
