@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start, column, flex-start);
  @include size(100%);

  position: relative;
  padding: $padding;

  .title {
    @include flex(flex-start, column, flex-start);

    width: 90%;

    & > h6 {
      width: 100%;
      margin: 0;
      font-weight: bold;
      text-align: left;
      word-break: break-all;
    }
  }

  .main {
    max-height: 65%;
    width: 100%;
    overflow-y: auto;

    .dsc {
      @include flex-gap(4rem, flex-start, column, flex-start);

      span[class*='label'] {
        font-size: 11px;
      }

      span[class*='content'] {
        font-size: 9px;

        span[class*='ico'] {
          @include size(16px);

          min-width: 16px;
        }

        span[class*='id'] {
          font-size: 8px;
        }

        div[class*='container'] {
          @include flex(flex-start, row, flex-start);
        }
      }
    }
  }
}
