@import '/src/components/MapBtns/styles/variables.scss';

.olMap {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &.radius {
    border-radius: $b-radius;
  }
}
