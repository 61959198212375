.direction {
  position: relative;
  display: flex;
  height: 10px;
  width: 100%;
  margin: 0;

  .subTacts {
    position: absolute;
    top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0;

    .subTactsItem {
      height: 100%;
      margin: 0;
    }
  }
}
