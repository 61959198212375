@import '../../../../assets/styles/variables.scss';

.head {
  display: block;
  height: 70px;
  width: 100%;
  background: var(--primary);
  margin-bottom: 2.5rem;

  .header {
    @include flex(flex-start, row, center);

    position: relative;
    top: 15px;
    width: 85%;
    margin: 0 auto;
    gap: 1rem;

    .avatar {
      @include flex(center, row, center);

      height: 80px;
      width: 80px !important;
      background: var(--font-color);
      border: 4px solid var(--bg);
      border-radius: 50%;
      cursor: pointer;
      transition: $transition;

      .icon {
        color: var(--bg);
      }

      &:hover {
        border: 4px solid var(--antd-blue);
      }
    }

    .title {
      color: var(--font-color);
      text-align: center;
      cursor: default;
    }
  }
}
