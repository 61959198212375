@import '../../../../../../../assets/styles/variables.scss';

.box {
  width: 100%;
}

.title {
  color: var(--font-color);
  text-align: center;
}

.response {
  @include flex(center, row, center);

  min-height: 400px;
  width: 100%;
}
