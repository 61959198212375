@import '../../../../assets/styles/variables.scss';
@import '../../../MapBtns/styles/variables.scss';

$map-info-height: 100px;
$map-info-bottom: 30px;
$offset-right: $map-btns-right + $child-containers-right-offset;

%basic {
  position: absolute;
  z-index: $map-btns-index + 1;
  right: $map-btns-right;
  bottom: $map-info-bottom;
  transition: right $transition;
}

.mapInfo,
.zoomContainer {
  @extend %basic;

  z-index: $map-btns-index - 1;
  height: $map-info-height;
  width: 250px;
  color: var(--font-color);
  background: var(--bg);
  padding: 15px 20px;
  border-radius: $b-radius;
  opacity: 90%;
}

.mapInfo {
  right: $offset-right;
}

.mapInfoBtn {
  @extend %basic;
}

.zoomContainer {
  right: $offset-right;
  bottom: $map-info-bottom + $map-info-height + 10px;
  height: 180px;

  .zoomWrapper {
    @include flex(space-between, row);

    .compareBtn {
      width: 70px;
      color: var(--whity);
      margin: 3px 0;
    }
  }

  .zoomInput {
    width: 100px;
    margin: 3px 0;
  }

  .inputLabelFrom,
  .inputLabelTo,
  .wheelZoomSwitch {
    color: var(--font-color);
    padding-right: 5px;
  }

  .inputLabelTo {
    padding-right: 4px;
  }
}

.zoomWithWarning {
  height: 270px;
}

.offset {
  right: $offset-right + $info-panel-width;
  transition: right $transition;
}

.offsetBtn {
  right: $map-btns-right + $info-panel-width;
  transition: right $transition;
}

.wheelZoom {
  padding: 10px 0;
}

.hint {
  margin: $margin 0 $margin * 1.5;
  font-size: small;
}
