@import '../../../assets/styles/variables.scss';

$device-opacity: 0.7;

.container {
  @extend %absolute-centered;
}

.unActive {
  opacity: $device-opacity;
}
