@import '/src/assets/styles/variables.scss';

.spaceHorizontalDuration {
  @include flex(space-between, row);

  width: 100%;

  h4 {
    margin-right: 3rem;
  }
}

.timePickerCard {
  @include flex(space-between, column, none);

  width: 100%;

  .spaceVertical {
    @include flex-gap(20px, flex-start, column, none);
  }
}
