@import '../../../assets/styles/variables.scss';

.wrapper {
  height: $detailed-height;

  > div {
    height: 100%;
  }
}

.title {
  color: var(--font-color);
  margin-bottom: 1rem;
}
