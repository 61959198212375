@import '../../../../assets/styles/variables.scss';

.blockBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .btn {
    color: var(--white);
    background: var(--primary);
    margin: 0 1rem;
    border: 1px solid var(--primary);
    border-radius: $b-radius;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      color: var(--primary);
      background: var(--white);
    }

    &:active {
      color: var(--white);
      background: var(--primary);
    }

    @media (max-width: ($media-xl + 30px)) {
      margin: 0 0.5rem;
    }
  }
}
