@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem);

  & > p {
    margin: 0;
  }

  & > h4 {
    font-weight: bold;
  }

  .disabledText {
    color: var(--danger);
  }

  .status {
    @include flex-gap(1rem, flex-start, row, flex-start);
  }
}
