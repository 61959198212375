@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start, column);

  position: relative;
  width: 100%;

  .noData {
    @include flex;

    min-height: 250px;
  }

  .tabsCustom div[role='tab'] {
    text-transform: uppercase;
  }

  .tabsCustom {
    justify-content: center;
    align-items: center;
    width: inherit;

    > div {
      width: inherit;

      > div {
        justify-content: center;
        align-items: center;
      }
    }
  }
}
