@import '../../assets/styles//variables.scss';

.top,
.container {
  @include flex(space-between);
}

.container {
  position: absolute;
  z-index: $sidebar-index;
  left: -$sidebar-width;
  height: 100vh;
  width: $sidebar-width;
  color: var(--font-color);
  background: var(--bg);
  padding: 1rem 0.3rem;
  border-right: 1px solid var(--white600);
  box-shadow: 1px 1px 10px #0000001a;
  transition:
    color $transition-time,
    background $transition-time,
    left $transition-time * 2 ease-in-out;
}

.sidebar {
  left: 0;
}

.logo {
  height: 85px;
  width: 44px;
}

.bottom {
  @include flex;
}
