@import './../../../../../assets/styles/variables.scss';

.device {
  position: relative;
  width: 95%;
  background: var(--bg-tint-contrast);
  padding: 6px 3px 3px;
  margin-bottom: 5px;
  gap: 1rem;
  border: 1px solid var(--bg-tint);
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @include flex(none, row, center);

  &:hover {
    background: var(--bg-tint);
  }

  .ico {
    margin-left: 1%;
  }

  p {
    margin-bottom: 4px;
  }

  .error {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translate(0, -50%);
  }
}

.disabled {
  background: var(--disabled);
  cursor: not-allowed;

  &:hover {
    background: var(--disabled);
  }
}
