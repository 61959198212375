.chunk {
  display: block;
  word-wrap: break-word;

  .keyStyle {
    color: var(--json-key);
  }
}

.green {
  color: var(--green-light);
}

.red {
  color: var(--error);
}

.orange {
  color: var(--orange);
}

.grey {
  color: var(--grey100);
}
