.dsc {
  display: flex;
  margin: 0;
  font-size: 12px;

  span {
    font-weight: 400;
  }

  .label {
    display: block;
    width: 40%;
    color: var(--font-color);
    margin-right: 10px;
    font-weight: 700;
  }

  .children {
    display: block;
    width: 45%;
  }
}
