$btn-size: 3.5rem;

@mixin btn-icon($sizeRate, $icoOffset, $right: 0) {
  position: absolute !important;
  top: -$btn-size;
  right: $right;
  color: var(--font-color);
  font-size: $btn-size * $sizeRate !important;

  >span > svg {
    margin-bottom: $icoOffset;
  }
}
