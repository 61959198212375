@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, column, flex-start);

  max-height: 400px;
  overflow-y: auto;
  opacity: 0%;
  transition: opacity $transition;

  .desc {
    span[class*='content'] {
      font-size: 10px;
    }

    span[class*='label'] {
      font-size: 12px;
    }
  }

  &.animate {
    opacity: 100%;
  }
}
