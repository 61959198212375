@import '../../../assets/styles/variables.scss';

.block {
  overflow: hidden;
  box-shadow: $box-shadow;
  border-radius: 8px;
}

.blockHorizontal {
  @include flex(space-between, row);
}
