$left-menu-width: 80px;
$right-panel-width: 500px;

.sceneContainer {
  position: fixed;
  z-index: -1;
  top: 0;
  left: $left-menu-width;
  height: calc(100vh);
  width: calc(100vw - $left-menu-width);
  background-color: rgb(0 0 0 / 100%);
}

.sceneContainerRightPanel {
  position: fixed;
  z-index: -1;
  top: 0;
  left: $left-menu-width;
  height: calc(100vh);
  width: calc(100vw - $left-menu-width - $right-panel-width);
  background-color: rgb(0 0 0 / 100%);
}
