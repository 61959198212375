@import '../../../assets/styles/variables.scss';

/* stylelint-disable */
.toolbar-wrapper {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  padding: 0px $padding-card;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 5px;
  cursor: pointer;

  svg {
    @include size(25px);
  }
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}
/* stylelint-enable */
