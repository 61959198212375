@import '../../../assets/styles/variables.scss';

.container {
  @include flex;
  @include size(100%);

  text {
    fill: var(--font-color) !important;
  }

  .cell {
    position: absolute;
    right: 0 !important;
    cursor: help;
  }

  .cellDefaultCursor {
    cursor: default;
    stroke: none;
  }

  .chart {
    position: relative;

    div[class*='legend'] {
      position: absolute;
      top: 0 !important;
      left: 0 !important;
    }
  }
}
