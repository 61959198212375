@import '../../../../assets/styles/variables.scss';

.textLabel {
  text {
    font-size: 1.2rem;
    fill: var(--font-color);

    @media (max-width: $media-xxl) {
      font-size: 1rem;
    }

    @media (max-width: $media-xl) {
      font-size: 0.8rem;
    }
  }
}
