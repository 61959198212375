@import '../../../../assets/styles/variables.scss';
@import '../StatusCard/StatusCard.module.scss';

.manageCard {
  @include size(100%);

  position: relative;
  box-shadow: $box-shadow;
  border-radius: $b-radius;

  & > div {
    height: 100%;
  }

  .tabsCustom {
    height: 100%;

    div[class*='content-top'],
    div[role='tabpanel'] {
      height: 100%;
    }
  }
}

.tooltipDotter {
  z-index: $ten-index + 1;
  height: 100%;
}
