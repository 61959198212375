@import '../../../../assets/styles/variables.scss';

.wrapper {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 200px;
  gap: 1rem;

  @media (max-width: $media-xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: $media-l) {
    grid-template-columns: repeat(3, 1fr);
  }
}
