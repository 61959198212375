/* stylelint-disable declaration-block-no-redundant-longhand-properties */

@import '../../../assets/styles/variables.scss';

.rowContainer {
  display: grid;
  height: 85vh;
  padding: $padding;
  grid-template-columns: 15% 50% 1fr;
  gap: 2rem;

  .column {
    @include flex-gap(2rem, flex-start, column, flex-start);
  }

  .logsCard {
    height: 100%;
    min-width: 200px;
    box-shadow: $box-shadow;
    border-radius: $b-radius;
    overflow-y: hidden;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--grey300-o09);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--white600-o80);
  }

  @media (max-width: $media-xl) {
    grid-template-columns: 15% 50% 30%;
  }

  @media (max-width: $media-l) {
    grid-template-columns: 20% 40% 1fr;
  }
}
