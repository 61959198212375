@import '../../../../../assets/styles/variables.scss';
@import '../../../TlMainInfo/TlMainInfo.module.scss';

@mixin contrast-theme {
  p,
  h3 {
    color: var(--font-color);
  }

  background: var(--bg-tint-contrast);
}

.signalPRg {
  box-shadow: $box-shadow;
  border-radius: $b-radius;
  flex-grow: 1;

  .sgnProgramBlock {
    @include flex(flex-start, column, flex-start);
  }
}

.signalGraphWrap {
  @include flex(flex-start, column, center);

  width: 100%;

  .signalPrgRow {
    @include flex(flex-start, row, center);

    position: relative;
    width: 100%;
  }
}

.wrapper {
  width: 100%;
}
