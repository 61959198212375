@import '../../../assets/styles/variables.scss';

.svg {
  position: absolute;
  top: 0;
  right: 0;
  transition: $transition-time;
  cursor: pointer;
}

.light:hover {
  filter: brightness(90%);
}

.dark:hover {
  filter: brightness(120%);
}
