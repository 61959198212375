@import '../../../assets/styles/variables.scss';
@import '../styles/variables.scss';

.dtIco {
  @extend %absolute-centered;
}

.icoCursor {
  @extend %absolute-centered;
  @extend %ico-cursor;

  &.inactive {
    transition: $transition;

    &:hover {
      fill: var(--primary);
    }
  }

  &.activeId {
    fill: var(--primary);
  }
}
