@import '../../../../assets/styles/variables.scss';

.blockTitle {
  text-transform: uppercase;

  >div {
    color: var(--font-color) !important;

    &::after,
    &::before {
      border-top: 1px solid var(--font-color) !important;
    }
  }
}
