@import '/src/assets/styles/variables.scss';

$min-width: 180px;
$max-width: 280px;
$margin-size: 6px;
$icon-size: 20px;
$tooltip-height: 40px;
$tooltip-font-weight: 700;
$tooltip-font-size: 12px;
$row-line-height: 16.34px;

.weatherTooltipContainer {
  max-width: $max-width;
  min-width: $min-width;

  @include flex(center, row);
}

.gisLayersTooltipContainer {
  max-width: $max-width;
  min-width: $min-width;
}

.tooltipTitle {
  display: flex;
  align-items: center;
  height: $tooltip-height;
  color: inherit;
  font-family: 'Noto Sans', serif;
  font-size: $tooltip-font-size;
  font-weight: $tooltip-font-weight;
  line-height: $row-line-height;
}

.avatar {
  margin-right: $margin-size;
  font-size: $icon-size;
}
