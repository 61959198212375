@import '../styles/variables.scss';
@import './../../../assets/styles/index.scss';

$tile-size: 96px;
$triangle-size: 10px;
$hover-color: rgb(200 200 200 / 70%);
$selected-color: rgb(21 115 255 / 100%);
$cell-size: 6px;
$title-font-size: 14px;

.baseMapsPanel {
  position: absolute !important;
  z-index: 100 !important;
  top: $child-containers-top-offset-thin-screen;
  right: 0;
  margin-top: $child-containers-top-offset !important;
  border-radius: $b-radius;
  border-width: 0 !important;
}

.baseMapTitleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.baseMapsPanelTitle {
  padding-left: $cell-size;
  font-size: $title-font-size;
}

.baseMapsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $cell-size;
}

.baseMapItemContainer {
  display: block;
}

.baseMapItemCard {
  max-height: $tile-size;
  max-width: $tile-size;
  margin-right: $cell-size !important;
  margin-left: $cell-size !important;
}

.baseMapItemImg {
  height: $tile-size !important;
  max-height: $tile-size;
  width: $tile-size !important;
  max-width: $tile-size;
  border-radius: $b-radius !important;

  &:hover {
    box-shadow: $hover-color 0 0 0 3px;
  }
}

.baseMapItemImgSelected {
  height: $tile-size !important;
  max-height: $tile-size;
  width: $tile-size !important;
  max-width: $tile-size;
  border-radius: $b-radius !important;
  box-shadow: $selected-color 0 0 0 3px;
}

.baseMapSettingsContainer {
  min-width: $tile-size * 4;
  padding-bottom: $cell-size;
  margin-left: $cell-size;
}

.baseMapSettingsRow {
  display: flex;
  margin-top: $cell-size;
  margin-bottom: $cell-size;
}

.basemapSettingsRowText {
  margin-left: $cell-size;
}

.basemapSettingsPropertyTitle {
  color: var(--font-color-lite);
  margin: 0;
  font-size: 2 * $cell-size;
}

.basemapSettingsSlider {
  height: 2 * $cell-size !important;
  margin: 0 0 1.5 * $cell-size !important;
  line-height: 2 * $cell-size !important;
}

.baseMapSettingsTitle {
  display: flex;
  margin-top: 2 * $cell-size !important;
}

.baseMapSettingsButton {
  color: var(--font-color-lite);
  margin-top: $cell-size;
  margin-left: calc($cell-size / 2);
  font-size: $title-font-size;
  cursor: pointer;
}

.baseMapSettingsButtonActive {
  color: var(--blue300);
  margin-top: $cell-size;
  margin-left: calc($cell-size / 2);
  font-size: $title-font-size;
  cursor: pointer;
}

.baseMapSettingsPredefinedContainer {
  display: inline-flex !important;
  margin-bottom: $cell-size;
}

.baseMapSettingsPredefinedButton {
  margin-right: $cell-size;
  font-size: 2 * $cell-size;
  border-bottom: 1px dotted;

  &:hover {
    color: var(--blue300);
    border-bottom: 1px dotted var(--blue300);
    cursor: pointer !important;
  }
}

.baseMapFilterContainer {
  margin-top: 2 * $cell-size;
}

.switchLabel {
  margin-left: $cell-size;
}

.switchContainer {
  display: flex;
  align-items: center;
}
