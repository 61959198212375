@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%, 30vh);

  overflow-y: auto;

  p {
    width: 100%;
    font-size: 12px;
  }
}
