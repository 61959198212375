@import '../../../assets/styles/variables.scss';

.container {
  margin-bottom: 10px;
  border: 1px solid var(--bg-tint);
  border-radius: $b-radius;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  height: $input-height;
  border-radius: $b-radius $b-radius 0 0;
  text-transform: uppercase;

  .collapseBtn {
    height: 100%;
    background: var(--bg);
    padding-top: 5px;
    font-size: 1.5rem;
    border: none;
    border-right: 1px solid var(--bg-tint);
    cursor: inherit;

    svg {
      transform: rotate(0deg);
      transition: $transition-time;
    }
  }

  .isAccordionOpen {
    cursor: pointer;

    svg {
      transform: rotate(90deg);
      transition: $transition-time;
    }
  }

  .headerContent {
    @include flex(space-between, row);

    width: 100%;
    padding: 0 10px;
  }

  .info {
    @include flex(center, row);
  }

  .title {
    margin-bottom: 0;
    font-size: 1.1rem;
  }

  .amount {
    color: var(--grey100);
    padding-left: 10px;
    font-size: 1.2rem;
  }
}

.items {
  position: relative;
  background: var(--bg-tint);
  white-space: nowrap;

  .list {
    @include flex-gap(4px, flex-start, column, flex-start);

    padding: 10px 4px 0;
  }
}

.isOpen,
.isHidden {
  transition: max-height $transition;
  overflow-x: hidden;
}

.isHidden {
  max-height: 0;
}
