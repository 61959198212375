@import '../../../../assets/styles/variables.scss';

.wrapper {
  @include flex(flex-start, column);

  position: relative;
  height: 80vh;
  min-height: 100%;
  width: 60%;
  padding: 2rem;
  transition: $transition;
  box-shadow: $box-shadow;
  border-radius: $b-radius;

  .infoText {
    margin: 0 auto;
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    opacity: 40%;
  }

  .dwldBtn {
    @include flex(center);

    margin-top: $margin;
  }

  .fileInfo {
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    padding: $padding;
    margin-bottom: $margin;
    border: 1px solid var(--bg-tint-contrast);
    border-radius: $b-radius;
    gap: 1rem;

    @include flex(center, column, flex-start);

    .infoItem {
      @include flex(flex-start, row, flex-start);

      width: 100%;
      max-width: 400px;
      gap: 1rem;

      span {
        display: block;
        font-weight: bold;
      }
    }

    .infodwldBtn {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .text {
    height: 100%;
    width: 100%;
    padding: $padding;
    border: 1px solid var(--bg-tint-contrast);
    border-radius: $b-radius;
    overflow: scroll;
  }
}
