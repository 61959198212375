@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(2rem, flex-start, row);

  position: absolute;
  z-index: 1;
  top: -2rem;
  width: 75%;
}
