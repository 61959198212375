@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%);
  @include flex-gap(1rem, flex-start, column, flex-start);

  .title {
    @include flex-gap(1rem, flex-start, row);

    width: 100%;

    .id {
      color: var(--grey100);
      font-size: 10px;
    }

    p {
      margin: 0;
    }
  }

  .block {
    @include size(100%, 75%);

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
