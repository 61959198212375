@import '/src/assets/styles/variables.scss';

.spaceHorizontalDuration {
  @include flex(space-between, row);

  width: 100%;

  h4 {
    margin-right: 3rem;
  }
}

.timePickerCard {
  @include flex(space-between, column, none);

  width: 100%;

  .timeCardStyle {
    margin-top: 20px;
    opacity: 0%;
    transition: opacity $transition;

    &.timeCardAnimate {
      opacity: 100%;
    }
  }

  .rowContainer {
    @include flex-gap(20px, flex-start, column, none);

    .labelCheckboxDisabled {
      color: var(--disabled-grey) !important;
    }
  }
}
