@import '../../../../../../../assets/styles/variables.scss';

.statusCard {
  @include flex-gap(0.5rem, space-between, row);

  width: 100%;
  padding: 2px;
  border-radius: $b-radius;
  transition: $transition;
  cursor: pointer;

  .tag {
    @include flex;
    @include fixed-size(40px);
  }

  p {
    width: 100%;
    margin: 0;
    text-align: left;
  }

  &:hover {
    background-color: var(--antd-blue);
  }
}

.active {
  color: var(--white);
  background-color: var(--antd-blue);

  &:hover {
    opacity: 80%;
  }
}
