@import '../../../../../../assets/styles/variables.scss';

.typeDesignation {
  width: fit-content;
  min-width: 250px;
}

.content {
  @include flex(space-between, column);

  flex-wrap: wrap;
  color: var(--font-color);
  background: var(--bg);
  padding: 15px 20px;
  border-radius: $b-radius-bottom;
  opacity: 90%;

  .titleIco {
    @include flex-gap(1rem, space-between, row);

    width: 100%;
    margin-bottom: 5px;

    p {
      width: 90%;
      padding-bottom: 5px;
      margin: 0;
      font-size: 14px;
    }
  }
}

.title {
  width: 100%;
  background-color: var(--disabled);
  padding: 0.5rem;
  text-align: center;
  border-radius: $b-radius-top;
  opacity: 90%;

  h3 {
    margin: 0;
  }
}
