@import '../../../../assets/styles/variables.scss';

.groupsBox {
  display: block;
  width: 100%;
  background: var(--bg-tint);
  padding: 5px;
  border-radius: 3px;

  .groups {
    @include flex(center, column);

    color: var(--font-color);
    margin-bottom: 5px;
    gap: 1rem;

    div {
      margin-top: 5px;
    }

    span {
      width: 100%;
      text-wrap: wrap !important;
    }
  }
}
