@import '/src/assets/styles/variables.scss';

.container {
  @include flex(flex-start, column, flex-start);
  @include size(100%);

  position: relative;
  background-color: var(--bg);
  padding: $padding-card;
  border-radius: $b-radius;

  .btn {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
  }
}
