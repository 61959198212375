@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, center);
  @include size(85%, 100%);

  min-width: 500px;
  padding: 2px 0;

  .select {
    width: 100%;

    & > div {
      color: var(--font-color);
      background: var(--bg-tint);
      border: 1px solid var(--disabled) !important;
    }

    & > span {
      color: var(--font-color);
    }
  }
}
