@import '../../../../assets/styles/variables.scss';

.container {
  @include flex;

  position: relative;
  min-height: 60px;
  padding: $padding;
  border: 1px solid var(--bg-tint);
  border-radius: $b-radius;

  .timer {
    span {
      font-size: 10px;
      text-align: left;
    }
  }

  .title {
    margin: 0;
  }
}
