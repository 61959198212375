@import '/src/assets/styles/variables.scss';

.groupTitleIcon {
  @include flex(space-between, column);

  position: absolute;
  right: 0;
  font-size: 18px;
  scale: 1;
  cursor: pointer;
  transition: $transition;

  &.active {
    color: var(--font-primary);
  }

  &.passive {
    color: var(--font-color-lite);
  }

  &.special {
    color: var(--warning);
  }

  &.disabled {
    color: var(--disabled);
    cursor: not-allowed;

    &:hover {
      scale: 1;
    }
  }

  &:hover {
    scale: 1.2;
  }
}
