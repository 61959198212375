@import '../../../assets/styles/variables.scss';

.container {
  @include flex;
  @include size(100%);

  position: relative;
}

.brush {
  rect {
    fill: var(--bg-tint);
    stroke: var(--font-color);

    &:nth-child(2) {
      fill: var(--bg);
      fill-opacity: 100%;
    }
  }

  g > line {
    stroke: var(--bg-tint);
  }

  text {
    fill: none;
  }
}

.empty {
  height: 50% !important;
  width: 50% !important;
  font-size: 20px !important;

  &:first-child {
    @include flex;

    height: 100% !important;
    width: 100% !important;

    div[class*='image'] {
      @include size(30%);
    }
  }

  svg {
    @include size(100%);
  }
}

.graphContainer {
  @include size(100%);
}
