@import '../../../../../assets/styles/variables.scss';

.infoBox {
  grid-area: Notify;
}

.listItem {
  background: var(--bg-tint);
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}

.point {
  @include flex(flex-start, row, center);

  h4,
  p {
    margin: 0;
  }

  h4 {
    margin-right: 0.5rem;
    font-weight: 700;
  }
}
