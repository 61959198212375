@import '../../../assets/styles/variables.scss';

$collapse-button-width: 35px;
$collapse-button-height: 220px;

.container {
  position: absolute;
  z-index: $detailed-index;
  top: 0;
  right: 0;
  height: 100vh;
  min-height: 100%;
  width: 100%;
  color: var(--font-color);
  background: var(--bg-tint);
  padding-left: $sidebar-width;
  transition: padding $transition;

  .contentWrapper {
    @include flex(flex-start, column, flex-start);
    @include size(100%);

    padding: 1rem;

    .content {
      @include size(100%, 85vh);

      position: relative;

      &.contentRightPanel {
        @include size(100%);

        padding: 1rem;
        overflow-y: auto;
      }
    }
  }
}

.detailed {
  overflow-y: auto !important;
}

.isPanel {
  padding-left: $sidebar-width + $panel-width;
  transition: padding $transition;
}

.isRightPanelDefault {
  position: absolute;
  z-index: $right-panel-index;
  top: 0;
  right: -$info-panel-width;
  height: 100vh;
  width: $info-panel-width;
  background: var(--bg);
  padding-left: 0;
  box-shadow: -2px 1px 20px #0003;
  transition: right $transition;
}

.isRightPanel {
  right: 0;
  transition: right $transition;
}

.collapseButton {
  position: absolute;
  z-index: -2;
  bottom: 50px;
  left: -$collapse-button-width;
  display: block;
  height: $collapse-button-height;
  width: $collapse-button-width;
  color: var(--font-color-dark);
  background-color: var(--primary);
  padding-right: 2px;
  font-size: 16px;
  border-radius: 15px 0 0 15px;
  writing-mode: vertical-rl;
  text-align: center;
  letter-spacing: 2px;
  border: none;
  opacity: 100%;
  transition: opacity $transition;
  cursor: pointer;

  & > div {
    padding: 0 !important;
  }

  &:hover {
    opacity: 80%;
  }
}

.collapseButtonHidden {
  opacity: 0%;
  cursor: grab;

  &:hover {
    opacity: 0%;
  }
}
