@import '../../../assets/styles/variables.scss';

.wrapper {
  height: 100%;

  .noData {
    margin-top: 5rem;
    text-align: center;
  }
}
