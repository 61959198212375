@import '../../../assets/styles/variables.scss';

$box-shadow: inset 0 0 0 50px var(--bg) !important;

.container {
  @include flex;
  @include size(100%);
}

.wrapper {
  width: 50%;
}

.btn {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 8px;
  text-transform: uppercase;

  &.disabled {
    color: var(--font-color) !important;
  }
}

.radioRow {
  display: flex;
  justify-content: space-between;

  label {
    color: var(--font-color);
  }

  a {
    text-decoration: underline;
  }
}

.register {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
}

.inputContainer {
  & div > label {
    color: var(--grey100);
    font-size: 1.2rem;
  }

  .icon {
    position: relative;
    left: -7px;
    margin-right: -7px;
    stroke: var(--grey100);
  }

  .input {
    display: flex;
    width: 100%;
    color: var(--font-color);
    background-color: var(--bg) !important;
    border: 1px solid var(--primary);

    &:hover,
    &:focus,
    &:focus-within {
      background-color: var(--bg);
    }

    span[class*='ant-input-suffix'] svg {
      fill: var(--grey100) !important;
    }

    input {
      &:-webkit-autofill {
        color: var(--font-color);
        box-shadow: $box-shadow;
        -webkit-text-fill-color: var(--font-color) !important;
        transition: none;

        &:focus {
          box-shadow: $box-shadow;
        }
      }
    }
  }
}
