@import '../../../assets/styles/variables.scss';

.olCustomOverviewmapDef {
  left: $panel-width + $sidebar-width + 34px !important;
}

.olCustomOverviewmap {
  position: absolute;
  bottom: 30px;
  left: $sidebar-width + 5px;
  height: 200px;
  width: $minific-map-width;
  transition: left $transition;
  animation: showBtn;
  animation-duration: 0.5s;

  & > div {
    height: 100%;
    width: 100%;
    border: 1px solid var(--bg-tint-dark);
    border-radius: $b-radius;

    & > div {
      div:last-child {
        & > div {
          border: 1px solid var(--bg-tint-dark);
          border-radius: $b-radius;
        }
      }
    }
  }

  button {
    display: none;
  }
}

@keyframes showBtn {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}
