@import '../styles/variables.scss';
@import './../../../assets/styles/index.scss';

$cell-size: 6px;
$tip-font-size: 12px;
$start-color: #fb4545;
$end-color: #69d1ff;

.buttonsBlock {
  display: flex;
}

.dirBtn {
  color: var(--font-color);
  background-color: var(--bg-tint);
  padding: $cell-size 2 * $cell-size;
  margin-right: 1px;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
}

.dirBtnActive {
  color: var(--white);
  background-color: var(--blue300);
  padding: $cell-size 2 * $cell-size;
  margin-right: 1px;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
}

.dirBtnLeft {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.dirBtnRight {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.trafficGraphPanel {
  position: absolute !important;
  top: $child-containers-top-offset-thin-screen;
  right: 50px;
  width: 400px;
  margin-top: $child-containers-top-offset !important;
  border-radius: $b-radius;
  border-width: 0 !important;
}

.title {
  margin-top: 2 * $cell-size;
  margin-bottom: $cell-size;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.timeSelectorsContainer {
  @extend %space-between;
}

.timeSelectorItem {
  width: 220px;
}

.hourSelectorItem {
  width: 280px;
  margin-left: 2 * $cell-size;
}

.hoursSelectorItem {
  width: 140px;
}

.toolsContainer {
  @include flex(space-between, row);
}

.infoContainer {
  position: fixed;

  @include size(400px, 420px);

  top: 490px;
  right: 100px;
  background-color: var(--bg);
  padding: 2 * $cell-size;
  border-radius: $b-radius;
}

.mergeTypeField {
  width: 100%;
}

.hourField {
  width: 50%;
}

.rowItem {
  display: flex;
  align-items: center;
}

.title {
  font-weight: bold;
}

.rowItemNum {
  width: 32px;
  max-width: 32px;
}

.rowItemId {
  width: 400px;
  max-width: 400px;
}

.rowItemCount {
  width: 80px;
}

.tipContainer {
  display: flex;
  align-items: center;
  margin-top: 2 * $cell-size;
  margin-bottom: $cell-size;
}

.tipIcon {
  margin-right: $cell-size;
}

.tipText {
  font-size: $tip-font-size;
}

.legendItemContainer {
  display: flex;
  align-items: center;
  line-height: 18px;
}

.legendIconStart {
  color: #fb4545;
  margin-right: 2 * $cell-size;
}

.legendIconEnd {
  color: #69d1ff;
  margin-right: 2 * $cell-size;
}
