@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, column, flex-start);
  @include size(100%);

  position: relative;

  .btn {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
  }
}
