/* stylelint-disable declaration-block-no-redundant-longhand-properties */

@import '../../../assets/styles/variables.scss';

.textArea,
.buttonsBoxCard,
.signalPrgCard,
.signalPRg {
  box-shadow: $box-shadow;
  border-radius: $b-radius;
}

.container {
  @include flex(space-between, row);

  padding: 20px;

  h3 {
    margin-bottom: 20px;
  }

  .signalPrgCard {
    @include flex(flex-start, column, none);

    max-height: 78.5vh;
    min-height: 78.5vh;
    width: $media-m;
    min-width: $media-xs;
    text-align: center;
    overflow-y: auto;

    .signalPRg {
      @include flex(center, column);

      margin-bottom: 4rem;
      text-align: center;

      .signalGraphWrap {
        max-width: $media-s;
        margin-left: 10px;
        overflow-x: auto;
      }

      @media (max-width: $media-xl) {
        max-width: $media-m - 100;
        min-width: $media-m - 100;
      }

      @media (max-width: ($media-xl - 100)) {
        max-width: $media-s - 100;
        min-width: $media-s - 100;
      }
    }

    @media (max-width: ($media-xl - 100)) {
      max-width: $media-s;

      .signalGraphWrap {
        max-width: $media-m;
        min-width: ($media-s - 150);
      }
    }
  }

  .containerColumn {
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    width: $media-s;
    min-width: 400px;
    margin-right: 4rem;

    .buttonsBoxCard {
      @include flex(center, column);

      width: 100%;
      margin-bottom: 4rem;
      text-align: center;

      .uploadBtn {
        display: none;
        height: 0;
        width: 0;
      }

      .fileName {
        margin-top: 2rem;
      }
    }

    .textArea {
      min-height: 55vh;
      color: var(--font-color);
      padding: 2rem;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: var(--grey300-o09);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--white600-o80);
  }
}

@media (max-width: $media-xl) {
  .container {
    @include flex(none, column);
  }

  .textArea {
    margin-bottom: 4rem;
  }

  .containerColumn {
    margin-right: 0 !important;
  }
}
