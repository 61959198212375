@import '/src/components/MapBtns/styles/variables.scss';

.zoom {
  position: absolute;
  z-index: 1;
  top: 40%;
  right: 1rem;
  font-size: 3rem;
  border-radius: $b-radius;

  span {
    @include flex;

    color: var(--sidebar-btn);
    overflow: hidden;
    transition: none;
  }

  .buttonBox {
    width: fit-content;
    box-shadow: none;

    > button {
      padding: 0;
      border-bottom: $border-side;
    }

    > :last-child {
      border: none;
    }

    & button {
      color: var(--sidebar-btn);
      font-size: 16px;
    }

    :active {
      color: var(--white);
      background: var(--primary);
      transition: none;

      span {
        color: var(--white);
        transition: none;
      }
    }
  }

  .reset {
    width: fit-content;
    margin-top: 7px;
    border-radius: 3px;
    overflow: hidden;

    & button {
      font-size: 16px;
    }
  }
}
