@import '/src/assets/styles/variables.scss';

$margin-size: 6px;
$hover-box-shadow: var(--antd-blue) 0 0 0 3px;

.optionsContainer {
  display: flex;
}

.optionButtonActive {
  color: var(--white) !important;
  background-color: var(--antd-blue) !important;
  margin-right: $margin-size;

  &:hover {
    box-shadow: $hover-box-shadow;
  }
}

.optionButton {
  background-color: var(--bg-tint) !important;
  margin-right: $margin-size;

  &:hover {
    box-shadow: $hover-box-shadow;
  }
}
