@import '../../../../../../assets/styles/variables.scss';

@mixin btn-media($max-width, $width, $font-size) {
  @media (max-width: $max-width) {
    width: $width;
    font-size: $font-size;

    svg {
      @include size(calc($width - 5px));
    }
  }
}

.container {
  @include flex(flex-start, column, flex-start);

  h4 {
    text-wrap: nowrap;
  }

  .btnsBlock {
    background-color: var(--bg-tint);
    border: 5px solid var(--bg-tint);
    border-radius: $b-radius !important;

    .nestedBlock {
      @include flex(flex-start, row);
    }

    .nested {
      margin: 0 1px;
    }
  }

  button {
    font-size: 1em;

    p {
      margin: 0;
    }

    svg {
      color: var(--font-color);
    }

    @include btn-media($media-xxl, 30px, 0.8em);
    @include btn-media($media-xl, 25px, 0.6em);

    &:hover {
      color: var(--sidebar-btn);

      p {
        color: var(--sidebar-btn);
      }
    }
  }
}
