@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start);

  max-width: 220px;

  & > h4 {
    margin: 0;
  }

  .error {
    color: var(--error);
    margin: 0;
    font-size: 12px;
  }
}
