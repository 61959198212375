.notData {
  color: var(--font-color);
  background: var(--bg);
  padding: 10px;
  text-align: center;

  >p {
    margin-bottom: 0;
  }
}
