@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/zIndex.scss';

.projectionWrapper,
.projection {
  @extend %absolute-centered;
}

.wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

.projectionWrapper {
  transform: translate(-50%, -50%) rotate(0deg);
}

.projection {
  position: relative;
  height: 0;
  width: 0;
}

.arc {
  position: absolute;
  border-radius: 50% 50% 0% 0% / 100% 100% 0% 0%;
}
