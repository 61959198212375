@import '../../assets/styles/variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  background: var(--bg-tint);
  aspect-ratio: 16 / 9;
  overflow: hidden;

  &.border {
    border-radius: $b-radius;
  }

  .empty {
    @include size(50%);

    font-size: 10px;

    &:first-child {
      @include flex;
      @include size(100%);

      div[class*='image'] {
        @include size(30%);
      }
    }

    svg {
      @include size(100%);
    }
  }
}
