@import '../../../../../assets/styles/variables.scss';

@mixin color-logs($color: var(--bg)) {
  color: var(--font-color);
  background: $color;
}

.messageBlock {
  @include flex(flex-start, column, none);

  position: relative;
  z-index: $default-index;

  .message {
    display: block;
    padding: 1px 10px;
    margin: 0;
    font-size: 13px;
    border-radius: 0 3px 3px;
  }

  .time {
    position: relative;
    z-index: $negative-index;
    display: block;
    width: fit-content;
    padding: 1px 10px;
    margin: 0;
    font-size: 10px;
    filter: brightness(0.8) !important;
    border-radius: 3px 3px 0 0;
  }

  &:hover {
    filter: brightness(0.8) !important;
    cursor: pointer;
  }
}

.default {
  @include color-logs;
}

.primary {
  @include color-logs(var(--primary-log));
}

.success {
  @include color-logs(var(--success-log));
}

.warning {
  @include color-logs(var(--warning-log));
}

.error {
  @include color-logs(var(--error));
}
