@import '../../../assets/styles/variables.scss';

.wrapper {
  @include flex(center, column, flex-start);

  padding-left: 10px;

  h2,
  h3 {
    color: var(--font-color);
  }

  h3 {
    text-align: center;
    text-transform: uppercase;
  }

  .headInfo {
    @include flex(space-between, row, flex-start);

    width: 100%;
  }

  .infoBoxes {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 2rem;
    /* stylelint-disable */
    grid-template-areas:
      'RefreshUpdateInfo PartialUpdateInfo Database IntegrationApi '
      'RefreshUpdateInfo PartialUpdateInfo Notify TableValidation'
      'RefreshUpdateInfo PartialUpdateInfo Notify .';
    /* stylelint-enable */
    ul {
      list-style: none;
    }
  }
}
