@import '../../../../../../../assets/styles/variables.scss';

.tile {
  @include flex;
  @include size(50px, 32px);

  padding: 0.5rem;
  border-radius: $b-radius;
  opacity: 100%;
  transition: transform $transition;
  cursor: pointer;

  p {
    color: var(--font-color-dark);
    margin: 0;
    text-align: center;
    vertical-align: middle;
  }

  &.active {
    border: 2px solid var(--primary);
    box-sizing: border-box;
    cursor: help;

    &:hover {
      transform: none;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}
