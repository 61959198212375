@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/zIndex.scss';

$grid-size: 3px;
$bottom-offset: 120px;
$icon-size: 16px;

.actionsContainer {
  margin-top: $grid-size;
  margin-left: 6 * $grid-size - $icon-size;
}

.actionItemText {
  margin-left: $grid-size;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: var(--hover-tab);
  }
}

.featureTitle {
  display: flex;
  align-items: center;
  color: var(--font-color);
  margin-top: 2 * $grid-size;
  margin-bottom: 4 * $grid-size;
  font-size: 14px;
  font-weight: bolder;
}

.featureTitleText {
  margin-left: 2 * $grid-size;
}

.featurePropertyContainer {
  display: block;
  align-items: center;
  margin: 2 * $grid-size;
  border: none !important;
  line-height: 6 * $grid-size;
}

.featurePropertyName {
  color: var(--font-color);
  font-size: 12px;
}

.featurePropertyValue {
  background: var(--bg-tint);
  padding: $grid-size 2 * $grid-size;
  font-size: 14px;
  font-weight: bolder;
  line-height: 10 * $grid-size;
}

.backButton {
  margin-bottom: 2 * $grid-size;
}
