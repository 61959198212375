@import '../../../assets/styles/variables.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  max-height: 79vh;
  padding: 25px;
  margin: 20px 0;
  overflow-y: auto !important;
  gap: 25px;
}

.borderBottom {
  border-bottom: 2px solid #d3d3d6;
}

.phaseTitle {
  @include size(100%, 60px);

  margin: 0;
  overflow: hidden;
}

.headinputs {
  svg {
    position: relative;
    top: 3px;
    width: 25px;
    filter: $svg-icons-filter;
  }

  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center !important;
  width: 400px;
  filter: $inputs-filter;
}

.resizeIco {
  height: 26px;
  width: 26px;
  margin-right: 10px;
}

.phaseScheme {
  @include size(100%);
  @include flex-gap(1rem, flex-start, column);

  min-height: 30vh;
  background-color: var(--bg);
  padding: $padding-card;
  flex-grow: 1;
  box-shadow: 1px 1px 20px #0003;
  border-radius: $b-radius;

  h3 {
    margin: 0;
  }

  .card {
    @include flex;
    @include size(100%);

    overflow: hidden;

    img {
      @include size(100%);

      height: 100% !important;
      object-fit: cover;
    }
  }

  .img {
    @include size(100%);
  }
}

.containerNotData {
  @include flex(center, row, center);

  width: 100%;

  .mainGraphCardNotData {
    @include flex(center, column);

    width: 40%;
    margin-bottom: 4rem;
    box-shadow: $box-shadow;
    border-radius: $b-radius;
    text-align: center;
  }
}

@media (orientation: portrait) {
  .phaseScheme {
    width: 100%;
  }
}
