@import '../../../../assets/styles/variables.scss';

@mixin contrast-theme {
  h1,
  h2,
  h3,
  p,
  button,
  label {
    color: var(--font-color);
  }

  background: var(--bg-tint-contrast);
}

.container {
  display: flex;
  justify-content: flex-end;
  width: 98%;
  gap: 20px;

  input {
    display: none;
  }

  label {
    @include contrast-theme;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    font-weight: bold;
    transform: scale(0.9);
    transition: all 0.2s;
    border-radius: $b-radius;
    box-shadow: $box-shadow-lite;
    -webkit-touch-callout: none;
    user-select: none;
    cursor: pointer;

    .gridIco,
    .listIco {
      fill: var(--font-color);
      filter:
        invert(60%) sepia(24%) saturate(383%) hue-rotate(197deg)
        brightness(95%) contrast(95%);
    }

    .gridIco {
      height: 33px;
      width: 33px;
    }

    .listIco {
      height: 25px;
      width: 25px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  input[type='radio']:checked + label {
    transform: scale(1.2);
  }
}
