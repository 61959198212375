@import '../../../assets/styles/variables.scss';

.panelDefault {
  position: absolute;
  z-index: $panel-index;
  left: -$panel-width - $sidebar-width;
  height: 100vh;
  transition: left $transition;
}

.isPanel {
  left: 0;
  transition: left $transition;
}

.container {
  position: absolute;
  top: 0;
  left: $sidebar-width;
  height: 100vh;
  width: $panel-width;
  color: var(--font-color);
  background: var(--bg);
  padding: 0 $padding;
  transition: all $transition-time;
  box-shadow: 1px 1px 20px #0003;
  overflow-x: hidden;
  overflow-y: auto;

  > div {
    margin: 25px 0;
  }

  > h1 {
    color: var(--font-color);
  }
}
