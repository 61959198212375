@import '../../../../assets/styles/variables.scss';

%manage-btn {
  position: absolute;
  top: 2rem;
  right: 4rem;
  border-radius: $b-radius;
}

.statusCard {
  position: relative;
  width: 100%;
  padding: $padding;
  box-shadow: $box-shadow;
  border-radius: $b-radius;

  .columnBlocks {
    @include flex-gap(2rem, flex-start, column, none);

    position: relative;
    min-height: 120px;
    width: 100%;

    & > h4 {
      margin: 0;
    }

    .block {
      @include flex-gap(1rem, flex-start, column, flex-end);

      position: absolute;
      top: 5rem;
      right: 0;
    }
  }

  .activateBtn {
    @extend %manage-btn;
  }
}
