@import '../../../../assets/styles/variables.scss';

.sectionTitle {
  color: var(--font-color);
  margin-bottom: 8px;
  text-align: center;
  line-height: 18px;
}

.loader {
  @include flex(center, row, center);

  height: 240px;
}

.error {
  margin: 0;

  @include flex(center);

  p {
    color: var(--font-color);
    margin-top: 10px;
    font-size: 20px;
    opacity: 20%;
  }

  svg {
    ellipse {
      fill: var(--bg-tint-contrast);
      stroke: var(--bg-tint);
    }

    path {
      fill: var(--bg-tint-contrast);
      stroke: var(--bg-tint);
    }
  }
}

.container {
  @include flex-gap(2rem);
}
