@import '../DevicesList/DevicesList.module.scss';

.container {
  @include flex(center, row);

  flex-wrap: wrap;
  max-height: $height-container;
  width: 100%;
  gap: 2rem;
  overflow: auto;
}
