@import '../../assets/styles/variables.scss';

.tabsCustom {
  color: var(--font-color);
}

.tabsCustom div[role='tab'] {
  text-transform: uppercase;
}

.tabsCustom div[role='tablist']:first-child::before {
  position: absolute;
  z-index: $zero-index;
  top: 44px;
  right: 0;
  left: 0;
  border-bottom: 2px solid #58596733;
  content: '';
}
