@import '../../../../assets/styles/variables.scss';

@mixin active-style() {
  color: var(--white) !important;
  background: var(--primary) !important;
}

.container {
  width: 100%;
  font-size: 1.1rem;
  list-style-type: none;
  overflow: hidden;

  .block {
    @include flex(flex-start, row);

    width: 100%;
    background: var(--bg-tint);
    padding-right: 0;
    border-radius: $b-radius;
    border: none;

    > span {
      color: var(--grey100);
      padding-right: 7px;
    }

    p {
      background: inherit;
      margin: 0;
    }

    &:hover {
      background: var(--bg);
      cursor: pointer;
    }
  }

  .idBlock {
    width: fit-content;
  }

  .activeRaw {
    @include active-style;

    &:hover {
      opacity: 85%;
    }
  }

  .disabled {
    background-color: var(--disabled) !important;
    cursor: not-allowed !important;

    &:hover {
      background-color: var(--disabled) !important;
      border-radius: 0 !important;
    }
  }

  .status {
    position: absolute;
    right: 1rem;

    .icon {
      @include size(20px);
    }
  }
}
