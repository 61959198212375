@import '../../../../assets/styles/variables.scss';

$map-info-height: 100px;
$map-info-bottom: 30px;

.mapInfoBtn {
  position: absolute;
  z-index: $map-btns-index + 1;
  right: $map-btns-right;
  bottom: $map-info-bottom;
  transition: $transition;

  &.isInfoPanel {
    transform: translate(-$info-panel-width, 0);
  }
}
