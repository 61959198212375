@import '../../../assets/styles/variables.scss';

.inputZoom {
  width: 70% !important;
  border-radius: $b-radius;
}

.inputNumber {
  width: 100% !important;
}

.settingsContent {
  width: 100%;
  padding: 1rem 2rem;
  overflow-x: hidden;
  overflow-y: auto;

  > div {
    margin-bottom: 10px !important;
  }
}

.applyBtn {
  @include btn-colored;

  margin: 0.2rem 2rem 2rem;

  > button:disabled {
    color: var(--grey100);
    border-radius: $b-radius;
    text-transform: uppercase;
  }
}

.applyBtnDisabled {
  border: none !important;
}

.row {
  @extend %space-between;

  color: var(--font-color);
  margin-bottom: 7px;
}

.circularSingle {
  @include flex(flex-start, column);
}
