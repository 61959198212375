@import '../../../../../../../assets/styles/variables.scss';

@mixin compass {
  font-size: 12px;
  font-weight: 100;
}

.container {
  @include size(150px);
  @include flex;

  background-color: var(--bg-tint);
  padding: $padding;
  border-radius: 50%;
  cursor: help;

  .wrapper {
    @include size(100%);

    background-color: var(--bg);
    padding: $padding;
    border-radius: 50%;

    .border {
      @include size(100%);

      position: relative;
      border-radius: 50%;
      border: 1px solid var(--bg-tint);

      .north {
        @include compass;
        @include position(absolute, -8%, 47%);
      }

      .south {
        @include compass;
        @include position(absolute, 92%, 42%);
      }

      .west {
        @include compass;
        @include position(absolute, 40%, auto, auto, -4%);
      }

      .east {
        @include compass;
        @include position(absolute, 40%, -4%);
      }

      .speed {
        @extend %absolute-centered;

        z-index: $default-index;
        font-size: 12px;
        font-weight: bolder;
      }

      .arrow {
        @include size(80%);
        @include flex;
        @extend %absolute-centered;

        p {
          font-size: 15px;
          text-align: center;
        }

        svg {
          @include size(100%);

          color: var(--bg-tint);
        }
      }
    }
  }
}
