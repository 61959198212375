@import '/src/assets/styles/variables.scss';

.container {
  @include flex(center, row);

  >svg {
    width: 25px;
    font-size: 22px;
    fill: var(--danger);
  }

  >span {
    font-size: 1.5rem;
  }
}
