@mixin flex($justify: center, $direction: column, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-gap(
  $gap: 1rem,
  $justify: center,
  $direction: column,
  $align: center
) {
  @include flex($justify, $direction, $align);

  gap: $gap;
}

@mixin position(
  $position: absolute,
  $top: auto,
  $right: auto,
  $bottom: auto,
  $left: auto
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin size($width, $height: $width) {
  height: $height;
  width: $width;
}

@mixin fixed-size($width) {
  max-width: $width;
  min-width: $width;
}

@mixin media-width($max-width: 1800, $width: auto) {
  @media (max-width: $max-width) {
    width: $width;
  }
}

@mixin font-color-tint($opacity: 0.4) {
  color: var(--font-color);
  opacity: $opacity;
}

@mixin btn-colored($bgColor: var(--primary), $color: var(--white)) {
  color: $color !important;
  background: $bgColor !important;
  border: 1px solid $bgColor !important;
  border-radius: $b-radius;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: $bgColor !important;
    background: $color !important;
  }

  &:active {
    color: $color !important;
    background: $bgColor !important;
  }
}
