@import '../../../../../../assets/styles/variables.scss';

.listContainer {
  display: grid;
  padding: 0;
  margin-top: 1rem;
  font-size: 11px;
  grid-template-columns: repeat(2, 1fr);
  list-style-type: none;
  gap: 1rem;

  .listValue {
    width: 100%;
    text-align: left;
  }

  @media (max-width: $media-xxl) {
    gap: 3rem;
  }
}
