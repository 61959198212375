@import '../../../../../assets/styles/variables.scss';

.infoBox {
  @include flex(flex-start, column, none);

  background: var(--bg);
  padding: 1.5rem;
  gap: 1rem;
  border-radius: 5px;

  h3 {
    font-size: 14px;
    font-weight: 700;
  }

  ul {
    padding: 0;
  }
}
