@import '../../../../assets/styles/variables.scss';

@mixin contrast-theme {
  h3,
  h4,
  p {
    color: var(--font-color);
  }

  background: var(--bg-tint-contrast);
}

@mixin theme-light-scheme {
  h3 {
    color: var(--font-color-scheme);
  }

  background: var(--bg-light-scheme);
}

.wrapper {
  @include flex(center, column);
  @include contrast-theme;

  width: 100%;
  background-color: var(--bg-tint);
  padding: 1rem;
  gap: 2rem;
  border-radius: $b-radius;
  box-shadow: $box-shadow;

  ::-webkit-scrollbar-track {
    background-color: var(--bg-tint-contrast);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--white600);
  }
}
