@import '/src/assets/styles/variables.scss';

.text {
  font-size: 18px;
  stroke: var(--font-color);
  cursor: default;

  &.large {
    font-size: 30px;
    font-weight: bold;

    @media (max-width: $media-xl) {
      font-size: 28px;
    }
  }

  &.percent {
    font-size: 26px;
    font-weight: normal;
    stroke-width: 0;

    @media (max-width: $media-xl) {
      font-size: 24px;
    }
  }
}
