@import '../../../../assets/styles/variables.scss';

.wrapper {
  @include flex(space-between, row, center);

  position: relative;
  height: 40px;
  width: 100%;

  .title {
    width: 100%;
    color: var(--font-color);
    margin: 0;
    text-align: left;
  }

  .fullForm {
    text-align: center;
  }
}
