@import '../styles/variables.scss';
@import './../../../assets/styles/index.scss';

$tile-size: 96px;
$tile-button-width: calc($tile-size * 0.8);
$tile-item-img-size: calc($tile-size * 0.8);
$plate-width: 72px;
$plate-font-size: 10px;
$margin-size-default: 6px;
$margin-y-default: calc($margin-size-default * 1.5);
$hover-color: rgb(200 200 200 / 70%);
$selected-color: rgb(21 115 255 / 100%);
$triangle-size: 10px;
$main-panel-width: 510px;
$weather-icon-size: 40px;
$cell-size: 5px;
$card-height: 65px;
$card-time-height: 45px;
$panel-title-font-size: 14px;
$title-font-size: 14px;
$subtitle-font-size: 12px;
$time-title-height: 24px;
$hover-filter: brightness(1.2);
$title-height: 24px;
$left-panel-width: calc(295px + 75px);
$right-panel-width: 500px;
$time-slider-side-margin: 25px;
$time-slider-0-offset: 220px;
$time-slider-right-offset: 150px;

.weathersPanel {
  position: absolute !important;
  top: $child-containers-top-offset-thin-screen !important;
  right: 0;
  width: $main-panel-width;
  margin-top: $child-containers-top-offset !important;
  border-radius: $b-radius;
  border-width: 0 !important;
}

.weathersPanelTitle {
  height: $title-height;
  padding-left: $cell-size;
  font-size: $panel-title-font-size;
  line-height: $title-height;
}

.weathersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $margin-size-default;
  margin-bottom: $margin-size-default;
}

.weatherItemContainer {
  display: block;
  margin-right: - $margin-size-default;
  margin-left: - $margin-size-default;
}

.weatherItemContainerRightTriangle {
  position: absolute;
  top: calc($triangle-size / 2);
  right: $triangle-size;
  height: 2 * $triangle-size;
  width: 2 * $triangle-size;
  background: var(--bg);
  border-radius: 0.5 * $triangle-size;
  transform: rotate(45deg);
}

.weatherPlate {
  height: $card-height;
  width: $plate-width;

  &:hover {
    filter: $hover-filter;
  }
}

.weatherPlateSelected {
  height: $card-height;
  width: $plate-width;
  background: $selected-color !important;

  &:hover {
    filter: $hover-filter;
  }
}

.weatherPlateContainer {
  display: block;
  cursor: pointer;
}

.weatherPlateImg {
  position: relative;
  top: 3px;
  left: 16px;
  transform: scale(0.9);
}

.weatherPlateText {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: $plate-font-size;
  line-height: 16px;
}

.weatherItemCard {
  height: $card-height;
  max-height: $card-height;
  width: $tile-size;
  max-width: $tile-size;
  margin-right: $cell-size !important;
  margin-left: $cell-size !important;

  &:hover {
    box-shadow: $hover-color 0 0 0 3px;
  }
}

.weatherItemCardSelected {
  height: $card-height;
  max-height: $card-height;
  width: $tile-size;
  max-width: $tile-size;
  box-shadow: $selected-color 0 0 0 3px;
  cursor: pointer;
}

.timeTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $time-title-height;
  color: var(--font-color);
  padding-top: calc($cell-size / 2) + 4px;
  font-size: $title-font-size;
  font-weight: bolder;
  cursor: pointer;
}

.timeTitleSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $time-title-height;
  color: var(--white);
  padding-top: calc($cell-size / 2) + 4px;
  font-size: $title-font-size;
  font-weight: bolder;
  cursor: pointer;
}

.timeSubtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color);
  font-size: $subtitle-font-size;
  cursor: pointer;
}

.timeSubtitleSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: $subtitle-font-size;
  cursor: pointer;
}

.unitsText {
  display: flex;
  margin-left: calc($cell-size / 2);
  font-size: 10px;
  font-weight: normal;
}

.weatherItemImg {
  display: block;
  height: $weather-icon-size;
  max-height: $weather-icon-size;
  width: $weather-icon-size !important;
  max-width: $weather-icon-size;
  margin-top: 2 * $cell-size;
  margin-right: auto;
  margin-left: auto;
  border-radius: $b-radius !important;
}

.isOneSmallElement {
  left: 250px !important;
}

.isTwoSmallElement {
  left: 280px !important;
}

.weatherTimeButtonsContainer0 {
  position: fixed;
  top: calc(100vh - 95px);
  display: flex;
  justify-content: space-around;
  align-items: inherit;
  max-height: 85px;
  background-color: var(--bg);
  transition: $transition;
}

.timeSlider {
  max-height: $card-height;
  transition: $transition;
}

.timeSlideContainer {
  width: 100%;
  background-color: var(--bg);
}

.timeSliderLeft {
  max-height: $card-height;
  width: calc(100vw - $left-panel-width - 185px) !important;
  transition: $transition;
}

.timeSliderLeftRight {
  max-height: $card-height;
  width: calc(100vw - $left-panel-width - $right-panel-width - 185px) !important;
  margin: 0;
  transition: $transition;
}

.timeSliderRight {
  max-height: $card-height;
  width: calc(100vw - $right-panel-width - 435px) !important;
  margin: 0;
  transition: $transition;
}

.weatherTimeTrack {
  display: flex;
  width: 80vw;
  overflow-x: hidden;
}

.timeCard {
  height: $card-time-height;
  max-height: $card-time-height;
  width: $tile-size;
  max-width: $tile-size;
  border-radius: $cell-size;

  &:hover {
    filter: $hover-filter;
  }
}

.timeCardSelected {
  height: $card-time-height;
  max-height: $card-time-height;
  width: $tile-size;
  max-width: $tile-size;
  color: white;
  background: $selected-color !important;
  border-radius: $cell-size;

  &:hover {
    filter: $hover-filter;
  }
}

.weatherLegendContainer {
  position: absolute;
  top: $child-containers-top-offset-thin-screen + 120px !important;
  right: 0;
  width: $main-panel-width;
  margin-top: $child-containers-top-offset !important;
  border-radius: $b-radius;
  border-width: 0 !important;
}

.weatherLegendMeasurementScale {
  height: 8px;
  width: 100%;
  margin-top: $cell-size;
  margin-bottom: $cell-size;
}

.labelsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $cell-size;
}

.windAnimationContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 2 * $cell-size;
  margin-bottom: $cell-size;
}

.legendWindLabel {
  margin-left: $cell-size;
}
