@import '../../../../../assets/styles/variables.scss';

$disabled-color: var(--grey100);

@mixin special-text() {
  color: $disabled-color;
  font-size: 12px;
}

.container {
  @include flex-gap(1rem, flex-start, row);

  .idBlock {
    @include special-text;

    min-width: 30px;
    text-align: right;
  }

  .title {
    margin: 0;
  }

  .count {
    @include special-text;
  }
}

.disabled {
  color: $disabled-color;
}
