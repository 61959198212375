@import '../../../../../assets/styles/variables.scss';

.modalContentContainer {
  width: 100%;
}

.modalContent {
  @include flex(center, column, none);

  width: 100%;
  gap: 20px;

  p {
    width: 40%;
    color: var(--font-color);
    margin: 0;
    font-size: 1.5rem;
    text-align: left;
  }

  .containerRowModal {
    @include flex(flex-start, row);

    gap: 6rem;

    .titleBlock {
      @include flex(flex-start, row);

      width: 40%;
      gap: 1rem;

      p {
        width: fit-content;
      }
    }
  }

  .timeCardStyle {
    width: 52%;
    margin-left: 48% !important;
    opacity: 0%;
    transition: opacity $transition;

    &.timeCardAnimate {
      opacity: 100%;
    }
  }
}

.picker {
  width: 55%;
}

.pickerTime {
  width: 100%;
}

.iconInfoBtn {
  color: var(--antd-blue);
  cursor: help;
}

.checkbox {
  position: relative;
  left: -1rem;
}

.radioGroup {
  width: 55%;
  margin: 0 !important;

  & > label {
    color: var(--font-color);
    background: var(--bg-tint);
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid var(--white600) !important;

    &:hover {
      background: var(--bg);
    }
  }

  .disabledBtn {
    color: var(--disabled-text) !important;
    background: var(--disabled) !important;
  }
}
