@import '../../../../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(3rem, flex-start, column, none);

  width: 100%;
  padding: 2rem;

  .slider {
    width: 90%;
    margin: 0 auto 2rem;
    cursor: grab;

    .phaseCardWrapper {
      padding: 1rem;

      .phaseCard {
        background-color: var(--bg);
      }
    }
  }
}

.confirm {
  max-width: $max-confirm-width;
}
