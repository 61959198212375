@import '../../../assets/styles/variables.scss';

.container {
  @include flex(space-between, row);

  .button {
    @include flex-gap(4px, center, row, flex-start);

    background-color: var(--bg);
    padding: 5px 10px;
    padding-left: 5px;
    border-radius: 4px;
    transition: $transition;
    cursor: pointer;

    .icon {
      @include size(22px);
    }

    .title {
      margin: 0;
      font-size: 14px;
    }

    &:hover {
      background-color: var(--bg-tint);
    }
  }
}
