@import '../../../assets/styles/variables.scss';

@mixin contrast-theme {
  h3,
  h4 {
    color: var(--font-color);
  }

  background: var(--bg-tint-contrast);
}

@mixin theme-light-scheme {
  h3,
  h4 {
    color: var(--font-color-scheme);
  }

  background: var(--bg-light-scheme);
}

.mainInfoCard,
.cameraLive {
  box-shadow: $box-shadow;
  border-radius: $b-radius;
}

.container {
  display: flex;
  flex-wrap: wrap;
  height: 80vh;
  max-height: 80vh;
  max-width: 100%;
  padding: 20px;
  margin-top: 10px;
  gap: 25px;
  overflow: auto;

  .mainInfoCard {
    @include contrast-theme;

    height: 20vh;
    min-height: 20vh;
    width: 50%;
    min-width: 610px;
    margin-right: 8rem;

    .titleRowContainer {
      @include flex(flex-start, row, none);

      p {
        color: var(--font-color);
        margin-left: 1rem;
        font-size: 16px;
      }
    }

    @media (max-width: ($media-xl + 30)) {
      margin-right: 0;
    }
  }

  .cameraLive {
    @include contrast-theme;

    height: 45vh;
    min-height: 425px;
    width: 610px;
  }
}
