@import '../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, column, flex-start);

  height: 100%;
  width: 100%;

  .content {
    width: 100%;
  }

  .btnBlock {
    @include flex-gap(1rem, flex-end, row, flex-end);

    width: 100%;
  }
}
