@import '../../../assets/styles/variables.scss';

.container {
  @include flex(center, row, center);
}

.img {
  width: 100%;
  overflow: hidden !important;
  object-fit: cover !important;
}
