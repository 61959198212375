@import '/src/assets/styles/variables.scss';

.wrapper {
  >span {
    display: flex !important;
    justify-content: center;
    width: 100%;
  }

  .upload > div {
    height: 400px !important;
    width: 400px !important;
  }

  .img {
    width: 100%;
  }

  .limits {
    display: block !important;
  }
}
