@import '../../../../../assets/styles/variables.scss';

.pingWrapper {
  @include flex(center, row, center);

  margin-right: 50px;
  gap: 2rem;

  .actionBtn {
    @include flex(center, row, center);

    gap: 0.5rem;
  }

  p {
    margin: 0;

    @include flex(center, row, center);

    gap: 1rem;
  }

  .ping {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
}
