@import '../../../assets/styles/variables.scss';

.containerNotCameras {
  @include flex(center, row, center);

  width: 100%;

  .mainGraphCardNotCameras {
    @include flex(center, column);

    width: 40%;
    margin-bottom: 4rem;
    box-shadow: $box-shadow;
    border-radius: $b-radius;
    text-align: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
