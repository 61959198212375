@import '/src/assets/styles/variables.scss';

.container {
  width: 100%;

  .slider {
    @include flex-gap(1rem, flex-start, row);

    width: 100%;
    margin: 0 auto 2rem;
    cursor: grab;

    & > div {
      width: 100%;
    }
  }
}

.noData {
  color: var(--grey100);
  margin: 0;
}
