@import '/src/assets/styles/variables.scss';

.wrapper {
  @include size(100%);
  @include flex-gap(1rem, flex-start, column, flex-start);

  form {
    width: 80%;

    .coordInput {
      width: 40%;
    }
  }
}
