@import '../../../assets/styles/variables.scss';

.imgContainer,
.imgWrapper {
  @extend %absolute-centered;
}

.imgWrapper {
  overflow: hidden;
}

.fixedContainer {
  @include size(100%);
}
