@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/zIndex.scss';

$grid-size: 3px;
$bottom-offset: 120px;
$icon-size: 16px;

.listContainer {
  height: calc(100vh - $bottom-offset);
  max-height: calc(100vh - $bottom-offset);
  min-height: calc(100vh - 2 * $bottom-offset);
}

.listItem {
  padding-right: 2 * $grid-size;
  padding-left: 2 * $grid-size;
  margin-top: 0;
  margin-bottom: 0;
  border: 3px rgb(255 255 255 / 0%) solid;
  border-radius: 2 * $grid-size;
  border-bottom: 1px solid var(--bg-tint);
  line-height: 7 * $grid-size;

  &:hover {
    border: 3px var(--antd-blue) solid;
  }
}

.icon {
  height: $icon-size;
  width: $icon-size;
  margin-right: $grid-size;
}

.listItemTitle {
  display: flex;
  align-items: center;
  color: var(--font-color);
  font-size: 14px;
  font-weight: bolder;
}

.listItemSubTitle {
  color: var(--font-color-lite);
  margin-top: $grid-size;
  margin-left: 6 * $grid-size - $icon-size;
  font-size: 13px;
}

.actionsContainer {
  margin-top: $grid-size;
  margin-left: 6 * $grid-size - $icon-size;
}

.actionItemContainer {
  display: flex;
  align-items: center;
  margin-right: 4 * $grid-size;

  &:hover {
    color: var(--hover-tab);
  }
}

.actionItemText {
  margin-left: $grid-size;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: var(--hover-tab);
  }
}

.noDataText {
  color: var(--disabled);
  font-size: 12px;
}
