@import '../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(3rem, flex-start, column, none);

  width: 100%;
}

.titleContainer {
  @include flex(flex-start, row);

  gap: 1rem;
}

.tooltipContainer {
  font-weight: bold;
  text-align: center;
}

.imgInfo {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 3rem;
  border: 2px solid var(--bg-tint-contrast);
  border-radius: $b-radius;
  box-shadow: $box-shadow;
}

.selectText {
  height: 100%;
}

.phasesListContainer {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
}
