@import '../../assets/styles/variables.scss';

.wrapper {
  .video {
    width: 400px;
    max-width: 400px;
    aspect-ratio: 16 / 9;
    border: 1px solid black;
    border-radius: $b-radius;
  }
}
