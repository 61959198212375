@import '/src/assets/styles/variables.scss';

.card {
  box-shadow: $box-shadow;
  border-radius: $b-radius;

  & > div {
    height: 100%;
  }

  .container {
    @include flex-gap(1rem, flex-start, column, flex-start);

    height: 100%;
    overflow-y: hidden;

    & > h3 {
      font-weight: bolder;
    }
  }
}
