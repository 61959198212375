@import '../../../../../../../assets/styles/variables.scss';

.container {
  @include flex(space-between, column, none);

  width: 100%;
  max-width: 300px;
  gap: 20px;

  .titleContainer {
    @include flex(flex-start, row, center);

    gap: 10px;

    .title {
      font-size: 16px;
    }

    .colorType {
      @include size(20px);

      border-radius: 50%;
    }
  }

  .requirementsContainer {
    @include flex(space-between, column, none);

    gap: 5px;

    p {
      text-align: left;
    }
  }
}
