@import '/src/assets/styles/variables.scss';

$border-style: 2px solid var(--bg-tint) !important;

.card {
  @include size(230px, 410px);

  border: $border-style;

  div[class*='body'] {
    height: 50%;
    padding: $padding !important;
    border-top: $border-style;
  }

  .title {
    display: block;
    font-size: 11px;
    font-weight: bold;
    text-wrap: wrap;

    &.invisible {
      display: none;
    }
  }

  &.back {
    div[class*='body'] {
      height: 100%;
    }
  }
}
