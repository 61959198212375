@import '../../../../../assets/styles/variables.scss';

.container {
  @include size(100%);
  @include flex;

  position: relative;
  background-color: var(--bg-tint);
  padding: $padding;
  border-radius: $b-radius;
  transition: background-color $transition;

  .headerCard {
    position: absolute;
    top: 1rem;
    right: 1rem;

    .infoBtn {
      transition: color $transition;
      cursor: help;

      &:hover {
        color: var(--primary);
      }
    }
  }

  .titleCard {
    @include flex(center, column, center);

    margin-top: 1.5rem;
    gap: 1rem;

    p {
      font-size: 1rem;
      text-align: center;
    }

    .circleCommand {
      @include size(10px);

      background-color: var(--disabled-grey);
      border-radius: 50%;
    }
  }

  .footerCard {
    @include flex(center, row, center);

    position: absolute;
    right: 0;
    bottom: 1rem;
    left: 0;
    margin: 0 auto;
  }
}

.disabledCard {
  color: var(--grey100) !important;
  background-color: var(--disabled) !important;
}

.active {
  border: 2px solid var(--primary);
  box-sizing: border-box;
}
