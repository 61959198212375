/*
// ***************************
// Put here some global styles
// ***************************
*/
@import 'antd/dist/reset.css';
@import 'normalize.css';

@font-face {
  font-family: 'Noto Sans';
  src: url('../fonts/noto-sans/NotoSans-Regular.ttf');
}

/* stylelint-disable */
* {
  font-family: -apple-system, 'Noto Sans', BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* stylelint-enable */

h2 {
  font-size: 2rem;
}

body,
#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

:root {
  font-size: 10px;

  /* colors */
  --black900: #181a22;
  --black600: #1e1f28;
  --black300: #272935;
  --black100: #2f313e;
  --grey300: #414354;
  --grey200: #4f5164;
  --grey100: #84879b;
  --grey10: #c7c7c9;
  --blue900: #345187;
  --blue500: #1573ff;
  --blue300: #408cff;
  --blue100: #91d5ff;
  --turquoise: #3bb4bc;
  --green200: #37b571;
  --green100: #4bc77f;
  --green-light: #069e06;
  --green: #007d06;
  --orange: #fa9e14;
  --whity: #f1f1f1;
  --white600: #bcbcbc;
  --white: #fff;
  --white600-o80: #79797925;
  --white600-o15: #2f313ec9;
  --grey300-o09: #41435409;
  --grey300-o05: #00000094;
  --error: #e01515;
  --danger: #ff4c4c;
  --danger-opacity80: #ff4c4c80;
  --danger-opacity50: #ff4c4c50;
  --antd-blue: #1573ff;
  --antd-blue-opacity: #188fff1a;
  --antd-blue-opacity8a: #188fff8a;
  --disabled-grey: #ccc;
  --purple: #b567f1;
  --purple-tint: #b8acf6;
  --warning: #e79f10;
  --active-tab: #248fff;
  --hover-tab: #3c9dfb;
  --tooltip-black: #1f1f1f;
  --hover-blue: #1573ff26;
  --dir-not-allowed: #ce6f4f;

  /* dark-theme */
  --bg-dark: var(--black100);
  --bg-tint-dark: var(--grey300);
  --bg-tint-contrast-dark: var(--black100);
  --bg-light-scheme-dark: var(--grey10);
  --sidebar-profile-dark: var(--grey300);
  --font-color-dark: var(--white);
  --font-color-scheme-dark: var(--grey300);
  --font-color-lite-dark: var(--white600);
  --font-primary-dark: var(--white);
  --sidebar-btn-dark: var(--whity);
  --logo-sign-itc-dark: var(--whity);
  --logo-sign-sber-dark: var(--grey100);
  --disabled-dark: var(--grey200);
  --disabled-with-bg-dark: var(--grey100);
  --disabled-tint-dark: var(--grey100);
  --disabled-text-dark: var(--grey100);
  --primary-log-dark: var(--antd-blue);
  --success-log-dark: var(--green);
  --warning-log-dark: var(--warning);
  --json-key-dark: var(--purple-tint);
  --tooltip-dark: var(--tooltip-black);
  --hover-btn-dark: var(--white600-o15);

  /* light-theme */
  --bg-light: var(--white);
  --bg-tint-light: var(--whity);
  --bg-tint-contrast-light: var(--white);
  --bg-light-scheme-light: var(--white);
  --sidebar-profile-light: var(--white600);
  --font-color-light: var(--black600);
  --font-color-scheme-light: var(--black600);
  --font-color-lite-light: var(--grey300-o05);
  --font-primary-light: var(--primary);
  --sidebar-btn-light: var(--blue500);
  --logo-sign-itc-light: var(--grey100);
  --logo-sign-sber-light: var(--black300);
  --disabled-light: var(--white600);
  --disabled-with-bg-light: var(--bg-tint-light);
  --disabled-tint-light: var(--white600);
  --disabled-text-light: var(--white600);
  --primary-log-light: var(--hover-tab);
  --success-log-light: var(--green200);
  --warning-log-light: var(--orange);
  --json-key-light: var(--purple);
  --tooltip-light: var(--white);
  --hover-btn-light: var(--hover-blue);

  /* vars */
  --bg: var(--bg-light);
  --bg-tint: var(--bg-tint-light);
  --font-color: var(--font-color-light);
  --font-color-scheme: var(--font-color-light);
  --font-color-lite: var(--font-color-light);
  --font-primary: var(--font-primary-light);
  --primary: var(--blue500);
  --sidebar-profile: var(--sidebar-profile-light);
  --sidebar-btn: var(--sidebar-btn-light);
  --logo-sign-itc: var(--logo-sign-itc-light);
  --logo-sign-sber: var(--logo-sign-sber-light);
  --disabled: var(--disabled-light);
  --disabled-with-bg: var(--disabled-with-bg-light);
  --disabled-tint: var(--disabled-tint-light);
  --disabled-text: var(--disabled-text-light);
  --primary-log: var(--primary-text-light);
  --success-log: var(--success-log-light);
  --warning-log: var(--warning-log-light);
  --json-key: var(--json-key-light);
  --tooltip: var(--white);
  --hover-btn: var(--hover-btn-light);
}

#root {
  display: flex;
}

*,
::after,
::before {
  box-sizing: border-box;
}
/* stylelint-disable */
body {
  font-size: 14px;
  line-height: 1.5715;
  font-family: -apple-system, 'Noto Sans', BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  height: 1px !important;
  background: var(--bg-tint);
  border: none;
}

/* scrollbar settings */

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-tint);
}

::-webkit-scrollbar-thumb {
  background: var(--white600);
}

.slick-arrow::before {
  color: var(--bg-dark);
}

.recharts-layer {
  > text {
    fill: var(--font-color);
  }
}

.ant-notification-notice,
.ant-notification-notice-message,
.ant-notification-notice-description,
.ant-notification-notice-close-x {
  background: var(--bg-tint) !important;
  color: var(--font-color) !important;
}

.ant-notification-notice-close {
  * svg {
    fill: var(--font-color) !important;
  }
}

.ant-menu-submenu-title,
.ant-menu-item,
.ant-menu-sub {
  padding-left: 8px !important;
  margin-bottom: 0 !important;
  line-height: 16px !important;
  white-space: normal;
}

.antd-popover,
.antd-popover-inner,
.antd-popover-arrow {
  background-color: var(--bg-tint) !important;
  --antd-arrow-background-color: var(--bg-tint) !important;
}

.antd-modal,
.antd-modal .antd-modal-content {
  background-color: var(--bg-tint) !important;
}

.slick-next:before {
  content: '' !important;
}

.slick-prev:before {
  content: '' !important;
}

/* stylelint-enable */
