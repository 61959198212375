@import '../../../../assets/styles/variables.scss';

$ico-b-radius: 20%;

%btn-hover {
  :hover {
    filter: brightness(106%) grayscale(10%);
  }
}

%btn {
  @extend%btn-hover;

  position: absolute;
  height: 50%;
  width: 100%;
  border: none;
  pointer-events: all;
  cursor: pointer;
  overflow: hidden;
}

%ico {
  position: absolute;
  left: 0;
}

.cmIco {
  @extend %ico;

  top: 0;
}

.dtIco {
  @extend %ico;

  top: -2px;
}

.btnWrapper {
  @extend %absolute-centered;
  @extend%btn-hover;
  @include size(100%);

  border-radius: $ico-b-radius;
  overflow: hidden;
}

.dtBtn {
  @extend %btn;

  top: 0;
  border-radius: $ico-b-radius $ico-b-radius 0 0;
}

.cmBtn {
  @extend %btn;

  bottom: 0;
  border-radius: 0 0 $ico-b-radius $ico-b-radius;
}

.id {
  position: absolute;
  bottom: 0;
  color: var(--white);
  font-weight: bold;
  pointer-events: none;
}
