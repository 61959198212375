@import '/src/assets/styles/variables.scss';

.wrapper {
  @include size(100%);
  @include flex-gap(2rem, flex-start, column, flex-start);

  position: relative;

  .select {
    width: 80%;
  }
}
