@import '../../../../assets/styles/variables.scss';

.content {
  display: block;
  padding: 0 20px;
  margin-top: 15px;
  transition: $transition;
  overflow: hidden;

  &.isOpen {
    height: 100%;
    padding: 0 20px 20px;
  }

  .row {
    max-width: 220px;
    color: var(--font-color);
    margin-bottom: 5px;
    font-size: 13px;

    span {
      font-weight: bold;
    }
  }
}
