@import '/src/assets/styles/variables.scss';

.title {
  @include flex;

  position: relative;
  width: 100%;

  .head {
    font-size: 14px;
  }
}
