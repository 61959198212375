@import '/src/assets/styles/variables.scss';

.block {
  position: absolute;
  top: 24px;
  right: 24px;
  background-color: var(--disabled);
  padding: 3px;

  .btnText {
    margin: 0;
    font-size: 12px;
  }
}
