@import '../styles/variables.scss';
@import './../../../assets/styles/index.scss';

$tile-size: 96px;
$triangle-size: 10px;
$hover-color: rgb(200 200 200 / 70%);
$selected-color: rgb(21 115 255 / 100%);
$cell-size: 6px;
$margin-size-default: 6px;
$main-panel-width: 400px;
$main-panel-height: 260px;
$panel-title-font-size: 14px;
$title-font-size: 14px;
$subtitle-font-size: 12px;
$hint-text-size: 12px;
$title-height: 24px;
$icon-size: 16px;
$right-offset: 50px;
$list-item-width: calc($main-panel-width - 4 * $cell-size);
$search-list-item-height: calc(75px - $margin-size-default);
$direction-list-item-height: 40px;

.routePanel {
  position: absolute !important;
  top: $child-containers-top-offset-thin-screen;
  right: 50px;
  width: $main-panel-width !important;
  margin-top: $child-containers-top-offset !important;
  border-radius: $b-radius;
  border-width: 0 !important;
}

.baseMapsPanelTitle {
  padding-left: 5px;
  font-size: 14px;
}

.baseMapsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.baseMapItemContainer {
  display: block;
}

.baseMapItemCard {
  max-height: $tile-size;
  max-width: $tile-size;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.baseMapItemImg {
  height: $tile-size !important;
  max-height: $tile-size;
  width: $tile-size !important;
  max-width: $tile-size;
  border-radius: $b-radius !important;

  &:hover {
    box-shadow: $hover-color 0 0 0 3px;
  }
}

.baseMapItemImgSelected {
  height: $tile-size !important;
  max-height: $tile-size;
  width: $tile-size !important;
  max-width: $tile-size;
  border-radius: $b-radius !important;
  box-shadow: $selected-color 0 0 0 3px;
}

.routeField {
  margin-top: $cell-size !important;
}

.narrativeItem {
  display: flex;
  align-items: center;
  margin-top: $margin-size-default;
  margin-bottom: $margin-size-default;
  font-size: $subtitle-font-size;
}

.narrativeItemTitle {
  width: 210px;
  margin-right: 4 * $cell-size;
}

.narrativeItemDistance {
  width: 60px;
  margin-right: 4 * $cell-size;
}

.narrativeItemTime {
  width: 80px;
}

.selectionActive {
  color: $selected-color;
}

.searchItemIconType {
  margin-right: $cell-size;
}

.directionSummaryContainer {
  display: block;
  align-items: center;
  margin-top: $margin-size-default;
  margin-bottom: $margin-size-default;
}

.directionSummaryResultItem {
  margin-right: 2 * $cell-size;
}

.searchResultListItem {
  height: $search-list-item-height;
  max-height: $search-list-item-height;
  min-height: $search-list-item-height;
}

.directionResultListItem {
  height: $direction-list-item-height;
  max-height: $direction-list-item-height;
  min-height: $direction-list-item-height;
}

.settingsHint {
  color: var(--font-color-lite);
  margin-top: $margin-size-default;
  font-size: $hint-text-size;
  font-weight: lighter;
  line-height: $hint-text-size + $margin-size-default;
  font-variant: contextual;
  text-align: justify;
}

.filler {
  width: 8px;
}
