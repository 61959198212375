@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex(space-between, column);
  @include size(100%);

  min-height: 100%;
  max-width: 450px;
  margin: 0 auto;
  gap: 1rem;

  .mainContainer {
    width: 100%;

    .titleRow {
      @include flex(space-between, row, flex-start);

      width: 100%;

      .phaseName {
        background-color: var(--antd-blue);
      }

      .secRow {
        @include flex(space-between, row, flex-start);

        gap: 1rem;

        .phaseSec {
          background-color: var(--grey100);
        }
      }
    }

    .img {
      @include size (100%);

      border-radius: $b-radius;
      object-fit: contain;
      overflow: hidden;
    }
  }
}

.detailed {
  @media (max-width: $media-xxl) {
    @include flex-gap(2rem, center, row);
  }
}

.phaseName,
.phaseSec {
  color: var(--whity);
  padding: 2px 4px;
  font-size: 12px;
  border-radius: $b-radius;
}
