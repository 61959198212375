@import '/src/assets/styles/variables.scss';

.infoOverlay {
  position: absolute;
  z-index: $default-index;
  bottom: 2rem;
  min-width: 15px;
  color: var(--font-color);
  background: var(--bg);
  padding: 1px 3px;
  border-radius: $b-radius;
  transform: translate(-50%, 0);
  box-sizing: content-box;
  box-shadow: $box-shadow;

  p {
    margin: 0;
    font-size: 10px;
    text-align: center;
  }

  &.infoOverlaySelect {
    bottom: 7rem;
  }
}
