@import '/src/assets/styles/variables.scss';

.select {
  width: 70%;
  margin-bottom: 7px !important;
}

.spaceHorizontal {
  @include flex(space-between, row);

  width: 100%;

  h4 {
    margin-right: 3rem;
  }
}
