@import '../../../assets/styles/variables.scss';

.wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
  gap: 1rem;
  align-self: flex-start;

  .counterBox {
    display: flex;
    justify-content: center;
    height: 24px;
    width: 32px;
    border: 2px solid var(--antd-blue);
    border-radius: $b-radius;
    overflow: hidden;

    span {
      position: relative;
      top: 1px;
      display: block;
      font-size: 0.9em;
      -webkit-touch-callout: none;
      user-select: none;
    }
  }

  .phaseNowBox {
    cursor: help;
  }
}
