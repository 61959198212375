@import '../../../../../../../assets/styles/variables.scss';

.container {
  position: absolute;
  right: 3rem;
  bottom: 0;
  min-width: 200px;
  background-color: var(--bg);
  padding: $padding;
  opacity: 80%;
  border-radius: $b-radius;

  h4 {
    font-weight: bolder;
  }

  .contentContainer {
    @include flex-gap(0.5rem, flex-start, column, flex-start);

    p {
      margin: 0;
      font-size: 12px;
    }
  }
}
