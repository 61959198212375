@import '../../../assets/styles/variables.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  height: 80vh;
  max-height: 80vh;
  max-width: 100%;
  padding: 20px;
  margin-top: 10px;
  gap: 25px;
  overflow: auto;
}

.passDirNChnls,
.passChInDrcs,
.passSgnProg {
  table {
    td,
    th {
      padding: 0.1em !important;
    }
  }
}

.containerSub {
  display: flex;
  flex-wrap: wrap;
  min-height: 340px;
  min-width: 100%;
  gap: 25px;
  flex-grow: 1;

  .passInfo,
  .passDirNChnls,
  .passChInDrcs {
    flex-grow: 1;
  }

  .passInfo {
    flex-grow: 0.5;
  }

  .passInfo,
  .passDirNChnls,
  .passChInDrcs,
  .passGraphNProg {
    box-shadow: 1px 1px 20px #0003;
    border-radius: $b-radius;
  }

  .passGraphNProg {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 5;

    .passGraphNProgSubWrap {
      display: flex;
      flex-direction: row;
      width: 100% !important;

      .passDailyLogs {
        min-height: 100px;
        width: 220px;
        min-width: 100px;
        flex-grow: 5;
        border-radius: $b-radius 0 0 $b-radius;
        overflow-y: auto;

        .datePickerWrap {
          input {
            color: var(--font-color);
          }

          svg {
            fill: var(--font-color-lite);
          }

          width: fit-content;
        }

        tr {
          td:last-of-type {
            max-width: 500px;
          }
        }
      }

      .passSgnProg {
        min-height: 100px;
        min-width: 100px;
        flex-grow: 1;
        border-radius: 0 $b-radius $b-radius 0;
      }
    }
  }
}

.infcontent {
  display: flex;
  justify-content: space-between;

  p[id^='inf_'] {
    margin: 0;
  }

  h4 {
    font-weight: 400;
    filter:
      invert(25%) sepia(24%) saturate(383%) hue-rotate(197deg)
      brightness(95%) contrast(95%);
  }

  > div {
    width: 100%;
  }
}

@media (orientation: portrait) {
  .containerSub,
  .passGraphNProg,
  .passGraphNProgSubWrap {
    flex-direction: column !important;
  }

  .passGraphNProg {
    box-shadow: none !important;

    div:first-of-type {
      width: 100% !important;
      margin-bottom: 20px;
    }

    .passDailyLogs,
    .passSgnProg {
      box-shadow: 1px 1px 20px #0003;
      border-radius: $b-radius;
    }
  }
}

@media (max-width: 1280px) {
  .container {
    max-height: 70vh;
  }
}
