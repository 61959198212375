@import '../../../../../../assets/styles/variables.scss';

$height-container: 60vh;

.listContainer {
  max-height: $height-container;
  width: 100%;
  padding: 0;
  overflow: auto;
  border: 1px solid rgb(140 140 140 / 35%);
  border-radius: $b-radius;
}
