@import '../../../../../assets/styles/variables.scss';

.icon {
  @include size(20px);

  cursor: help;
  transition: $transition;

  &:hover {
    color: var(--antd-blue);
  }
}
