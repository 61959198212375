@import '/src/assets/styles/variables.scss';

.card {
  @include flex-gap(2rem, flex-start, column, flex-start);

  width: 100%;
  padding: 0 $padding;

  .row {
    @include flex(space-between, row);

    width: 100%;

    .box {
      width: 45%;

      .item {
        width: 100%;
      }
    }
  }

  p {
    margin-bottom: 4px;
    font-size: 12px;
  }

  .boxBlock {
    width: 100%;

    .rowBlock {
      @include flex-gap(1rem, center, row);

      & > div {
        @include flex-gap(1rem, flex-start, row);

        & > span {
          @include size(30px);

          svg {
            @include size(100%);
          }
        }

        .block {
          width: 100%;
        }
      }
    }

    .rowContainer {
      @include flex(space-between, row);

      & > div {
        width: 250px;
      }
    }
  }
}
