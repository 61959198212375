@import '../styles/variables.scss';
@import './../../../assets/styles/index.scss';

$title-font-size: 14px;
$tip-font-size: 10px;
$subinfo-font-size: 9px;
$icon-font-size: 12px;
$settings-title-font-size: 14px;
$hint-text-size: 12px;
$node-line-height: 18px;
$layer-item-min-width: 355px;
$margin-size: 6px;
$inactive-opacity-filter: opacity(0.4);
$panel-width: 440px;
$group-node-width: 380px;

.layersPanel {
  position: absolute !important;
  top: 60px;
  right: $child-containers-right-offset;
  width: $panel-width;
  border-radius: $b-radius;
  border-width: 0 !important;
  overflow-y: hidden !important;
}

.folderContainer {
  display: flex;
  align-items: center;
}

.folderTitle {
  display: flex;
  font-size: $title-font-size;
  font-weight: bolder;

  ::before {
    display: flex;
  }
}

.nodeContainer {
  display: flex;
  align-items: center;
}

.nodeSettings {
  color: var(--font-color-lite);
  font-size: 1.5 * $icon-font-size;

  &:hover {
    color: var(--font-primary);
  }
}

.nodeTitleContainer {
  position: relative;
  display: block;
  margin-top: $margin-size;
  margin-bottom: $margin-size;
  align-content: center;
  line-height: $node-line-height;
}

.blockContainer {
  display: block;
  margin-top: $margin-size;
  margin-right: 2 * $margin-size;
  margin-bottom: $margin-size;
  align-content: center;
  line-height: $node-line-height;
}

.groupContainer {
  display: flex;
  justify-content: space-between;
  min-width: $group-node-width;
}

.groupTitle {
  color: var(--font-color);
  font-size: $title-font-size;
  font-weight: bold;
}

.nodeTitle {
  display: block;
  color: var(--font-color);
  font-size: $title-font-size;
}

.nodeTitleNotInRange {
  display: block;
  color: var(--font-color-lite);
  font-size: $title-font-size;
  filter: $inactive-opacity-filter;
}

.nodeTitleScaleContainer {
  display: block;
  color: var(--font-color-lite);
  margin-left: 4 * $margin-size;
  font-size: $subinfo-font-size;
}

.nodeTitleScaleContainerNotInRange {
  display: block;
  color: var(--font-color-lite);
  margin-left: 4 * $margin-size;
  font-size: $subinfo-font-size;
  filter: $inactive-opacity-filter;
}

.nodeTipContainer {
  display: flex;
  align-items: center;
  color: var(--orange);
  font-size: $tip-font-size;
}

.nodeTipIcon {
  color: var(--orange) !important;
  margin-right: 2 * $margin-size;
  font-size: $tip-font-size;
}

.settingsTitle {
  margin-top: $margin-size;
  margin-bottom: $margin-size;
  font-size: $settings-title-font-size;
  font-weight: bolder;
}

.labelsOptionsContainer {
  display: flex;
}

.optionTitle {
  margin-left: $margin-size;
}

.settingsHint {
  color: var(--font-color-lite);
  padding-right: 4 * $margin-size;
  padding-left: 4 * $margin-size;
  margin-bottom: 2 * $margin-size;
  font-size: $hint-text-size;
  font-weight: lighter;
  line-height: $hint-text-size + $margin-size;
  font-variant: contextual;
  text-align: justify;
}

.layerItemTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: $layer-item-min-width;
}

.visibilityIcon {
  margin-right: $margin-size;
  font-size: 1.5 * $icon-font-size;
}

.rowLine {
  display: flex;
  align-items: center;
}

.settingsContainer {
  display: none;
}

.labelsSettingsContainer {
  margin-right: 4 * $margin-size;
  margin-left: 4 * $margin-size;
}

.settingsInput {
  width: 100%;
}

.opacityStyle {
  margin-right: 4 * $margin-size;
  margin-left: 4 * $margin-size;
}

.inputGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2 * $margin-size;
  margin-bottom: $margin-size;
}

.inputTitle {
  color: var(--font-color-lite);
  margin-top: $margin-size;
  font-size: $hint-text-size;
  font-weight: lighter;
  line-height: $hint-text-size + $margin-size;
  font-variant: contextual;
  text-align: justify;
}

.searchField {
  margin-top: $margin-size;
  margin-bottom: $margin-size;
}

.downloadIcon {
  margin-right: $margin-size;
  font-size: 16px;
}

.downloadButton {
  align-items: center;
  margin-bottom: $margin-size;
}

.arrowBackIcon {
  margin-right: $margin-size;
  cursor: pointer;
}

.nodeSubInfoContainer {
  display: flex;
  align-items: center;
  margin-left: 4 * $margin-size;
  font-size: $subinfo-font-size;
}

.countViewContainer {
  width: $margin-size !important;
  max-width: $margin-size !important;
}

.countViewNoDataContainer {
  width: $margin-size !important;
  max-width: $margin-size !important;
  color: var(--orange);
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  margin-right: $margin-size;
  margin-left: $margin-size;
}

.layerActionIcon {
  color: var(--disabled-tint);
  margin-right: $margin-size;
  font-size: 1.5 * $icon-font-size;

  &:hover {
    color: var(--font-primary);
    cursor: pointer;
  }
}
