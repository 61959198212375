@mixin point-after($height) {
  position: absolute;
  bottom: 0;
  left: -1px;
  height: $height;
  width: 1px;
  background: var(--font-color);
  content: '';
}

@mixin point-before($height) {
  position: absolute;
  right: 0;
  bottom: 0;
  height: $height;
  width: 1px;
  background: var(--font-color);
  content: '';
}

.wrapper {
  display: flex;
  align-items: flex-end;
  height: 40px;
  width: 100%;
  margin-top: 1rem;

  .point {
    position: relative;
    height: 5px;
    width: 100%;

    &::after {
      @include point-after(5px);
    }

    &.isMultipleTen::after {
      @include point-after(15px);
    }

    &.isMultipleFive::after {
      @include point-after(10px);
    }

    &.isLast::before {
      @include point-before(5px);
    }

    &.isLast.isMultipleTen::before {
      @include point-before(5px);
    }

    &.isLast.isMultipleFive::before {
      @include point-before(10px);
    }

    .tenPoint {
      position: absolute;
      top: -3rem;
      right: 0;
      font-size: 12px;
    }
  }
}
