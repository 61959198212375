@import '../../../../assets/styles/variables.scss';

.wrapper {
  @include size(100%);

  .title {
    text-align: center;

    &.detailed {
      font-weight: bold;
      text-align: left;
    }
  }

  .return {
    margin-top: $margin;
  }

  .tabsCustom div {
    font-size: 12px;
  }
}
