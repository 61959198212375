@import '../../../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-end, row);

  position: absolute;
  right: 0;
  height: 100%;

  & > span {
    @include flex;
  }

  .warningIco {
    @include size(20px);

    color: var(--warning);
  }
}
