@import '../../../assets/styles/variables.scss';

.title {
  @include flex(space-between, row);

  position: sticky;
  left: 1px;
  width: 100%;
  margin-bottom: 2rem;

  h3 {
    color: var(--font-color);
  }
}
