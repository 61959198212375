@import '../../../../../../assets/styles/variables.scss';

.container {
  @include flex(flex-start, column, none);

  width: 45%;
  margin-right: 11rem;

  strong {
    margin: 2rem 0;
    font-size: large;
  }
}
