@import '/src/assets/styles/variables.scss';

$radius: 2px;

.container {
  @include flex-gap(1rem, flex-start, row);

  border: 1px solid var(--disabled);
  border-radius: $radius;

  .block {
    @include flex;
    @include fixed-size(35px);

    height: 100%;
    color: var(--white);
    padding: 3px;
    font-size: 12px;
    font-weight: 400;
    border-radius: $radius 0 0 $radius;
  }

  p {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
  }
}
