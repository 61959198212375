@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(2px, space-between, row);

  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: var(--bg);
  padding: 2px 10px;
  border: 1px solid var(--disabled);
  border-radius: 4px;
  transition: $transition;
  cursor: pointer;

  p {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
  }

  &:hover {
    background-color: var(--bg-tint);
  }
}
