@import '../../../../assets/styles/variables.scss';
@import '../PointerMarker/PointerMarker.module.scss';

.crosshairPopupMain {
  @extend %crosshair-default;
  @include flex(center, none, center);
  @include size(34px);

  bottom: -30px;
  color: var(--font-color);
  background: var(--primary);
  padding: 0;
  border: 2px solid var(--primary);

  svg {
    transform: scale(1.1);
  }
}
