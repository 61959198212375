@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, column, flex-start);
  @include size(100%);

  .info {
    @include size(100%);

    max-height: 350px;
    padding: $padding;
    border: 1px solid var(--bg-tint);
    border-radius: $b-radius;
    overflow: auto;

    .desc {
      span[class*='label'] {
        font-size: 12px;
      }

      span[class*='content'] {
        font-size: 12px;
      }

      th {
        padding-bottom: 5px;
      }
    }
  }

  & > h5 {
    font-weight: bold;
  }
}
