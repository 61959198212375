@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%, 50%);
}

.tabsCustom {
  @include size(100%);

  & > div {
    width: 100%;
  }

  div[class*='content-holder'] {
    @include size(100%);

    & > div {
      @include size(100%);

      & > div {
        @include size(100%);
      }
    }
  }

  div[role='tablist']::before {
    border-bottom: 1px solid var(--bg-tint) !important;
  }
}
