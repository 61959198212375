@import '../../../../../../assets/styles/variables.scss';

.circle {
  @include flex(center, row);
  @include size(100%);

  background-color: var(--bg-tint);
  border-radius: 50%;
  border: 1px solid var(--font-color);
  transition: $transition;

  svg {
    max-width: fit-content;
    padding: 10%;
  }
}

.circleHover {
  &:hover {
    background-color: var(--green200);
    cursor: pointer;
  }
}

.active {
  color: var(--font-color-dark);
  background-color: var(--antd-blue);
}
