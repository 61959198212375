@import '../../../../../../assets/styles/variables.scss';

.btnsRow {
  position: absolute;
  top: 50%;
  right: 1rem;
  display: flex;
  margin-top: 0.3rem;
  transform: translate(0, -50%);

  .tag {
    height: 100%;
  }

  .downloadBtn,
  .deleteBtn {
    @extend %reset-btn-style;

    svg {
      @include size(20px);
    }
  }
}
