@import '/src/assets/styles/variables.scss';

.text {
  font-size: 6px;
  transition: $transition;

  &.active {
    font-size: 8px;
  }
}
