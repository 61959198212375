@import '../../../assets/styles/variables.scss';

.mainBtn {
  @include flex;
  @include size(40px);

  color: var(--font-color);
  background: var(--bg);
  font-size: 2.5rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 10px rgb(var(--grey400) 0.4);
  transition: $transition;

  > svg {
    color: var(--sidebar-btn);
    transition: color $transition;
  }

  &:not(.active):active {
    > svg {
      color: var(--white);
      transition: none;
    }
  }
}

.active {
  color: var(--white) !important;
  background: var(--primary) !important;

  > svg {
    color: var(--white) !important;
  }

  p {
    color: var(--white) !important;
  }

  &:hover {
    opacity: 80%;
  }
}

.round {
  border-radius: 6px;
}

.hover {
  &:hover {
    opacity: 75% !important;

    > svg {
      color: var(--font-primary) !important;
    }
  }
}

.hoverForActiveBtn {
  &:hover {
    background: var(--primary) !important;
    opacity: 75% !important;

    > svg {
      color: var(--white) !important;
    }
  }
}

.roundTop {
  border-radius: $b-radius-top;
}

.roundBottom {
  border-radius: $b-radius-bottom;
}

.roundRight {
  border-radius: $b-radius-right;
}

.roundLeft {
  border-radius: $b-radius-left;
}

.small {
  @include size(25px);
}

.isNotAllowed {
  cursor: not-allowed;
}

.disabled {
  color: var(--font-color);
  background-color: var(--disabled);

  > svg {
    background-color: transparent;
  }

  &:hover {
    background-color: var(--disabled) !important;
  }

  &:active {
    > svg {
      color: var(--disabled-text) !important;
    }
  }
}
