@import '../../../../assets/styles/variables.scss';

.active {
  color: var(--primary) !important;

  &:hover {
    color: var(--primary) !important;
  }
}

.disabled {
  color: var(--disabled-tint) !important;
  cursor: not-allowed !important;

  &:hover {
    color: var(--disabled-tint) !important;
  }
}

.warning {
  color: var(--warning) !important;

  &:hover {
    color: var(--warning) !important;
  }
}
