@import '../../../../../../assets/styles/variables.scss';

.containerRow {
  @include flex-gap(1rem, flex-start, column, flex-start);

  flex-wrap: wrap;
  width: 200px;
  background-color: var(--tooltip);
  padding: $padding;
  border-radius: $b-radius;
  border: 2px solid var(--bg-tint);

  .statusCard {
    @include flex-gap(1rem, flex-start, row);

    p {
      margin: 0;
    }
  }
}
