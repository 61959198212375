@import '/src/assets/styles/variables.scss';

.title {
  width: 100%;

  p {
    color: var(--grey100);
    margin: 0;
    font-size: 12px;
  }

  h2 {
    color: var(--font-color);
  }
}
