@import '../../../assets/styles/variables.scss';

.programWrapper {
  position: relative;

  @include flex(flex-start, column, flex-start);

  width: 100%;
  gap: 1rem;
  align-self: flex-end;
}
