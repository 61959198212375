@import '../../../../../../assets/styles/variables.scss';

.rowContainer {
  @include flex(space-between, row, none);

  width: 100%;

  p {
    // color: var(--font-color-light);
    margin-right: 20px;
    font-size: 13px;
  }
}
