@import '../../../assets/styles/variables.scss';
@import './../../../assets/styles/index.scss';

$media-s-map-btns: $media-s + 21px;
$border-side: 1px solid var(--whity);
$child-containers-right-offset: 50px;
$child-containers-top-offset: -40px;
$child-containers-top-offset-thin-screen: 95px;
$tile-size: 96px;
$triangle-size: 10px;
$hover-color: rgb(200 200 200 / 70%);
$selected-color: rgb(21 115 255 / 100%);
$cell-size: 6px;
$title-font-size: 14px;

%divider-general {
  padding: 0;
  border-right: $border-side;
}

%systems {
  @include flex(flex-start, row);

  overflow: hidden;

  hr {
    height: 1px;
    width: 100%;
    background: var(--whity);
    padding: 0;
    margin: 0;
    border: none;
  }

  .buttonBox {
    display: block;
  }
}

%divider {
  >button {
    @extend %divider-general;
  }

  >:last-child {
    border: none;
  }
}

.layerFilterSettingsContainer {
  min-width: $tile-size * 4;
  padding-bottom: $cell-size;
  margin-left: $cell-size;
}

.layerFilterSettingsTitle {
  color: var(--font-color-lite);
  margin: 0;
  font-size: 2 * $cell-size;
}

.layerFilterSettingsSlider {
  height: 2 * $cell-size !important;
  margin: 0 0 1.8 * $cell-size !important;
  line-height: 2 * $cell-size !important;
}

.layerFilterSettingsSubTitle {
  display: flex;
}

.baseMapSettingsButton {
  color: var(--font-color-lite);
  margin-top: $cell-size;
  margin-left: calc($cell-size / 2);
  font-size: $title-font-size;
  cursor: pointer;
}

.baseMapSettingsButtonActive {
  color: var(--blue300);
  margin-top: $cell-size;
  margin-left: calc($cell-size / 2);
  font-size: $title-font-size;
  cursor: pointer;
}

.layerFilterPredefinedContainer {
  display: inline-flex !important;
  margin-bottom: $cell-size;
}

.layerFilterSettingsButton {
  margin-right: $cell-size;
  font-size: 2 * $cell-size;
  border-bottom: 1px dotted;

  &:hover {
    color: var(--blue300);
    border-bottom: 1px dotted var(--blue300);
    cursor: pointer !important;
  }
}

.buttonsBox {
  display: flex;
  margin-bottom: $cell-size;
}

.buttonOffset {
  margin-right: $cell-size;
}
