@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex(space-between, row);

  padding: 2rem $padding $padding;
  border-radius: 0 0 $b-radius $b-radius;
}

.bg {
  background-color: var(--bg-tint);
}

.center {
  @include flex-gap(6rem, center, row);
}
