$cell-size: 6px;

.layerRow {
  display: flex !important;
  align-items: center;
  margin: $cell-size;
  line-height: 4 * $cell-size;
}

.layerTitle {
  margin-left: $cell-size;
  font-size: 14px;
  cursor: pointer;
}
