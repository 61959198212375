@import '/src/assets/styles/variables.scss';

.container {
  position: relative;
  padding: $padding;

  h4 {
    font-weight: bold;
  }

  .row {
    @include flex-gap(1rem, flex-start, row, flex-start);
  }

  .listContainer {
    @include size(100%, 460px);

    max-height: 460px;
    padding: 0;
    overflow: auto;
    border: 1px solid var(--bg-tint);
    border-radius: $b-radius;
  }
}
