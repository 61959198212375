@import '../../../assets/styles/variables.scss';

.mainBtn {
  @include flex;

  background: transparent;
  padding: 0;
  transition: $transition;
}

.hover {
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
}

.active {
  color: var(--primary);
}

.large {
  @include size(30px);
}

.small {
  @include size(20px);
}

.danger {
  color: var(--danger);
  cursor: pointer;
}

.disabled {
  color: var(--disabled) !important;
  cursor: not-allowed !important;
}

.hoverOpacity {
  &:hover {
    opacity: 60%;
  }
}
