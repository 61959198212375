@import '../../../../../assets/styles/variables.scss';

.openBtn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;

  button {
    @include flex(center, row, center);

    position: relative;
    border-radius: $b-radius;

    svg {
      @include size(20px);
    }
  }

  .warning {
    color: var(--warning);
    cursor: pointer;

    @include size(20px);
  }
}
