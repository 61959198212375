@import '/src/assets/styles/variables.scss';

.icoWrapper {
  @extend %absolute-centered;

  width: 100%;
}

.popover {
  background-color: var(--bg) !important;
  border-radius: 10px;
}
