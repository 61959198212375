@import '../../../../../assets/styles/variables.scss';

.card {
  @include flex(flex-start, column, flex-start);

  width: 100%;
  max-width: 125px;
  background-color: var(--bg-tint);
  padding: 1rem;
  box-sizing: border-box;
  gap: 0.5rem;
  border-radius: $b-radius;
  transition: $transition;

  &.isCurrent {
    box-shadow: 0 0 5px 5px rgb(24 144 255);
  }

  &.isCurrent.isPromTac {
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-duration: 1s;

    @keyframes blinker {
      from {
        box-shadow: 0 0 5px 5px rgb(24 144 255);
      }

      to {
        box-shadow: 0 0 2px 2px rgb(24 144 255);
      }
    }
  }

  &.isSlider {
    max-width: 170px;
  }

  &.isDisabled {
    color: var(--whity);
    background-color: var(--disabled);
  }

  &.isDetailed {
    height: 100%;
    width: 100%;
    max-width: none;
  }

  .img {
    height: 130px;
    width: 100%;
    flex: 1 0 auto;
    object-fit: contain;
    overflow: hidden;
  }

  .phaseInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;

    span {
      display: block;
      font-size: 11px;

      &.phase,
      &.time,
      &.phaseType {
        color: var(--whity);
        padding: 2px 4px;
        font-size: 12px;
        border-radius: $b-radius;
      }

      &.phase {
        background-color: var(--antd-blue);
      }

      &.phaseType {
        background-color: var(--warning);
        cursor: help;
      }

      &.time {
        background-color: var(--grey100);
      }
    }
  }
}
