@import '../../../assets/styles/variables.scss';

@mixin animation-size($size, $border-width, $opacity) {
  @include size($size);

  border-width: $border-width;
  opacity: $opacity;
}

.alarmPopup {
  @include size(40px);

  position: absolute;
  z-index: $default-index;
  color: var(--font-color);
  box-shadow: $box-shadow;
  border: 5px solid #da3c3c;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: swing 3s ease;
  animation-iteration-count: infinite;
  pointer-events: none;
}

.largeAlarm {
  animation-name: swingLarge;
}

.mediumAlarm {
  animation-name: swingMedium;
}

@keyframes swing {
  0% {
    @include animation-size(40px, 5px, 100%);
  }

  100% {
    @include animation-size(80px, 0, 0%);
  }
}

@keyframes swingMedium {
  0% {
    @include animation-size(60px, 5px, 100%);
  }

  100% {
    @include animation-size(100px, 0, 0%);
  }
}

@keyframes swingLarge {
  0% {
    @include animation-size(80px, 5px, 100%);
  }

  100% {
    @include animation-size(120px, 0, 0%);
  }
}
