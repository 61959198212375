@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, space-between, row);

  width: fit-content;

  &.absolute {
    position: absolute;
    right: 0;
  }
}
