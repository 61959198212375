@import '../../../../assets/styles/variables.scss';

.pie {
  font-size: 1.5rem;
  font-weight: bolder;
  transform: rotate(-0deg);
  border: none !important;
  pointer-events: none;

  svg {
    text {
      fill: var(--bg-dark);
    }
  }
}

.circularWrapper {
  @extend %absolute-centered;

  z-index: $negative-index;
  overflow: hidden;
  border-radius: 50%;
}

.line {
  height: 1px;
  width: 90%;
  background: var(--white);
  margin: 1px 0 2px;
  opacity: 50%;
}
