@import '/src/assets/styles/variables.scss';

.card {
  @include flex;

  max-height: 200px;
  width: 100%;
  background-color: var(--bg-tint);
  border-radius: $b-radius;
  border: 1px solid var(--bg-tint);
  transition: $transition;
  cursor: pointer;

  & > span {
    @include size(30px);

    & > svg {
      @include size(100%);
    }
  }

  &:hover {
    background-color: var(--bg);
  }
}
