@import '/src/assets/styles/variables.scss';

.container {
  @include flex;

  width: 50vw;
  background-color: var(--bg);
  padding: $padding-card;

  .block {
    @include flex;
    @include size(100%);

    position: relative;
  }
}
