@import '../../../assets/styles/variables.scss';

.list {
  @include flex(none, row, none);

  list-style: none;
  padding-inline-start: 0;

  li {
    @include flex(none, row, center);
  }

  .btn {
    position: relative;
    color: var(--font-color);
    background: none;
    border: none;
    transition: all 0.3s ease-in;

    &::before {
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      height: 0.2rem;
      width: 0;
      background: var(--font-color);
      content: '';
      transition: all 0.3s ease-in;
    }
  }

  .btnHover {
    cursor: pointer;

    &:hover::before {
      width: 100%;
    }
  }

  .btnActive {
    color: var(--blue100);

    &::before {
      width: 100%;
      background: var(--blue100);
    }
  }
}
