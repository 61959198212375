@import '../../../../../../../assets/styles/variables.scss';

.statusIco {
  @include size(25px);

  svg {
    width: 100%;
  }

  &.small {
    @include size(20px);
  }
}
