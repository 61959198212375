@import '../../../assets/styles/variables.scss';

$programs-band-height: 30px;

.phaseRow {
  @include flex(flex-start, row);

  position: relative;
  width: 100%;
  border: 1px solid var(--font-color);
  border-radius: $b-radius;

  .timePointer {
    position: absolute;
    display: block;
    height: 100%;
    background: var(--blue300);
    opacity: 70%;
    visibility: visible;
    border-radius: $b-radius-left;
    transition: $transition-tact;
  }

  .timePointerMiddle {
    background: var(--purple);
  }

  .block {
    @include flex(center, column);

    position: relative;
    z-index: $detailed-index + 1;
    max-width: 100%;
    border-right: solid var(--font-color);

    .titleBlock {
      position: relative;
      height: $programs-band-height;
    }

    .phaseBlockTitleMiddle {
      height: $programs-band-height;
      margin: 0;
    }
  }
}
