@import '../../../../../assets/styles/variables.scss';
@import '../../styles/basicStyles.module.scss';

.imgConfirm,
.imgInfo {
  border: 2px solid var(--font-color);
  border-radius: $b-radius;
  box-shadow: $box-shadow;
}

.imgInfo {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 3rem;
}

.imgConfirm {
  height: 100%;
  min-height: 30vh;
  width: 50%;
}
