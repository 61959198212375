@import '../../../../../../../../assets/styles/variables.scss';

.container {
  @include flex(flex-start, column);

  width: 400px;
  max-width: 400px;
  gap: 1rem;

  .containerRow {
    @include flex(flex-start, row);

    gap: 1rem;

    p {
      margin: 0;
      vertical-align: middle;
    }
  }

  .special {
    color: var(--warning);
    font-weight: bold;
  }
}
