@import '../../../assets/styles/variables.scss';

.spin {
  @include flex;
  @include size(100%);

  &.filter {
    @include size(100%);

    position: absolute !important;
    z-index: 2;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
  }
}
