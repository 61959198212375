@import '../../../../assets/styles/variables.scss';

$height-graph: 90%;

.containerGraph {
  @include flex(space-between);
  @include size(100%);

  .containerChart {
    @include flex-gap(2rem, flex-start);

    height: $height-graph;
    width: 100%;
  }

  .iconInfo {
    @include size(20px);
  }

  .titleBlock {
    @include flex;

    h3,
    h4 {
      margin: 0;
    }

    h3 {
      font-weight: bold;
    }
  }
}
