@import '../../../../assets/styles/variables.scss';

.laneNumber {
  @extend %absolute-centered;

  color: var(--white);
  background-color: var(--grey200);
  padding: 0 3px;
  border: 2px solid var(--white);
  border-radius: 4px;
  text-align: center;

  > div {
    padding: 2px !important;

    > span > i {
      background-color: var(--white) !important;
    }
  }

  & > svg {
    @include size(100%);
  }

  &.numbers {
    border-radius: 2px;
  }

  &.active {
    background-color: var(--primary);
  }
}
