@import '../../../../../assets/styles/variables.scss';

.list {
  @include flex(flex-start, row, center);

  flex-wrap: wrap;
  margin: 0;
  gap: 10px;
  list-style: none;
  padding-inline-start: 0;
}
