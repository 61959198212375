@import '../../../../../assets/styles/variables.scss';

.list {
  @include flex(center, row);

  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 1rem;
  list-style: none;
  gap: 1.5rem;
  overflow-y: auto;
  border-radius: $b-radius;
}
