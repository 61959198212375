@import '/src/assets/styles/variables.scss';

.ico {
  font-size: 18px;

  svg {
    fill: var(--font-color);
    transition: $transition;
  }

  &.active {
    svg {
      fill: var(--white);
    }
  }

  &:hover:not(.active) {
    svg {
      fill: var(--primary);
    }
  }
}
