@import '../../../assets/styles/variables.scss';

$large: 330px;
$medium: 320px;

@mixin image-container() {
  @include size($large);

  position: relative;
  color: var(--black100);
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  border: 1px solid var(--font-color);
  border-radius: $b-radius;

  .img {
    img {
      @include size($large);

      display: block;
      padding: 20px 35px;
      overflow: hidden;
      object-fit: cover;
    }
  }
}

.container {
  display: grid;
  max-height: 79vh;
  max-width: 100%;
  padding: 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  overflow-y: auto !important;
  gap: 15px;

  @media (max-width: $media-xl) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $media-m) {
    grid-template-columns: 1fr;
  }
}

.borderBottom {
  border-bottom: 2px solid #d3d3d6;
}

.phaseScheme {
  flex-grow: 1;
  box-shadow: 1px 1px 20px #0003;
  border-radius: $b-radius;

  .containerImg {
    @include image-container;
  }
}

.containerNotData {
  @include flex(center, row, center);

  width: 100%;

  .mainGraphCardNotData {
    @include flex(center, column);

    width: 40%;
    margin-bottom: 4rem;
    box-shadow: $box-shadow;
    border-radius: $b-radius;
    text-align: center;
  }
}

@media (orientation: portrait) {
  .container {
    justify-content: center !important;
  }

  .phaseScheme {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .container {
    justify-content: center !important;
  }
}
