@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%);

  display: grid;
  background-color: var(--bg-tint);
  grid-template-columns: repeat(2, 1fr);
  border-radius: $b-radius;
  gap: 1rem;
}

.singleCard {
  @include size(50%, 100%);

  margin: 0 auto;
}

.block {
  @include flex-gap(2rem, flex-start, column, flex-start);
  @include size(100%, 85vh);

  position: relative;
  padding: $padding-card;
  border-radius: $b-radius;
  overflow-y: auto;

  &.bg {
    background-color: var(--bg);
  }
}
