@import '/src/assets/styles/variables.scss';

.section {
  width: 100%;

  .title {
    text-align: start;
  }

  .wrapper {
    display: grid;
    width: 100%;
    margin-bottom: 2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 2rem;

    .item {
      color: var(--whity);
      background-color: var(--primary);
      padding: 2rem 1rem;
      text-align: center;
      border-radius: $b-radius;
      transition: all 0.5s;

      >p {
        margin: 0;
      }

      .data {
        font-size: 3rem;
        font-weight: 600;
      }
    }
  }
}
