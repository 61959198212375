@import '../../../../../assets/styles/variables.scss';

.radioGroup {
  width: fit-content;

  & > label {
    color: var(--font-color);
    background: var(--bg-tint);
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid var(--white600) !important;

    &:hover {
      color: var(--font-color) !important;
      background: var(--bg-tint) !important;
    }

    [class*='checked'] {
      background-color: var(--primary);
      border-radius: $b-radius;
    }
  }
}

.btn {
  position: relative;

  .specialInfo {
    position: absolute;
    right: 2px;
    bottom: -5px;
    color: var(--warning);
    font-size: 12px;
  }
}
