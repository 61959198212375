@import '/src/assets/styles/variables.scss';

.count {
  @include flex;

  position: absolute;
  right: 1rem;
  bottom: 1rem;

  .title {
    margin: 0;
    font-size: 12px;
  }
}
