@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem);

  width: 100%;
  padding: $padding;
  border: 1px solid var(--bg-tint);
  border-radius: $b-radius;
}
