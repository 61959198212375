@import '/src/assets/styles/variables.scss';

$icon-size: 30px;

.iconInfo {
  @include size($icon-size);

  color: var(--warning);
}

.editIcon {
  @include size($icon-size);

  svg {
    @include size(100%);
  }
}
