@import '../../../../../assets/styles/variables.scss';

.wrapper {
  position: relative;
  display: flex;
  gap: 2rem;

  .header {
    padding: 0;
    margin: 0;
    text-align: center;
    transition: $transition;

    &:hover {
      color: var(--antd-blue);
    }
  }

  .count {
    opacity: 50%;
  }

  .addBtn {
    @extend %reset-btn-style;

    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);

    svg {
      @include size(22px);
    }
  }
}
