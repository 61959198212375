@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/colors.scss';
@import '../../../../assets/styles/zIndex.scss';

.wrapper {
  @include flex(flex-start, row, center);

  position: relative;
  height: 80vh;
  width: 100%;
  border-radius: $b-radius;

  .row {
    @include flex(flex-start, column, center);

    z-index: $ten-index;
    padding-left: 10rem;

    .text {
      display: block;
      width: 520px;
      font-size: 24px;
      text-align: center;
    }

    .error {
      width: 300px;
      color: var(--error);
      font-size: 15px;
      text-align: center;
    }
  }

  .image {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    opacity: 60%;
  }
}
