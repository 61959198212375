@import '/src/assets/styles/variables.scss';

.container {
  @include size(100%);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr);
  gap: 1rem;
}
