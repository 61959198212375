@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/variables.scss';

@mixin bg-slice($size, $top, $left, $color) {
  position: absolute;
  top: $top;
  left: $left;
  height: $size;
  width: $size;
  background: $color;
  border-radius: 50%;
  filter: blur(10vh);
}

.container {
  @include flex(center, column);

  position: relative;
  width: 50vw;
  background: var(--bg);
  overflow: hidden;

  .title {
    display: block;
    width: 90%;
    text-align: center;

    h1,
    h2 {
      color: var(--font-color);
    }
  }

  .footer {
    position: absolute;
    bottom: 10px;
    width: 90%;

    .copyrightContainer {
      @include flex(center, row);

      color: var(--font-color);
      margin-bottom: 10px;

      .copyrightIco {
        margin-right: 3px;
        font-size: 18px;
      }
    }

    .footerText {
      color: var(--font-color);
      font-size: 12px;
      text-align: center;
    }
  }
}

.logo {
  fill: var(--font-color);
}

.bgSlice1 {
  @include bg-slice(66%, -17%, -32%, $logo-blue);
}

.bgSlice2 {
  @include bg-slice(51%, -19%, 23%, $logo-green);
}

.bgSlice3 {
  @include bg-slice(51.5%, -14%, 72%, $logo-salad);
}

.bgSlice4 {
  @include bg-slice(51%, 75%, 60%, $logo-green);
}

.bgSlice5 {
  @include bg-slice(24%, 85%, 35%, $logo-salad);
}
