@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/zIndex.scss';

$box-shadow: -1px -1px 10px #00000080;

.info {
  position: relative;
  height: 100%;
  color: var(--font-color);
  background: var(--bg);

  > div {
    > div {
      > div {
        > div {
          div {
            color: var(--font-color);
          }
        }
      }
    }
  }

  .wrapper {
    padding: 0;
    margin: 0;
  }
}
