@import '../../../../assets/styles/variables.scss';
@import '../../styles/variables.scss';

.frame {
  @extend %ico-cursor;
}

.id {
  @extend %absolute-centered;

  color: var(--white);
  background-color: var(--primary);
  padding: 0 3px;
  font-weight: bold;
  border-radius: $b-radius;
}
