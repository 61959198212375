/* Z-index grade */
$negative-index: -1;
$zero-index: 0;
$default-index: 1;
$ten-index: 10;
$one-hundred-index: 100;
$two-hundred-index: 200;

/* Z-index variables */
$sidebar-index: $one-hundred-index + 1;
$constructor-overlays-index: $one-hundred-index;
$service-form-index: $one-hundred-index;
$panel-index: $one-hundred-index;
$info-panel-index: $ten-index + 1;
$notification-panel-index: 1;
$modal-loading-min-index: $one-hundred-index - 1;
$widget-index: $ten-index;
$search-box-index: $ten-index - 1;
$traffic-light-index: $ten-index + 1;
$detailed-index: $ten-index + 1;
$right-panel-index: $ten-index;
$tact-index: $ten-index + 2;
$admin-index: $ten-index + 1;
$popop-content-index: $ten-index;
$map-btns-index: $ten-index;
$app-error-index: $one-hundred-index + 1;
$full-screen-video-index: $two-hundred-index;
$full-screen-video-index-of: $two-hundred-index + 1;
