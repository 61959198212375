@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(1rem, flex-start, column, flex-start);
  @include size(100%);

  overflow-y: auto;

  .title {
    margin: 0;
    font-size: 11px;
    font-weight: bold;
  }

  &.full {
    padding: $padding;
    border: 1px solid var(--bg-tint);
    border-radius: $b-radius;
  }
}

.desc {
  & > div {
    border-color: var(--bg-tint) !important;
  }

  td,
  th {
    border-color: var(--bg-tint) !important;
  }
}
