@import '/src/assets/styles/variables.scss';

.wrapper {
  @include flex(flex-end, column, flex-end);

  padding: 8px;

  .input {
    display: 'block';
    margin-bottom: 8px;

    span[class*='suffix'] {
      display: flex;
      gap: 1rem;

      span[class*='clear-icon'] {
        margin: 0 !important;
        visibility: visible !important;
        order: 2;
      }

      span [class*='show-count'] {
        order: 1;
      }
    }
  }

  .btn {
    height: 30px;
    padding: 0 2rem;
  }
}
