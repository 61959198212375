@import '../../../../assets/styles/variables.scss';
@import '../TlMainInfo.module.scss';

.mainTLInfo {
  @extend %tl-box;
}

.infheader {
  display: flex;
  justify-content: space-between;

  & > h3 {
    font-weight: bold;
    text-align: left;
  }
}

.infcontent {
  display: flex;
  justify-content: space-between;

  p[id^='inf_'] {
    margin: 0;
  }

  h4 {
    font-weight: 400;
    filter:
      invert(25%) sepia(24%) saturate(383%) hue-rotate(197deg)
      brightness(95%) contrast(95%);
  }

  > div {
    width: 30%;
  }

  @media (max-width: $media-s) {
    flex-direction: column !important;
    align-items: center;
  }
}

.infdescription {
  width: 100%;
}

.timeStamp {
  @include flex(space-between, row, none);

  position: relative;
  width: fit-content;
  color: var(--font-color);
  gap: 1rem;
  filter:
    invert(25%) sepia(24%) saturate(383%) hue-rotate(197deg)
    brightness(95%) contrast(95%);

  p {
    vertical-align: bottom;
  }

  .iconInfo {
    cursor: help;
  }
}
