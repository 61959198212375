.settingsContent {
  width: 100%;
  padding-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;

  > div,
  >form {
    padding: 0 20px !important;
  }
}
